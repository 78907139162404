











import { Vue, Component, Prop } from "vue-property-decorator";
import ButtonAtom from "../button/Button.vue";

@Component({
    components: {
        ButtonAtom
    }
})
export default class InputTextfieldAtom extends Vue {

    @Prop({
        type: String,
        default: "large",
        validator: value => ["large", "small"].indexOf(value) > -1
    }) private size!: string;
    @Prop() private label!: string;
    @Prop() private placeholder!: string;
    @Prop() private value!: string;
    @Prop() private pattern!: string;
    @Prop({ default: false }) private required!: boolean;
    @Prop({ default: "text" }) private type!: string;
    @Prop({ default: false }) private disableErrMsg!: boolean;

    private errorMsgIx = -1;
    private errMsgLabel = this.label || "feltet";
    private errorMessages = [
        "Du skal udfylde " + this.errMsgLabel.toLocaleLowerCase() + ".",
        this.errMsgLabel + " er ikke udfyldt korrekt."
    ];

    private get setFlags() {
        return `--${this.size}`;
    }

    private onInput(ev) {
        this.$emit("input", ev.target.value);
    }

    private validateField() {
        if (this.value === "") {

            // only emit error if required
            if (this.required) {
                this.errorMsgIx = 0;
                this.emitError(true);
            } else {
                this.errorMsgIx = -1;
                this.emitError(false);
            }

        } else if (this.type === "email") {

            // tslint:disable-next-line
            const emailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
            const passedTest = emailRegex.test(this.value);
            this.errorMsgIx = passedTest ? -1 : 1;

            this.emitError(!passedTest);

        } else if (this.type === "phone") {

            const phoneRegex = /^[0-9\(\)\-\+]+$/;
            const passed = phoneRegex.test(this.value);
            this.errorMsgIx = passed ? -1 : 1;

            this.emitError(!passed);

        } else if (this.type === "time") {

            const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/g;
            const passedTest = timeRegex.test(this.value);
            this.errorMsgIx = passedTest ? -1 : 1;

            this.emitError(!passedTest);

        } else {
            this.errorMsgIx = -1;
            this.emitError(false);
        }
    }

    private emitError(val) {
        this.$emit("error", {
            value: val,
            msg: this.errorMsgIx === -1 ? "" : this.errorMessages[this.errorMsgIx]
        });
    }
}
