























import Vue from "vue";
import Api from "../../../core/api/Api";
import Component from "vue-class-component";
import { NavigationLink } from "../../../generated/contracts";
import IconAtom from "@atoms/icons/Icons.vue";
import { Action } from "vuex-class";

@Component({
    components: {
        IconAtom
    }
})
export default class NavigationPanel extends Vue {
    private links: NavigationLink[] = [];

    @Action private clearLogin!: () => void;

    protected async mounted() {
        try {
            const navigation = await Api.content.getNavigation();
            this.links = navigation.links;
        } catch {
            // Probably not logged in
        }
    }

    public $refs!: {
        icons: HTMLBaseElement
    };

    public hideIcon(index) {
        this.$refs.icons[index].classList.remove("--hide-text");
    }
}
