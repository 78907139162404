


































import { Vue, Component, Prop } from "vue-property-decorator";

import { CardItemContent } from "../cardModels";

@Component({
    props: {
        items: Object
    }
})
export default class CardLayoutTimelineAtom extends Vue {

    @Prop() private items!: CardItemContent[];

    private showLess = true;

    protected showMore() {
        this.showLess = !this.showLess;
    }

}
