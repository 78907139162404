import { render, staticRenderFns } from "./ExcludedPostalCode.vue?vue&type=template&id=b2bab396&scoped=true&"
import script from "./ExcludedPostalCode.vue?vue&type=script&lang=ts&"
export * from "./ExcludedPostalCode.vue?vue&type=script&lang=ts&"
import style0 from "./ExcludedPostalCode.vue?vue&type=style&index=0&id=b2bab396&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2bab396",
  null
  
)

component.options.__file = "ExcludedPostalCode.vue"
export default component.exports