












import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class LoaderAtom extends Vue {
    @Prop() private small!: boolean;

}
