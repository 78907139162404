
import { Component, Prop } from "vue-property-decorator";
import StandardLayout from "../../components/layout/standard-layout/StandardLayout.vue";
import { OrderSortOrder, OrderSummary, SalesAccountOrderView, SalesAccountView, SortBy } from "../../../generated/contracts";
import Api from "../../../core/api/Api";
import constants from "../../../core/constants";
import OrderHistoryView from "../shared/OrderHistoryView.vue";
import ErrorView from "../shared/ErrorView.vue";
import ReturnErrorView from "../shared/ReturnErrorView.vue";
import StoreReturnDeliveryView from "../store/StoreReturnDeliveryView.vue";

@Component({
    components: { OrderHistoryView, ErrorView, StoreReturnDeliveryView }
})
export default class StorePage extends StandardLayout<SalesAccountView> {
    private orderSummaries: OrderSummary[] = [];

    @Prop() private id!: string;

    protected async created() {
        this.pageView = await Api.store.getStorePage(this.id, OrderSortOrder.DeliveryDate, SortBy.Ascending, constants.NoOfRowsAtATime);

        // Remove statistics for now
        (this.pageView as any).statistics = null;

        this.orderSummaries = (this.pageView.content as SalesAccountOrderView).orders;
        this.sections = [
            {
                name: "Ordrehistorik",
                component: OrderHistoryView,
                props: {
                    id: this.id,
                    tab: "0"
                }
            },
            {
               name: "Retur fra butik",
               component: StoreReturnDeliveryView,
               props: {
                   id: this.id,
                   apiMethod: Api.store.getStoreReturnDeliveries,
                   tab: "1"
               }
            },
            {
                name: "Fejlmeldinger",
                component: ErrorView,
                props: {
                    id: this.id,
                    apiMethod: Api.store.getStoreErrors,
                    tab: "2"
                }
            },
            {
                name: "Fejlmeldinger retur",
                component: ReturnErrorView,
                props: {
                    id: this.id,
                    apiMethod: Api.store.getStoreReturnErrors,
                    tab: "3"
                }
            }
        ];

        this.contactType = "phone";
    }
}
