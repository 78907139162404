






























import { Component, Prop, Vue } from "vue-property-decorator";
import Overlay from "@components/overlay/Overlay.vue";
import DropdownAtom from "@atoms/dropdown/Dropdown.vue";
import InputTextfieldAtom from "@atoms/input-textfield/InputTextfield.vue";
import CardItemAtom from "@atoms/cards/card-item/CardItem.vue";
import CardImagesAtom from "@atoms/cards/card-images/CardImages.vue";
import ShareInfo from "../shared/delivery-info/_ShareInfo.vue";
import StaticMap from "@components/static-map/StaticMap.vue";
import { CardImages, CardItemContent } from "../../atoms/cards/cardModels";
import { GpsLocation, PickupInfo } from "../../../generated/contracts";
import { dateTimeFilter as dateTime } from "../../filters/dateTimeFilters";
import serverContext from "../../../core/server-context";
import mapImage from "../../../core/map-utils/mapImage";

@Component({
    components: {
        Overlay,
        InputTextfieldAtom,
        DropdownAtom,
        CardItemAtom,
        CardImagesAtom,
        ShareInfo,
        StaticMap
    }
})
export default class PickupInfoComp extends Vue {
    private showMap: boolean = false;

    @Prop() private pickupInfo!: PickupInfo;

    private history(): CardItemContent[] {
        return this.pickupInfo.history.map((historyElem) => {
            return {
                description: historyElem.description,
                label: dateTime(historyElem.timestamp)
            };
        });
    }

    private get infoSummary(): CardItemContent[] {
        return [
            {
                label: "Afhentet",
                description: dateTime(this.pickupInfo.pickupTimeUtc)
            },
            {
                label: "Afhentet af",
                description: this.pickupInfo.pickupBy
            },
            {
                label: "Adresse",
                description: this.combinedAddress
            }
        ];
    }

    private get combinedAddress() {
        return this.createMultiline(this.pickupInfo.pickupFromName,
            this.pickupInfo.pickupAtStreetAndNumber,
            this.pickupInfo.pickupAtPostalCodeAndCity);
    }

    private get images(): CardImages {
        return {
            headline: "Billede af retursag",
            content: this.pickupInfo.photos.map((photo) => ({
                label: dateTime(photo.exif.takenUtc),
                url: serverContext.apiUrl + photo.url,
                deliveryInfo: [
                    {
                        label: "EXIF data",
                        description: photo.exif.address,
                        name: this.createMultiline(formatGpsLocation(photo.exif.location), dateTime(photo.exif.takenUtc))
                    },
                    {
                        label: "Chauffør note",
                        description: photo.note
                    }
                ]
            }))
        };

        function formatGpsLocation(location: GpsLocation) {
            return `${location.latitude}, ${location.longitude}`;
        }
    }

    private createMultiline(...strings: string[]) {
        return strings.join("\r\n");
    }

    private get getLatLng() {
        return {
            lat: this.pickupInfo.pickupGpsPos.latitude,
            lng: this.pickupInfo.pickupGpsPos.longitude
        };
    }

    private shareAction(to: "customer" | "colleague", name: string, email, message, images) {
        this.$emit("shareInfo", to, name, email, message, images);
    }

    private get dropMapImg() {
        return mapImage(this.pickupInfo.pickupGpsPos.latitude,
            this.pickupInfo.pickupGpsPos.longitude,
            600, 300,
            17,
            serverContext.googleMapsApiKey
        );
    }

    private toggleMap() {
        this.showMap = !this.showMap;
    }

}
