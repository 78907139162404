export default {
    sundayFirst: false,
    days: ["Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag", "Søndag"],
    daysShort: ["ma", "ti", "on", "to", "fr", "lø", "sø"],
    monthNames: [
        "Januar",
        "Februar",
        "Marts",
        "April",
        "Maj",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "December"
    ],
    colors: {
        selected: "transparent",
        inRange: "transparent",
        selectedText: "#808080",
        text: "#808080",
        inRangeBorder: "#E2EAEE",
        disabled: "#fff"
    },
    texts: {
        apply: "Vælg",
        cancel: "Afbryd"
    }
};
