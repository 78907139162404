export interface TableHeader {
    title: string;
    order?: boolean;
    orderBy?: string;
    key: string;
    size?: number;
}

export interface TableEditModel {
    type: string;
    content: object;
}

export interface TableRow {
    cells: object;
    options?: object;
    rowModel: object;
}

export enum TableStatusColor {
    Blue = 0,
    Green = 1,
    Yellow = 2,
    Red = 3
}

export interface StatusModel {
    label: string;
    color: TableStatusColor;
    type: string;
}

