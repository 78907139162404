














import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ExtendedDeliveryLineItemSortOrder, ExtendedDeliveryLineItemSummary } from "../../../generated/contracts";
import Api from "../../../core/api/Api";
import { TableHeader } from "../../atoms/table/tableModels";
import TableViewControlModel, { ToolbarItem } from "../../components/table-view/tableViewModels";
import constants from "../../../core/constants";
import router from "../../router";
import { statusToDisplayNameAndColor, toDomainSortBy } from "../shared/tableHelper";
import TableView from "../../components/table-view/TableView.vue";

interface ExtendedDeliveryLineItemSummaryTableHeader extends TableHeader {
    key: keyof (ExtendedDeliveryLineItemSummary);
}

@Component({
    components: { TableView }
})
export default class OrderLinesView extends Vue {
    @Prop() private id!: string;
    @Prop() private tab!: string;

    private loadFromUrl = this.tab === router.currentRoute.query["tab"];
    private initialSortOrder: keyof (ExtendedDeliveryLineItemSummary) = router.currentRoute.query["sortOrder"] && this.loadFromUrl ?
                (router.currentRoute.query["sortOrder"] as keyof (ExtendedDeliveryLineItemSummary))! :
                "status";
    private initialSortBy = router.currentRoute.query["sortByAsc"] && this.loadFromUrl ?
                router.currentRoute.query["sortByAsc"] === "true" :
                true;
    private initialSearchTerm = router.currentRoute.query["searchTerm"] && this.loadFromUrl ?
                router.currentRoute.query["searchTerm"] :
                null;

    private tableControlModel: TableViewControlModel = {
        fromDate: null,
        toDate: null,
        searchTerm: this.initialSearchTerm,
        sort: {
            sortOrder: this.initialSortOrder,
            sortByAscending: this.initialSortBy
        }
    };

    private loadingOrderBy = false;
    private loadingMore = false;
    private deliveryLineItemSummaries: ExtendedDeliveryLineItemSummary[] = [];

    private mounted() {
        this.load(0, true);
    }

    private get tableHeader(): ExtendedDeliveryLineItemSummaryTableHeader[] {
        return [
            { key: "deliveryNoteNumber", title: "Følgeseddel" },
            { key: "lineNumber", title: "Pos" },
            { key: "itemNumber", title: "Varenr." },
            { key: "itemName", title: "Varenavn", size: 30 },
            { key: "unitOfMeasurement", title: "Enhed", size: 8 },
            { key: "orderedQuantity", title: "Bestilt", size: 8 },
            { key: "deliveredQuantity", title: "Pakket", size: 8 },
            { key: "status", title: "Status" }
        ];
    }

    private tableControlModelChanged(tableControlData: TableViewControlModel) {
        this.tableControlModel = tableControlData;
        this.loadingOrderBy = true;
        this.load();
    }

    private async load(skip = 0, firstLoad = false, take = constants.NoOfRowsAtATime) {
        this.loadingMore = true;
        try {
            const sortByAsc = router.currentRoute.query["sortByAsc"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["sortByAsc"] === "true" :
                this.tableControlModel.sort.sortByAscending;

            const sortOrder = router.currentRoute.query["sortOrder"] && firstLoad && this.loadFromUrl ?
                (router.currentRoute.query["sortOrder"] as keyof (ExtendedDeliveryLineItemSummary))! :
                (this.tableControlModel.sort.sortOrder as keyof (ExtendedDeliveryLineItemSummary))!;

            const searchTerm = router.currentRoute.query["searchTerm"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["searchTerm"] :
                this.tableControlModel.searchTerm;

            this.loadFromUrl = this.tab === router.currentRoute.query["tab"];

            if (!firstLoad && this.loadFromUrl) {
                router.replace({ query: {
                    ...this.$route.query,
                    sortOrder: sortOrder.toString(),
                    sortByAsc: sortByAsc.toString(),
                    searchTerm: searchTerm as string} });
                }

            const deliveryLineItems: ExtendedDeliveryLineItemSummary[] = await Api.order.getOrderDeliveryLines(this.id,
                    this.toDeliveryLineItemSummarySortOrder(sortOrder),
                    toDomainSortBy(sortByAsc),
                    searchTerm,
                    skip,
                    take);

            this.deliveryLineItemSummaries = skip > 0 ? [...this.deliveryLineItemSummaries, ...deliveryLineItems] : deliveryLineItems;
        } finally {
            this.loadingOrderBy = false;
            this.loadingMore = false;
        }
    }

    private toDeliveryLineItemSummarySortOrder(key: keyof (ExtendedDeliveryLineItemSummary)): ExtendedDeliveryLineItemSortOrder {
        switch (key) {
            case "deliveryNoteNumber":
                return ExtendedDeliveryLineItemSortOrder.DeliveryNoteNumber;
            case "lineNumber":
                return ExtendedDeliveryLineItemSortOrder.LineNumber;
            case "itemNumber":
                return ExtendedDeliveryLineItemSortOrder.ItemNumber;
            case "itemName":
                return ExtendedDeliveryLineItemSortOrder.Name;
            case "unitOfMeasurement":
                return ExtendedDeliveryLineItemSortOrder.UnitOfMeasure;
            case "orderedQuantity":
                return ExtendedDeliveryLineItemSortOrder.OrderedQuantity;
            case "deliveredQuantity":
                return ExtendedDeliveryLineItemSortOrder.DeliveredQuantity;
            case "status":
                return ExtendedDeliveryLineItemSortOrder.Status;
            default:
                throw new Error(`unknown key ${key}`);
        }
    }

    private async loadMore(done: () => void) {
        await this.load(this.tableContent.length);
        done();
    }

    private get tableContent(): object[] {
        return this.deliveryLineItemSummaries.map((d: ExtendedDeliveryLineItemSummary) => ({
            ...d,
            status: statusToDisplayNameAndColor(d.status)
        }));
    }

    private get toolbarItems() {
        return [ToolbarItem.TextSearch];
    }

}
