




import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import customPin from "../../../assets/gfx/map-marker.svg";

@Component
export default class StaticMap extends Vue {
    private map!: google.maps.Map;

    @Prop({
        default: () => {
            return {
                lat: 56.263920,
                lng: 9.501785
            };
        },
        type: Object
    }) private location!: google.maps.LatLngLiteral;

    @Prop({
        default: 16
    }) private zoom!: number;

    protected async mounted() {
        this.createMap();
    }

    private createMap() {
        const element = this.$refs["map"];

        const mapStyles = require("../../util/mapStyleDetailed.json");

        const styledMapType = new google.maps.StyledMapType(mapStyles, { name: "Map" });

        const options: google.maps.MapOptions = {
            center: this.location,
            zoom: this.zoom,
            disableDoubleClickZoom: true,
            fullscreenControl: false,
            keyboardShortcuts: false,
            mapTypeControlOptions: {
                mapTypeIds: ["styled_map", "hybrid"]
            },
            mapTypeControl: true,
            rotateControl: false,
            scaleControl: false,
            streetViewControl: false
        };

        this.map = new google.maps.Map(element as HTMLBaseElement, options);

        this.map.mapTypes.set("styled_map", styledMapType);
        this.map.setMapTypeId("styled_map");

        const marker = new google.maps.Marker({
            position: this.location,
            icon: {
                url: customPin,
                scaledSize: new google.maps.Size(38, 52)
            },
            map: this.map
        });
    }
}
