import { render, staticRenderFns } from "./RouteHistoryView.vue?vue&type=template&id=16f5cde0&"
import script from "./RouteHistoryView.vue?vue&type=script&lang=ts&"
export * from "./RouteHistoryView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "RouteHistoryView.vue"
export default component.exports