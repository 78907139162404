















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ReturnPackageSortOrder, ReturnPackageSummary } from "../../../generated/contracts";
import Api from "../../../core/api/Api";
import TableViewControlModel, { ToolbarItem } from "../../components/table-view/tableViewModels";
import constants from "../../../core/constants";
import { statusToDisplayNameAndColor, toDomainSortBy } from "../shared/tableHelper";
import TableView from "../../components/table-view/TableView.vue";
import { TableHeader } from "@/ui/atoms/table/tableModels";
import { dateTimeFilter } from "@/ui/filters/dateTimeFilters";
import router from "@/ui/router";

interface ReturnPackageSummaryTableHeader extends TableHeader {
    key: keyof (ReturnPackageSummary);
}

@Component({
    components: { TableView }
})
export default class ReturnPackageView extends Vue {
    @Prop() private id!: string;
    @Prop() private tab!: string;

    private loadFromUrl = this.tab === router.currentRoute.query["tab"];
    private initialSortOrder: keyof (ReturnPackageSummary) = router.currentRoute.query["sortOrder"] && this.loadFromUrl ?
                (router.currentRoute.query["sortOrder"] as keyof (ReturnPackageSummary))! :
                "status";
    private initialSortBy = router.currentRoute.query["sortByAsc"] && this.loadFromUrl ?
                router.currentRoute.query["sortByAsc"] === "true" :
                true;
    private initialSearchTerm = router.currentRoute.query["searchTerm"] && this.loadFromUrl ?
                router.currentRoute.query["searchTerm"] :
                null;

    private tableControlModel: TableViewControlModel = {
        fromDate: null,
        toDate: null,
        searchTerm: this.initialSearchTerm,
        sort: {
            sortOrder: this.initialSortOrder,
            sortByAscending: this.initialSortBy
        }
    };
    private loadingOrderBy = false;
    private loadingMore = false;
    private returnPackageSummaries: ReturnPackageSummary[] = [];

    private mounted() {
        this.load(0, true);
    }

    private get tableHeader(): ReturnPackageSummaryTableHeader[] {
        return [
            { key: "ssccNumber", title: "SSCC", size: 30 },
            { key: "deliveredTime", title: "Dato / tid", size: 55 },
            { key: "status", title: "Status", size: 15 }
        ];
    }

    private rowClicked(row: ReturnPackageSummary) {
        router.push({ name: "ReturnPackage", params: { id: row.ssccNumber } });
    }

    private tableControlModelChanged(tableControlData: TableViewControlModel) {
        this.tableControlModel = tableControlData;
        this.loadingOrderBy = true;
        this.load();
    }

    private async load(skip = 0, firstLoad = false, take = constants.NoOfRowsAtATime) {
        this.loadingMore = true;
        try {
            const sortByAsc = router.currentRoute.query["sortByAsc"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["sortByAsc"] === "true" :
                this.tableControlModel.sort.sortByAscending;

            const sortOrder = router.currentRoute.query["sortOrder"] && firstLoad && this.loadFromUrl ?
                (router.currentRoute.query["sortOrder"] as keyof (ReturnPackageSummary))! :
                (this.tableControlModel.sort.sortOrder as keyof (ReturnPackageSummary))!;

            const searchTerm = router.currentRoute.query["searchTerm"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["searchTerm"] :
                this.tableControlModel.searchTerm;

            this.loadFromUrl = this.tab === router.currentRoute.query["tab"];

            if (!firstLoad && this.loadFromUrl) {
                router.replace({ query: {
                    ...this.$route.query,
                    sortOrder: sortOrder.toString(),
                    sortByAsc: sortByAsc.toString(),
                    searchTerm: searchTerm as string} });
                }
            const returnPackages: ReturnPackageSummary[] =
                await Api.returnDeliveryPackage.getReturnPackageSummaries(this.id,
                    this.toSortOrder(sortOrder),
                    toDomainSortBy(sortByAsc),
                    this.tableControlModel.searchTerm,
                    skip,
                    take);

            this.returnPackageSummaries = skip > 0 ? [...this.returnPackageSummaries, ...returnPackages] : returnPackages;
        } finally {
            this.loadingOrderBy = false;
            this.loadingMore = false;
        }
    }

    private toSortOrder(key: keyof (ReturnPackageSummary)): ReturnPackageSortOrder {
        switch (key) {
            case "ssccNumber":
                return ReturnPackageSortOrder.SsccNumber;
            case "deliveredTime":
                return ReturnPackageSortOrder.DeliveredDate;
            case "status":
                return ReturnPackageSortOrder.Status;
            default:
                throw new Error(`unknown key ${key}`);
        }
    }

    private async loadMore(done: () => void) {
        await this.load(this.tableContent.length);
        done();
    }

    private get tableContent(): object[] {
        return this.returnPackageSummaries.map((d: ReturnPackageSummary) => ({
            ...d,
            deliveredTime: dateTimeFilter(d.deliveredTime),
            status: statusToDisplayNameAndColor(d.status)
        }));
    }

    private get toolbarItems() {
        return [ToolbarItem.TextSearch];
    }

}
