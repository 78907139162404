










import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { DeliveryInfo } from "../../../generated/contracts";
import Table from "../../atoms/table/Table.vue";
import Overlay from "../../components/overlay/Overlay.vue";
import { CardItemContent } from "../../atoms/cards/cardModels";
import CardItemAtom from "@atoms/cards/card-item/CardItem.vue";

@Component({
        components: { CardItemAtom, Table, Overlay }
    }
)
export default class DeliveryNotificationsView extends Vue {
    @Prop() private deliveryInfo!: DeliveryInfo;

    private async created() {
        //this.load();
    }

    private get infoSummary(): CardItemContent[] {
        return [
            {
                label: "Email",
                description: this.deliveryInfo.email
            },
            {
                label: "Phone",
                description: this.deliveryInfo.phoneNumber
            }
        ];
    }
}
