
































import { Component, Prop, Vue } from "vue-property-decorator";
import { GlobalStatistics, GlobalStatus } from "../../../generated/contracts";
import IconAtom from "@atoms/icons/Icons.vue";
import GraphRadial from "../../atoms/graph-radial/GraphRadial.vue";
import GraphProgress from "../../atoms/graph-progress/GraphProgress.vue";

@Component({
    components: {
        IconAtom,
        GraphRadial,
        GraphProgress
    }
})
export default class DCInfobox extends Vue {
    @Prop() private statistics!: GlobalStatistics;
    @Prop() private status!: GlobalStatus;

    private show = true;

    protected toggle() {
        this.show = !this.show;
    }

    private mounted() {
        window.addEventListener("resize", this.setShow);
        this.setShow();
    }

    private beforeDestroy() {
        window.removeEventListener("resize", this.setShow);
    }

    private setShow() {
        if (window.innerWidth <= 768) {
            this.show = false;
        }
    }
}
