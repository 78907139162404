


























import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "./Breadcrumb.vue";
import ContactInfo from "@atoms/contact-info/ContactInfo.vue";
import CustomerStats from "./CustomerStats.vue";
import ContentTemplate from "../../content/ContentTemplate.vue";
import { BasePageView, SalesAccountHeaderInfo } from "../../../../generated/contracts";
import { ContentSection, InTransit } from "../../content/types";
import InTransitAtom from "@atoms/in-transit/InTransit.vue";
import Overlay from "../../overlay/Overlay.vue";
import LiveRoute from "../../live-route/LiveRoute.vue";
import router from "../../../router";

@Component({
    components: {
        ContactInfo,
        CustomerStats,
        Breadcrumb,
        ContentTemplate,
        InTransitAtom,
        Overlay,
        LiveRoute
    }
})
export default class StandardLayout<T> extends Vue {
    protected pageView: BasePageView<T> | null = null;
    protected sections: ContentSection[] = [];
    protected inTransit: InTransit | null = null;
    protected showMap: boolean = false;
    protected contactType: "client" | "phone" = "client";

    protected created() {
        const showMapParam = router.currentRoute.query["showMap"];
        this.showMap = showMapParam ? showMapParam.toLowerCase() === "true" : false;
    }

    private doShowMap(show: boolean) {
        this.showMap = show;
        if (show) {
            router.replace({ query: { ...this.$route.query, showMap: "true" } });
        } else {
            const query: any = { ...this.$route.query };
            delete query.showMap;
            router.replace({ query });
        }
    }

    private get contactInfo(): SalesAccountHeaderInfo | undefined {
        return this.pageView ? this.pageView.headerContactInfo : undefined;
    }

    private get title() {
        return this.contactInfo ? this.contactInfo.header1 : null;
    }

    private get address() {
        return this.contactInfo ? this.contactInfo.header2 : null;
    }

    private get accountNumber() {
        return this.contactInfo ? `Kunde nr. ${this.contactInfo.header3}` : null;
    }

    private get phoneNumber() {
        return this.contactInfo ? this.contactInfo.header3 : null;
    }
}
