import endpointService from "./endpoint.service";
import {
    AddOrUpdateNotificationRequest,
    CustomerPageView,
    CustomerReturnDeliveryPageView,
    CustomerReturnDeliverySortOrder,
    CustomerReturnDeliverySummary,
    DeliveryLineItemSortOrder,
    DeliveryLineItemSummary,
    DeliveryOnRouteSortOrder,
    DeliveryOnRouteSummary,
    DeliveryPageView,
    DeliveryRouteView,
    DeliverySortOrder,
    DeliverySummary,
    DistributionCenter,
    DistributionCenterPageView,
    DriverPageView,
    ErrorSortOrder,
    ErrorSummary,
    ExtendedDeliveryLineItemSortOrder,
    ExtendedDeliveryLineItemSummary,
    GlobalStatistics,
    GlobalStatus,
    LoginView,
    LogisticsType,
    NavigationView,
    OrderPageView,
    OrderSortOrder,
    OrderSummary,
    PackagePageView,
    PackageSortOrder,
    PackageSummary,
    ProjectPageView,
    ReturnDeliveryPackagePageView,
    ReturnPackageSortOrder,
    ReturnPackageSummary,
    SalesAccountNotificationSettingView,
    SearchQueryResult,
    SortBy,
    TrackAndTraceView,
    StoreReturnDeliveryPackagePageView,
    DriverDeliveryRoutesPageView,
    DeliveryRouteSortOrder,
    DeliveryRouteSummary,
    DeliveryRouteDetailsPageView
} from "@/generated/contracts";

class SearchController {
    public async search(
        searchStr: string,
        from: Number = 0,
        size: Number = 0,
        type: LogisticsType | null = null
    ): Promise<SearchQueryResult> {
        return endpointService.get<SearchQueryResult>("search", {
            query: searchStr,
            from,
            size,
            type
        });
    }
}

class ContentController {
    public async getNavigation(): Promise<NavigationView> {
        return endpointService.get<NavigationView>("content/navigation");
    }

    public async getGlobalStatus(): Promise<GlobalStatus> {
        return endpointService.get<GlobalStatus>("content/globalstatus");
    }

    public async updateGlobalStatus(status: GlobalStatus): Promise<GlobalStatus> {
        return endpointService.post<GlobalStatus>("content/globalstatus/", { message: status.message });
    }
}

class StatisticsController {
    public async getGlobalStatistics(): Promise<GlobalStatistics> {
        return endpointService.get<GlobalStatistics>("statistics/globalstatistics");
    }
}

class DriverController {
    public async getDriverPage(
        userName: string,
        sortOrder: DeliveryOnRouteSortOrder,
        sortBy: SortBy,
        take: number
    ): Promise<DriverPageView> {
        return endpointService.get<DriverPageView>(`driver/page/${userName}`, {
            sortOrder,
            sortBy,
            take
        });
    }

    public async getDeliveriesOnRoute(
        userName: string,
        sortOrder: DeliveryOnRouteSortOrder,
        sortBy: SortBy,
        query: string | null,
        fromDate: string | null,
        toDate: string | null,
        skip: number,
        take: number
    ): Promise<DeliveryOnRouteSummary[]> {
        return endpointService.get<DeliveryOnRouteSummary[]>(`driver/${userName}/search`, {
            sortOrder,
            sortBy,
            query,
            fromDate,
            toDate,
            skip,
            take
        });
    }

    public async getDeliveriesOnRouteByDeliveryRouteId(
        id: string,
        sortOrder: DeliveryOnRouteSortOrder,
        sortBy: SortBy,
        query: string | null,
        fromDate: string | null,
        toDate: string | null,
        skip: number,
        take: number
    ): Promise<DeliveryOnRouteSummary[]> {
        return endpointService.get<DeliveryOnRouteSummary[]>(`driver/deliveryroute/${id}/search`, {
            sortOrder,
            sortBy,
            query,
            fromDate,
            toDate,
            skip,
            take
        });
    }

    public async getDeliveryRoutesPage(
        userName: string,
        sortOrder: DeliveryRouteSortOrder,
        sortBy: SortBy,
        fromDate: string | null,
        toDate: string | null,
        skip: number,
        take: number
    ): Promise<DeliveryRouteSummary[]> {
        return endpointService.get<DeliveryRouteSummary[]>(`driver/deliveryroute/${userName}`, {
            sortOrder,
            sortBy,
            fromDate,
            toDate,
            skip,
            take
        });
    }

    public async getDeliveryRouteDetailsPage(
        id: string,
        sortOrder: DeliveryOnRouteSortOrder,
        sortBy: SortBy,
        take: number
    ): Promise<DeliveryRouteDetailsPageView> {
        return endpointService.get<DeliveryRouteDetailsPageView>(`driver/deliveryroute/page/${id}`, {
            sortOrder,
            sortBy,
            take
        });
    }
}

class DistributionCenterController {
    public async getDistributionCenters(): Promise<DistributionCenter[]> {
        return endpointService.get<DistributionCenter[]>("distributioncenter");
    }

    public async updateDistributionCenterMessage(distributionCenter: DistributionCenter): Promise<DistributionCenter> {
        return endpointService.put<DistributionCenter>(`distributioncenter/${distributionCenter.name}/message`, {
            message: distributionCenter.message
        });
    }

    public async updateDistributionCenter(distributionCenter: DistributionCenter): Promise<DistributionCenter> {
        return endpointService.put<DistributionCenter>(`distributioncenter/${distributionCenter.name}/`, {
            isDelayedNotificationEnabled: distributionCenter.isDelayedNotificationEnabled
        });
    }

    public async getDistributionCenterPage(
        name: string,
        sortOrder: PackageSortOrder,
        sortBy: SortBy,
        take: number
    ): Promise<DistributionCenterPageView> {
        return endpointService.get<DistributionCenterPageView>(`distributioncenter/page/${name}`, {
            sortOrder,
            sortBy,
            take
        });
    }

    public async getDistributionCenterErrors(
        storeName: string,
        sortOrder: ErrorSortOrder,
        sortBy: SortBy,
        query: string | null,
        fromDate: Date | null,
        toDate: Date | null,
        skip: number,
        take: number
    ): Promise<ErrorSummary[]> {
        return endpointService.get<ErrorSummary[]>(`distributioncenter/${storeName}/error/search`, {
            sortOrder,
            sortBy,
            query,
            fromDate,
            toDate,
            skip,
            take
        });
    }

    public async getDistributionCenterReturnErrors(
        storeName: string,
        sortOrder: ErrorSortOrder,
        sortBy: SortBy,
        query: string | null,
        fromDate: Date | null,
        toDate: Date | null,
        skip: number,
        take: number
    ): Promise<ErrorSummary[]> {
        return endpointService.get<ErrorSummary[]>(`distributioncenter/${storeName}/error/return/search`, {
            sortOrder,
            sortBy,
            query,
            fromDate,
            toDate,
            skip,
            take
        });
    }
}

class CustomerController {
    public async getCustomerPage(
        accountNumber: string,
        sortOrder: OrderSortOrder,
        sortBy: SortBy,
        take: number
    ): Promise<CustomerPageView> {
        return endpointService.get<CustomerPageView>(`customer/page/${accountNumber}`, {
            sortOrder,
            sortBy,
            take
        });
    }

    public async getNotifications(accountNumber: string): Promise<SalesAccountNotificationSettingView> {
        return endpointService.get<SalesAccountNotificationSettingView>(`customer/notification/${accountNumber}`);
    }

    public async getCustomerReturnDeliverySummaries(
        accountNumber: string,
        sortOrder: CustomerReturnDeliverySortOrder,
        sortBy: SortBy,
        query: string | null,
        fromDate: string | null,
        toDate: string | null,
        skip: number,
        take: number
    ): Promise<CustomerReturnDeliverySummary[]> {
        return endpointService.get<CustomerReturnDeliverySummary[]>(`customer/returndelivery/search`, {
            accountNumber,
            sortOrder,
            sortBy,
            query,
            fromDate,
            toDate,
            skip,
            take
        });
    }
}

class ProjectController {
    public async getProjectPage(accountNumber: string, sortOrder: OrderSortOrder, sortBy: SortBy, take: number): Promise<ProjectPageView> {
        return endpointService.get<ProjectPageView>(`project/page/${accountNumber}`, {
            sortOrder,
            sortBy,
            take
        });
    }

    public async getNotifications(accountNumber: string): Promise<SalesAccountNotificationSettingView> {
        return endpointService.get<SalesAccountNotificationSettingView>(`project/notification/${accountNumber}`);
    }

    public async getCustomerReturnDeliverySummaries(
        accountNumber: string,
        sortOrder: CustomerReturnDeliverySortOrder,
        sortBy: SortBy,
        query: string | null,
        fromDate: string | null,
        toDate: string | null,
        skip: number,
        take: number
    ): Promise<ReturnPackageSummary[]> {
        return endpointService.get<ReturnPackageSummary[]>(`project/returndelivery/search`, {
            accountNumber,
            sortOrder,
            sortBy,
            query,
            fromDate,
            toDate,
            skip,
            take
        });
    }
}

class StoreController {
    public async getStorePage(accountNumber: string, sortOrder: OrderSortOrder, sortBy: SortBy, take: number): Promise<ProjectPageView> {
        return endpointService.get<ProjectPageView>(`store/page/${accountNumber}`, {
            sortOrder,
            sortBy,
            take
        });
    }

    public async getStoreErrors(
        accountNumber: string,
        sortOrder: ErrorSortOrder,
        sortBy: SortBy,
        query: string | null,
        fromDate: Date | null,
        toDate: Date | null,
        skip: number,
        take: number
    ): Promise<ErrorSummary[]> {
        return endpointService.get<ErrorSummary[]>(`store/error/search`, {
            accountNumber,
            sortOrder,
            sortBy,
            query,
            fromDate,
            toDate,
            skip,
            take
        });
    }

    public async getStoreReturnErrors(
        accountNumber: string,
        sortOrder: ErrorSortOrder,
        sortBy: SortBy,
        query: string | null,
        fromDate: Date | null,
        toDate: Date | null,
        skip: number,
        take: number
    ): Promise<ErrorSummary[]> {
        return endpointService.get<ErrorSummary[]>(`store/error/return/search`, {
            accountNumber,
            sortOrder,
            sortBy,
            query,
            fromDate,
            toDate,
            skip,
            take
        });
    }

    public async getStoreReturnDeliveries(
        warehouse: string,
        sortOrder: ErrorSortOrder,
        sortBy: SortBy,
        query: string | null,
        fromDate: Date | null,
        toDate: Date | null,
        skip: number,
        take: number
    ): Promise<ReturnPackageSummary[]> {
        return endpointService.get<ReturnPackageSummary[]>(`store/returndelivery/search`, {
            warehouse,
            sortOrder,
            sortBy,
            query,
            fromDate,
            toDate,
            skip,
            take
        });
    }

    public async getStoreReturnPackagePage(storeAccountNumber: string, returnSsccNumber: string): Promise<StoreReturnDeliveryPackagePageView> {
        return endpointService.get<StoreReturnDeliveryPackagePageView>(`store/${storeAccountNumber}/returndelivery/${returnSsccNumber}`, {});
    }
}

class NotificationController {
    public async saveNotification(accountNumber: string, payload: AddOrUpdateNotificationRequest): Promise<void> {
        return endpointService.post<void>(`notificationSetting/${accountNumber}`, payload);
    }

    public async applyToAll(accountNumber: string): Promise<void> {
        return endpointService.post<void>(`notificationSetting/clearall/${accountNumber}`);
    }
}

class OrderController {
    public async getOrderPage(orderNumber: string, sortOrder: DeliverySortOrder, sortBy: SortBy, take: number): Promise<OrderPageView> {
        return endpointService.get<OrderPageView>(`order/page/${orderNumber}`, {
            sortOrder,
            sortBy,
            take
        });
    }

    public async getOrderDetails(
        accountNumber: string,
        sortOrder: OrderSortOrder,
        sortBy: SortBy,
        query: string | null,
        fromDate: string | null,
        toDate: string | null,
        skip: number,
        take: number
    ): Promise<OrderSummary[]> {
        return endpointService.get<OrderSummary[]>("order/search", {
            accountNumber,
            sortOrder,
            sortBy,
            query,
            fromDate,
            toDate,
            skip,
            take
        });
    }

    public async getOrderDeliveryLines(
        orderNumber: string,
        sortOrder: ExtendedDeliveryLineItemSortOrder,
        sortBy: SortBy,
        query: string | null,
        skip: number,
        take: number
    ): Promise<ExtendedDeliveryLineItemSummary[]> {
        return endpointService.get<ExtendedDeliveryLineItemSummary[]>("order/deliverylineitem/search", {
            orderNumber,
            sortOrder,
            sortBy,
            query,
            skip,
            take
        });
    }
}

class DeliveryController {
    public async getDeliveryPage(noteNumber: string, sortOrder: PackageSortOrder, sortBy: SortBy, take: number): Promise<DeliveryPageView> {
        return endpointService.get<DeliveryPageView>(`delivery/page/${noteNumber}`, {
            sortOrder,
            sortBy,
            take
        });
    }

    public async getDeliverySummaries(
        orderNumber: string,
        sortOrder: DeliverySortOrder,
        sortBy: SortBy,
        query: string | null,
        skip: number,
        take: number
    ): Promise<DeliverySummary[]> {
        return endpointService.get<DeliverySummary[]>("delivery/search/", {
            orderNumber,
            sortOrder,
            sortBy,
            query,
            skip,
            take
        });
    }

    public async share(to: "customer" | "colleague", noteNumber: string, name: string, email: string, message: string, images: string[]): Promise<void> {
        return endpointService.post<void>(`delivery/share/${to}/${noteNumber}`, {
            name,
            email,
            message,
            images
        });
    }

    public async getCustomerReturnDeliveryPage(
        returnNoteNumber: string,
        sortOrder: ReturnPackageSortOrder,
        sortBy: SortBy,
        take: number
    ): Promise<CustomerReturnDeliveryPageView> {
        return endpointService.get<CustomerReturnDeliveryPageView>(`delivery/return/page/${returnNoteNumber}`, {
            sortOrder,
            sortBy,
            take
        });
    }

    public async shareReturn(
        to: "customer" | "colleague",
        returnNoteNumber: string,
        name: string,
        email: string,
        message: string,
        images: string[]
    ): Promise<void> {
        return endpointService.post<void>(`delivery/return/share/${to}/${returnNoteNumber}`, {
            name,
            email,
            message,
            images
        });
    }
}

class PackageController {
    public async getPackagePage(
        ssccNumber: string,
        sortOrder: DeliveryLineItemSortOrder,
        sortBy: SortBy,
        take: number
    ): Promise<PackagePageView> {
        return endpointService.get<PackagePageView>(`package/page/${ssccNumber}`, {
            sortOrder,
            sortBy,
            take
        });
    }

    public async getPackageSummaries(
        noteNumber: string,
        sortOrder: PackageSortOrder,
        sortBy: SortBy,
        query: string | null,
        skip: number,
        take: number
    ): Promise<PackageSummary[]> {
        return endpointService.get<PackageSummary[]>("package/search", {
            noteNumber,
            sortOrder,
            sortBy,
            query,
            skip,
            take
        });
    }

    public async share(to: "customer" | "colleague", ssccNumber: string, name: string, email: string, message: string, images: string[]): Promise<void> {
        return endpointService.post<void>(`package/share/${to}/${ssccNumber}`, {
            name,
            email,
            message,
            images
        });
    }
}

class ReturnDeliveryPackageController {
    public async getReturnPackagePage(returnSsccNumber: string): Promise<ReturnDeliveryPackagePageView> {
        return endpointService.get<ReturnDeliveryPackagePageView>(`returndeliverypackage/page/${returnSsccNumber}`, {});
    }

    public async getReturnPackageSummaries(
        noteNumber: string,
        sortOrder: ReturnPackageSortOrder,
        sortBy: SortBy,
        query: string | null,
        skip: number,
        take: number
    ): Promise<ReturnPackageSummary[]> {
        return endpointService.get<ReturnPackageSummary[]>("returndeliverypackage/search/", {
            noteNumber,
            sortOrder,
            sortBy,
            query,
            skip,
            take
        });
    }

    public async share(
        to: "customer" | "colleague",
        returnSsccNumber: string,
        name: string,
        email: string,
        message: string,
        images: string[]
    ): Promise<void> {
        return endpointService.post<void>(`returndeliverypackage/share/${to}/${returnSsccNumber}`, {
            name,
            email,
            message,
            images
        });
    }
}

class DeliveryLineItemController {
    public async getDeliveryLineItemSummaries(
        ssccNumber: string,
        sortOrder: DeliveryLineItemSortOrder,
        sortBy: SortBy,
        query: string | null,
        skip: number,
        take: number
    ): Promise<DeliveryLineItemSummary[]> {
        return endpointService.get<DeliveryLineItemSummary[]>("deliverylineitem/search", {
            ssccNumber,
            sortOrder,
            sortBy,
            query,
            skip,
            take
        });
    }
}

class LoginController {
    public async login(username: string, password: string): Promise<LoginView> {
        return endpointService.post<LoginView>("login/portal", {
            username,
            password
        });
    }
}

class DeliveryRouteController {
    public async getRoute(id: string): Promise<DeliveryRouteView> {
        return endpointService.get<DeliveryRouteView>(`deliveryroute/${id}`);
    }
}

class TrackAndTraceController {
    public async getPackage(ssccNumber: string): Promise<TrackAndTraceView> {
        return endpointService.get<TrackAndTraceView>(`trackandtrace/${ssccNumber}`);
    }
}

class ExcludedPostalCodesController {
    public async get(): Promise<string[]> {
        return endpointService.get<string[]>("excludedpostalcodes");
    }

    public async put(excludedPostalCode: string): Promise<void> {
        return endpointService.put<void>("excludedpostalcodes", {
            excludedPostalCode
        });
    }

    public async delete(excludedPostalCodes: string[]): Promise<void> {
        return endpointService.httpDelete("excludedpostalcodes", {
            excludedPostalCodes });
    }
}

export default class Api {
    public static content = new ContentController();
    public static statistics = new StatisticsController();
    public static distributioncenter = new DistributionCenterController();
    public static search = new SearchController();
    public static customer = new CustomerController();
    public static project = new ProjectController();
    public static store = new StoreController();
    public static order = new OrderController();
    public static delivery = new DeliveryController();
    public static package = new PackageController();
    public static returnDeliveryPackage = new ReturnDeliveryPackageController();
    public static deliveryLineItem = new DeliveryLineItemController();
    public static login = new LoginController();
    public static deliveryroute = new DeliveryRouteController();
    public static notification = new NotificationController();
    public static driver = new DriverController();
    public static trackAndTrace = new TrackAndTraceController();
    public static delayedDelivery = new ExcludedPostalCodesController();
}
