
import { Component, Prop } from "vue-property-decorator";
import StandardLayout from "../../components/layout/standard-layout/StandardLayout.vue";
import { ReturnDeliveryPackageView } from "../../../generated/contracts";
import Api from "../../../core/api/Api";
import PickupInfo from "./PickupInfo.vue";

@Component
export default class ReturnPackagePage extends StandardLayout<ReturnDeliveryPackageView> {

    @Prop() private id!: string;

    protected async created() {
        this.pageView = await Api.returnDeliveryPackage.getReturnPackagePage(this.id);
        this.sections = [
            {
                name: "Afhentningsinfo",
                component: PickupInfo,
                props: {
                    pickupInfo: this.pageView.content.pickupInfo
                },
                events: {
                    shareInfo: this.returnInfoShareInfo
                }
            }
        ];
    }

    private returnInfoShareInfo(target: "customer" | "colleague", name: string, email: string, message: string, images: string[]) {
        Api.returnDeliveryPackage.share(target, this.id, name, email, message, images);
    }
}
