import { JL } from "jsnlog";

init();

class LoggingService {
    public debug(logObject: any): void {
        if (this.isDebugMode) {
            console.debug(logObject);
        }
        JL().debug(logObject);
    }

    public info(logObject: any) {
        if (this.isDebugMode) {
            console.info(logObject);
        }
        JL().warn(logObject);
    }

    public warn(logObject: any) {
        if (this.isDebugMode) {
            console.warn(logObject);
        }
        JL().warn(logObject);
    }

    public error(logObject: any) {
        if (this.isDebugMode) {
            console.error(logObject);
        }
        JL().error(logObject);
    }

    public exception(logObject: any, e: any) {
        if (this.isDebugMode) {
            console.error(logObject, e);
        }
        JL().fatalException(logObject, e);
    }

    get isDebugMode() {
        return process.env.NODE_ENV !== "production";
    }
}

function init() {
    (window as any).__jsnlog_configure = _JL => {
        _JL.setOptions({
            // defaults are Ok.
        });
    };
    try {
        (window as any).__jsnlog_configure(JL);
    } catch (e) {
        // Thats ok. Default jsnlog config recommandation to avoid racecondition
    }
}

export default new LoggingService();
