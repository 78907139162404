import XLSX from "xlsx";
import { TableHeader } from "../../ui/atoms/table/tableModels";
class ExcelService {
    private tableContent: any[];
    private columnMap: object;
    private columnOrder: string[];

    constructor(content: any[], header: TableHeader[]) {
        this.tableContent = content;
        this.columnMap = header.reduce((o, element) => ({...o, [element.key]: element.title}), {});
        this.columnOrder = header.map(o => o.title);
    }

    public exportTableToXlsx(): void {
        const content = this.tableContent.map(row => this.reMapObject(row));
        const sheet = XLSX.utils.json_to_sheet(content, {header: this.columnOrder});
        const wb = XLSX.utils.book_new();
        const tableName = this.getTableName();
        const fileName = this.getFileName();
        wb.SheetNames.push(tableName);
        wb.Sheets[tableName] = sheet;
        XLSX.writeFile(wb, fileName,  {bookType: "xlsx", type: "binary"});
    }

    private renameColumns(row: object): object {
        return Object.keys(row).reduce(
            (accumulator, key) => ({
                ...accumulator,
                ...{ [this.columnMap[key] || key ]: row[key]}
            }), {}
        );
    }

    private reMapObject(obj: object): object {
        const flatRow = Object.keys(obj)
                    // Filter out columns that are not present in the table view
                    .filter(key => this.columnMap.hasOwnProperty(key))
                    // Flatten any rich objects according to a list of re-write rules
                    .reduce((accumulator, key) => ({
                        ...accumulator,
                        ...{ [key]: typeof obj[key] === "object" ? this.reMapRules(key, obj[key]) : obj[key] }
                    }), {});
        return this.renameColumns(flatRow);
    }

    private getTableName(): string {
        const tableName = document.getElementsByClassName("item --active")[0] as HTMLElement;
        return tableName.innerText as string || "";
    }

    private getFileName(): string {
        const breadcrumb = document.getElementsByClassName("breadcrumb")[0] as HTMLElement;
        return breadcrumb.innerText.split(" ").join("_") + ".xlsx";
    }

    private reMapRules(key: string, richObject: object) {
        const lowerKey = key.toLowerCase();
        switch (lowerKey) {
            case "status":
                return richObject["label"];
            default:
                return richObject;
        }
    }
}

export default ExcelService;
