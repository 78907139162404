

































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Facet, LogisticsType, SearchQueryResult, SearchResult } from "../../../generated/contracts";
import { logisticsTypeDisplayName } from "../../util/dictionary";
import ListItem from "@atoms/list-item/ListItem.vue";
import Tabs from "@atoms/tabs/Tabs.vue";
import TabItem from "../../atoms/tabs/tabsModel";
import IsSearchingAnimation from "./_IsSearchingAnimation.vue";
import SearchAnimation from "./_SearchAnimation.vue";
import NoResultsAnimation from "./_NoResultsAnimation.vue";
import LoaderAtom from "@atoms/loader/Loader.vue";
import bus from "../../../core/bus/Bus.vue";

@Component({
    components: {
        ListItem,
        Tabs,
        SearchAnimation,
        IsSearchingAnimation,
        NoResultsAnimation,
        LoaderAtom
    }
})
export default class SearchResultComp extends Vue {
    private activeFacet: Facet | null = null;
    private stickyFacet: boolean = false;

    @Prop() private term!: string;
    @Prop() private searchResult!: SearchQueryResult;
    @Prop() private isSearching!: boolean;

    private created() {
        bus.onEndlessScrollLoadMore(this.loadMore);
        bus.onEndlessScrollScrollPos(this.scrollPos);
        this.updateUrl();
    }

    private beforeDestroy() {
        bus.unregisterEndlessScrollLoadMore(this.loadMore);
        bus.unregisterEndlessScrollScrollPos(this.scrollPos);
    }

    public $refs!: {
        facets: HTMLElement,
        items: HTMLElement[]
    };

    private pickedOne(searchItem: SearchResult) {
        this.$emit("pickedOne", searchItem);
    }

    private loadMore(done: () => void) {
        this.$emit("loadMore", this.activeFacet ? this.activeFacet.type : null, done);
    }

    private scrollPos(pos) {
        this.stickyFacet = pos > 0 ? true : false;
    }

    private get facets(): TabItem[] {
        if (!this.searchResult || !this.searchResult.facets) {
            return [];
        }

        function typePriority(type: LogisticsType): number {
            const tabOrder = [
                LogisticsType.Customer,
                LogisticsType.Project,
                LogisticsType.Order,
                LogisticsType.Delivery,
                LogisticsType.Package,
                LogisticsType.CustomerReturnDelivery,
                LogisticsType.ReturnPackage,
                LogisticsType.Store,
                LogisticsType.DistributionCenter,
                LogisticsType.Driver
            ];
            return tabOrder.indexOf(type);
        }

        const tabItems: TabItem[] = this.searchResult.facets.map(facet => {
            return {
                title: this.typeDisplayName(facet.type),
                disabled: facet.count === 0,
                hits: facet.count,
                userData: facet
            };
        })
            .sort((a, b) => typePriority(a.userData.type) - typePriority(b.userData.type));

        tabItems
            .unshift({
                title: "Alle",
                disabled: this.totalSearchResults === 0,
                hits: this.totalSearchResults
            });
        return tabItems;
    }

    private tabSelected(tab: TabItem) {
        this.activeFacet = tab.userData ? tab.userData as Facet : null;
        this.$emit("loadNew", this.activeFacet ? this.activeFacet.type : null);
        this.updateUrl();
    }

    private updateUrl() {
        this.$router.replace({
            query: {
                ...this.$route.query,
                facet: this.activeFacet ? this.activeFacet.type.toString() : "all"
            }
        });
    }

    private get searchItems(): SearchResult[] {
        if (!this.searchResult || !this.searchResult.results) {
            return [];
        }
        return this.searchResult.results;
    }

    private upDownEnter(event) {
        if (!aSearchResultItemIsFocused()) {
            return;
        }
        switch (event.key) {
            case "Enter":
                (document.activeElement as HTMLBaseElement).click();
                break;
            case "ArrowDown":
            case "Down":
                const next = (document.activeElement as HTMLBaseElement).nextElementSibling as HTMLBaseElement;
                if (next) {
                    next.focus();
                }
                break;
            case "ArrowUp":
            case "Up":
                const previous = (document.activeElement as HTMLBaseElement).previousElementSibling as HTMLBaseElement;
                if (previous) {
                    previous.focus();
                }
        }

        function aSearchResultItemIsFocused() {
            return document.activeElement && document.activeElement.classList.contains("search-result-item");
        }
    }

    private get totalSearchResults() {
        return this.searchResult ? this.searchResult.facets.reduce((sum, facet) => {
            return sum + facet.count;
        }, 0) : 0;
    }

    private typeDisplayName(type: LogisticsType) {
        return logisticsTypeDisplayName(type);
    }

    @Watch("term")
    private onTermUpdated(value) {
        if (value === "") {
            this.activeFacet = null;
        }
    }

}
