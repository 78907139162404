
import { Component, Prop } from "vue-property-decorator";
import StandardLayout from "../../components/layout/standard-layout/StandardLayout.vue";
import { DeliveryOnRouteSortOrder, DeliveryOnRouteSummary, DeliveryRouteDetailsView, SortBy } from "../../../generated/contracts";
import Api from "../../../core/api/Api";
import constants from "../../../core/constants";
import DeliveryRouteHistoryView from "./DeliveryRouteHistoryView.vue";

@Component
export default class DeliveryRoutePage extends StandardLayout<DeliveryRouteDetailsView> {
    private deliveriesOnRoute: DeliveryOnRouteSummary[] = [];

    @Prop() private id!: string;

    protected async created() {
        this.pageView = await Api.driver.getDeliveryRouteDetailsPage(this.id, DeliveryOnRouteSortOrder.Status, SortBy.Ascending,
            constants.NoOfRowsAtATime);

        // Remove statistics for now
        (this.pageView as any).statistics = null;

        this.deliveriesOnRoute = (this.pageView.content as DeliveryRouteDetailsView).deliveriesOnRoute;
        this.sections = [
            {
                name: "Leveringshistorik",
                component: DeliveryRouteHistoryView,
                props: {
                    id: this.id,
                    tab: "0"
                }
            }
        ];

        if (this.pageView.content.currentDeliveryRouteId) {
            this.inTransit = {
                routeId: this.pageView.content.currentDeliveryRouteId,
                displayText: `Ruten`,
                simulated: false
            };
        }
        this.contactType = "phone";
    }
}
