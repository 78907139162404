




import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { quillEditor } from "vue-quill-editor";

@Component({
    components: {
        quillEditor
    }
})
export default class HtmlEditor extends Vue {
    @Prop() private value!: string;
    @Prop({ default: 0, type: Number }) private maxChars!: number;
    @Prop() private options;

    private change(value: string) {
        this.$emit("input", value);
    }

    protected mounted() {
        if (this.maxChars) {
            this.quill.on("text-change", () => {
                const currLen = this.quill.getLength();
                if (currLen > this.maxChars) {
                    this.quill.deleteText(this.maxChars, currLen);
                }
                this.sendCharactersLeft();
            });
        }
        this.sendCharactersLeft();
    }

    private sendCharactersLeft() {
        this.$emit("rawCharacterCount", this.quill.getLength() - 1);
    }

    @Watch("value")
    private onValueChange() {
        this.sendCharactersLeft();
    }

    private get quill() {
        return (this.$refs.editor as any).quill;
    }
}
