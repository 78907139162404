
import { Component, Prop } from "vue-property-decorator";
import StandardLayout from "../../components/layout/standard-layout/StandardLayout.vue";
import { DeliveryLineItemSortOrder, DeliveryLineItemSummary, PackageView, SortBy } from "../../../generated/contracts";
import Api from "../../../core/api/Api";
import constants from "../../../core/constants";
import DeliveryInfo from "../shared/delivery-info/DeliveryInfo.vue";
import PackageDetailsView from "./PackageDetailsView.vue";

@Component
export default class PackagePage extends StandardLayout<PackageView> {

    private deliveryLineItemSummaries: DeliveryLineItemSummary[] = [];

    @Prop() private id!: string;

    protected async created() {
        this.pageView = await Api.package.getPackagePage(this.id, DeliveryLineItemSortOrder.Status, SortBy.Ascending,
            constants.NoOfRowsAtATime);
        this.sections = [
            {
                name: "Kollidetaljer",
                component: PackageDetailsView,
                props: {
                    id: this.id,
                    tab: "0"
                }
            },
            {
                name: "Leveringsinfo",
                component: DeliveryInfo,
                props: {
                    deliveryInfo: this.pageView.content.deliveryInfo
                },
                events: {
                    shareInfo: this.deliveryInfoShareInfo
                }
            }
        ];

        if (this.pageView.content.order.isInTransit) {
            this.inTransit = {
                routeId: this.pageView.content.order.currentDeliveryRouteId!,
                displayText: `Levering på vej`,
                orderNumber: this.pageView.content.order.orderNumber,
                simulated: !this.pageView.content.order.isInTransit
            };
        }
    }

    private deliveryInfoShareInfo(target: "customer" | "colleague", name: string, email: string, message: string, images: string[]) {
        Api.package.share(target, this.id, name, email, message, images);
    }
}
