














import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { ContentSection } from "./types";
import Component from "vue-class-component";
import Tabs from "@atoms/tabs/Tabs.vue";
import TabContent from "@atoms/tabs/TabContent.vue";
import TabItem from "../../atoms/tabs/tabsModel";
import router from "../../router";
import SkeletonLoaderAtom from "@atoms/skeleton-loader/SkeletonLoader.vue";

@Component({
    components: {
        Tabs,
        TabContent,
        SkeletonLoaderAtom
    }
})
export default class ContentTemplate extends Vue {
    private initialActiveIndex = 0;

    @Prop({
        type: Array
    })
    private sections!: ContentSection[];

    private created() {
        this.initialActiveIndex = parseInt(router.currentRoute.query["tab"], 10) || 0;
        if (!router.currentRoute.query["tab"]) {
            router.replace({ query: {
                    ...this.$route.query,
                    tab: this.initialActiveIndex.toString() } });
        }

    }

    private tabSelected(tab: TabItem) {
        router.replace({ query: { tab: tab.userData.ix } });
    }

    private get tabs(): TabItem[] {
        if (!this.sections) {
            return [];
        }
        return this.sections.map((section, ix) => ({
            title: section.name,
            userData: {
                ix: ix.toString()
            }
        }));
    }
}
