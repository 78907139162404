



















import { Component, Prop, Vue } from "vue-property-decorator";
import { DropdownModel } from "./dropdownModel";

import IconAtom from "@atoms/icons/Icons.vue";

@Component({
    components: {
        IconAtom
    }
})
export default class DropdownAtom extends Vue {
    @Prop() private content!: DropdownModel;
    @Prop() private static!: boolean;
    @Prop() private hoverEffect!: boolean;
    @Prop() private small!: boolean;
    @Prop({ default: true }) private showActive!: boolean;
    private placeholder = "";
    private currentIndex = 0;
    private dropdownActive = false;

    public $refs!: {
        dropdown: HTMLDivElement
    };

    private get getClass() {
        return {
            "--active": this.dropdownActive,
            "--small": this.small,
            "--hover-effect": this.hoverEffect
        };
    }

    private mounted() {
        this.$refs.dropdown.addEventListener("click", (e) => {
            this.dropdownActive = true;
            e.stopPropagation();
        });

        document.body.addEventListener("click", this.closeDropdown);

        this.setPlaceholder();
    }

    private updated() {
        this.setPlaceholder();
    }

    private setPlaceholder() {
        if (this.content.placeholder !== undefined && this.content.placeholder.length > 0) {
            this.placeholder = this.content.placeholder;
        } else if (this.content.options !== undefined && this.content.options[this.currentIndex].label !== undefined) {
            this.placeholder = this.content.options[this.currentIndex].label;
        }
    }

    private beforeDestroy() {
        document.body.removeEventListener("click", this.closeDropdown);
    }

    private toggleDropdown() {
        this.dropdownActive = !this.dropdownActive;
    }

    private closeDropdown() {
        this.dropdownActive = false;
    }

    private selectOption(ix) {
        this.currentIndex = this.static ? null : ix;

        this.$emit("dropdownSelect", {
            index: ix,
            label: this.content.options[ix].label,
            value: this.content.options[ix].value
        });

        setTimeout(() => {
            this.closeDropdown();
        }, 50);
    }
}
