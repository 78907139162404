













import { Vue, Component, Prop } from "vue-property-decorator";

import IconAtom from "@atoms/icons/Icons.vue";

@Component({
    components: {
        IconAtom
    }
})
export default class NoResultsAnimation extends Vue {
    @Prop({ default: "Ingen resultater" }) private text!: string;
}
