declare const window: any;

interface IStorage {
    isImplemented(): boolean;
    getItem(key: string): string | null;
    setItem(key: string, value: any): boolean;
    removeItem(key: string): void;
    clear(): boolean;
    key(index: number): string | null;
}

class SessionStorageService {
    public static create(): IStorage {
        return hasStorage(window, "sessionStorage")
            ? new BaseStorage(window.sessionStorage)
            : new FakeStorage();
    }
}

class LocalStorageService {
    public static create(): IStorage {
        return hasStorage(window, "localStorage")
            ? new BaseStorage(window.localStorage)
            : new FakeStorage();
    }
}

class BaseStorage implements IStorage {
    constructor(private store: any) {}

    public isImplemented(): boolean {
        return true;
    }

    public getItem(key: string): string | null {
        return this.store.getItem(key);
    }

    public setItem(key: string, value: any): boolean {
        try {
            this.store.setItem(key, value);
            return true;
        } catch (ex) {
            return false;
        }
    }

    public removeItem(key: string): void {
        return this.store.removeItem(key);
    }

    public clear() {
        return this.store.clear();
    }

    public key(index: number): string | null {
        return this.store.key(index);
    }
}

class FakeStorage implements IStorage {
    public isImplemented(): boolean {
        return false;
    }

    public getItem(key: string): string | null {
        return null;
    }

    public setItem(key: string, value: any): boolean {
        return false;
    }

    public removeItem(key: string): void {
        return;
    }

    public clear(): boolean {
        return true;
    }

    public key(index: number): string | null {
        return null;
    }
}

const hasStorage = ($window: any, name: string) => {
    const hasStorageSupport = name in $window;
    if (!hasStorageSupport) {
        return false;
    }
    const testKey = "storageTest";
    try {
        // this check has to be wrapped within a try/catch because of
        // a SecurityError: Dom Exception 18 on iOS
        if ($window[name] !== null) {
            $window[name].setItem(testKey, "foo");
            $window[name].removeItem(testKey);
            return true;
        } else {
            return false;
        }
    } catch (e) {
        return false;
    }
};

export const sessionStorage = SessionStorageService.create();
export const localStorage = LocalStorageService.create();
