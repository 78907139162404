






import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import router from "../../router";

@Component
export default class EndlessScroll extends Vue {
    private lastScrollHeight: Number | null = 0;
    private isActive = true;
    private ticking = false;
    private targetScrollTop = 0;

    @Prop({ default: 0, type: Number }) private threshold!: Number;

    public $refs!: {
        scrollContainer: HTMLBaseElement
    };

    private mounted() {
        router.afterEach(() => {
            this.isActive = true;
            this.lastScrollHeight = null;
            (this.$el as HTMLBaseElement).scrollTop = 0;
        });
    }

    private scroll(event) {
        const target: HTMLDivElement = event.target;

        // Throttle emitting scrollpos
        this.targetScrollTop = target.scrollTop;
        this.requestTick();

        if (!this.isActive) {
            return;
        }

        if (this.isBottomVisible(target)) {
            this.isActive = false;
            this.lastScrollHeight = null;
            this.$emit("loadMore", () => this.isActive = true);
        }
    }

    private tick() {
        this.ticking = false;
        this.$emit("scrollPos", this.targetScrollTop);
    }

    private requestTick() {
        if (!this.ticking) {
            requestAnimationFrame(this.tick);
        }
        this.ticking = true;
    }

    private isBottomVisible(target: HTMLDivElement): boolean {
        const offsetBelow = -(target.scrollTop - target.scrollHeight + target.clientHeight);
        return offsetBelow < this.threshold;
    }
}
