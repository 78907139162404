













import { Vue, Component, Prop } from "vue-property-decorator";
import ButtonAtom from "../button/Button.vue";

@Component({
    components: {
        ButtonAtom
    }
})
export default class InputTextareaAtom extends Vue {

    @Prop() private label!: string;
    @Prop() private placeholder!: string;
    @Prop() private errorMsg!: string;
    @Prop({ default: "" }) private value!: string;

    public $refs!: {
        input: HTMLInputElement
    };

    protected clearContent() {
        this.$refs.input.focus();
        this.$emit("input", "");
    }

    private onInput(event) {
        this.$emit("input", event.target.value);
    }
}
