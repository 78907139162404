















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { DeliveryRouteSortOrder, DeliveryRouteSummary } from "../../../generated/contracts";
import Api from "../../../core/api/Api";
import { TableHeader } from "../../atoms/table/tableModels";
import { dateTimeFilter } from "../../filters/dateTimeFilters";
import TableViewControlModel, { ToolbarItem } from "../../components/table-view/tableViewModels";
import constants from "../../../core/constants";
import { statusToDisplayNameAndColor, toDomainSortBy } from "../shared/tableHelper";
import TableView from "../../components/table-view/TableView.vue";
import router from "../../router";

interface ExtendedDeliveryRouteSummary extends DeliveryRouteSummary {
}

interface DeliveryRouteSummaryTableHeader extends TableHeader {
    key: keyof (ExtendedDeliveryRouteSummary);
}

@Component({
    components: { TableView }
})
export default class DeliveryRouteHistoryView extends Vue {
    @Prop() private id!: string;
    @Prop() private tab!: string;

    private loadFromUrl = this.tab === router.currentRoute.query["tab"];
    private initialSortOrder: keyof (DeliveryRouteSummary) = router.currentRoute.query["sortOrder"] && this.loadFromUrl ?
                (router.currentRoute.query["sortOrder"] as keyof (DeliveryRouteSummary))! :
                "createdTimeUtc";
    private initialSortBy = router.currentRoute.query["sortByAsc"] && this.loadFromUrl ?
                router.currentRoute.query["sortByAsc"] === "true" :
                true;
    private initialFromDate = router.currentRoute.query["fromDate"] && this.loadFromUrl ?
                router.currentRoute.query["fromDate"] as string :
                null;
    private initialToDate = router.currentRoute.query["toDate"] && this.loadFromUrl ?
                router.currentRoute.query["toDate"] as string :
                null;

    private tableControlModel: TableViewControlModel = {
        fromDate: this.initialFromDate ? this.initialFromDate : null,
        toDate: this.initialToDate ? this.initialToDate : null,
        searchTerm: null,
        sort: {
            sortOrder: this.initialSortOrder,
            sortByAscending: this.initialSortBy
        }
    };
    private loadingOrderBy = false;
    private loadingMore = false;
    private deliveryRouteSummaries: DeliveryRouteSummary[] = [];

    private mounted() {
        this.load(0, true);
    }

    private get tableHeader(): DeliveryRouteSummaryTableHeader[] {
        return [
            { key: "createdTimeUtc", title: "Dato", size: 25 },
            { key: "isCompleted", title: "Afsluttet", size: 25 },
            { key: "loadedPackagingPallets", title: "Paller med", size: 25 },
            { key: "loadedPackagingPalletFrames", title: "Rammer med", size: 25 },
            { key: "loadedBlueBoxes", title: "Blå kasser med", size: 25 },
            { key: "returnPackagingPallets", title: "Paller hjem", size: 25 },
            { key: "returnPackagingPalletFrames", title: "Rammer hjem", size: 25 },
            { key: "returnBlueBoxes", title: "Blå kasser hjem   ", size: 25 },
            { key: "returnPackagingCraneTime", title: "Krantid", size: 15 }
        ];
    }

    private rowClicked(row: DeliveryRouteSummary) {
        this.$router.push({ name: "DeliveryRoute", params: { id: row.deliveryRouteId.toString() } });
    }

    private tableControlModelChanged(tableControlData: TableViewControlModel) {
        this.tableControlModel = tableControlData;
        this.loadingOrderBy = false;
        this.load();
    }

    private async load(skip = 0, firstLoad = false, take = constants.NoOfRowsAtATime) {
        this.loadingMore = true;
        try {
            const sortByAsc = router.currentRoute.query["sortByAsc"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["sortByAsc"] === "true" :
                this.tableControlModel.sort.sortByAscending;

            const sortOrder = router.currentRoute.query["sortOrder"] && firstLoad && this.loadFromUrl ?
                (router.currentRoute.query["sortOrder"] as keyof (DeliveryRouteSummary))! :
                (this.tableControlModel.sort.sortOrder as keyof (DeliveryRouteSummary))!;

            const fromDate = router.currentRoute.query["fromDate"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["fromDate"] as string :
                this.tableControlModel.fromDate;

            const toDate = router.currentRoute.query["toDate"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["toDate"] as string :
                this.tableControlModel.toDate;

            this.loadFromUrl = this.tab === router.currentRoute.query["tab"];

            if (!firstLoad && this.loadFromUrl) {
                router.replace({ query: {
                    ...this.$route.query,
                    sortOrder: sortOrder.toString(),
                    sortByAsc: sortByAsc.toString(),
                    fromDate: fromDate ? fromDate.toString() : "",
                    toDate: toDate ? toDate.toString() : ""} });
                }
            const deliveryRoutes: DeliveryRouteSummary[] = await Api.driver.getDeliveryRoutesPage(this.id,
                this.toDeliveryRouteSummarySortOrder(sortOrder),
                toDomainSortBy(sortByAsc),
                fromDate,
                toDate,
                skip,
                take);

            this.deliveryRouteSummaries = skip > 0 ? [...this.deliveryRouteSummaries, ...deliveryRoutes] : deliveryRoutes;
        } finally {
            this.loadingOrderBy = false;
            this.loadingMore = false;
        }
    }

    private toDeliveryRouteSummarySortOrder(key: keyof (ExtendedDeliveryRouteSummary)): DeliveryRouteSortOrder {
        switch (key) {
            case "createdTimeUtc":
                return DeliveryRouteSortOrder.CreatedTime;
            default:
                throw Error(`Unhandled sort-order: ${key}`);
        }
    }

    private async loadMore(done: () => void) {
        await this.load(this.tableContent.length);
        done();
    }

    private get tableContent(): object[] {
        return this.deliveryRouteSummaries.map((d: DeliveryRouteSummary) => ({
            ...d,
            createdTimeUtc: dateTimeFilter(d.createdTimeUtc)
        }));
    }

    private get toolbarItems() {
        return [ToolbarItem.DatePicker];
    }
}
