import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import HttpStatus from "http-status-codes";
import serverContext from "../server-context";
import store from "../../store";
import router from "../../ui/router";
import snackbar from "../../ui/components/snackbar/snackbarClient";

export default {
    get,
    getAbsolute,
    put,
    post,
    httpDelete
};

const instance = axios.create({
    baseURL: serverContext.apiUrl,
    responseType: "json"
});

const cacheBustHeaders = {
    Pragma: "no-cache",
    "Cache-Control": "no-cache",
    "If-Modified-Since": "0"
};

async function get<T>(relativeUrl: string, params?: any): Promise<T> {
    const config: AxiosRequestConfig = {
        baseURL: serverContext.apiUrl,
        headers: {
            ...cacheBustHeaders
        }
    };
    if (params) {
        config.params = params;
    }
    return handleResult(instance.get<T>(relativeUrl, config));
}

async function getAbsolute<T>(url: string, params?: any): Promise<T> {
    const config: AxiosRequestConfig = {
        headers: {
            ...cacheBustHeaders
        }
    };
    if (params) {
        config.params = params;
    }
    return handleResult(instance.get<T>(url, config));
}

async function put<T>(relativeUrl: string, payload?: any): Promise<T> {
    const config: AxiosRequestConfig = { baseURL: serverContext.apiUrl };
    return handleResult(instance.put<T>(relativeUrl, payload, config));
}

async function post<T>(relativeUrl: string, payload?: any): Promise<T> {
    const config: AxiosRequestConfig = { baseURL: serverContext.apiUrl };
    return handleResult(instance.post<T>(relativeUrl, payload, config));
}

async function httpDelete(relativeUrl: string, params?: any): Promise<void> {
    const config: AxiosRequestConfig = { baseURL: serverContext.apiUrl };

    if (params) {
        config.data = params;
    }
    return handleResult(instance.delete(relativeUrl, config));
}

function handleResult<T>(responsePromise: AxiosPromise<T>): Promise<T> {
    return responsePromise.then(response => {
            if (response.status === HttpStatus.NO_CONTENT) {
                // for T = void situation
                return {} as T;
            } else {
                return response.data;
            }
        },
        (error) => {
            const url = error.config ? error.config.url : "<unknown>";
            const statuscode = error.response ? error.response.status : "<unknown>";
            if (statuscode !== HttpStatus.UNAUTHORIZED) {
                console.error(`Call to ${url} failed with statuscode ${statuscode}`);
                snackbar.error("Netværkskald fejlede. Prøv igen senere.", error.message);
            }
            throw error;
        });
}

setupInterceptors();

function setupInterceptors() {

    instance.interceptors.response.use(response => {
            return response;
        },
        error => {
            // If 401 and not response from failed login-attempt - redirect to login
            if (error.response
                && HttpStatus.UNAUTHORIZED === error.response.status
                && error.config.url.indexOf("login") === -1) {
                store.dispatch("clearLogin");
                router.replace({ name: "Login" });
            }
            throw error;
        }
    );

}
