
















import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class ListItemAtom extends Vue {
    private parsedTitle: object = {
        start: "",
        term: "",
        end: ""
    };
    private parsedCompany: object = {
        start: "",
        term: "",
        end: ""
    };

    @Prop({ default: "" }) private id!: string;
    @Prop({ default: "" }) private name!: string;
    @Prop({ default: "" }) private company!: string;
    @Prop({ default: 0 }) private index!: number;
    @Prop({ default: "" }) private highlight!: string;
    @Prop({ default: "følgeseddel" }) private type!: string;

    @Watch("highlight", {
        immediate: true
    })
    private onTermChanged() {
        this.parsedTitle = this.parseTerm(this.name);
        this.parsedCompany = this.parseTerm(this.company);
    }

    protected parseTerm(text) {
        if (!text) {
            return {
                start: "",
                term: "",
                end: ""
            };
        }

        const indexOf = text.toLocaleLowerCase().indexOf(this.highlight.toLocaleLowerCase());
        if (indexOf > -1) {
            return {
                start: text.slice(0, indexOf),
                term: text.slice(indexOf, indexOf + this.highlight.length),
                end: text.slice(indexOf + this.highlight.length, text.length)
            };
        } else {
            return {
                start: text,
                term: "",
                end: ""
            };
        }
    }
}
