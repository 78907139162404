import { SortBy, StatusColor, StatusViewModel } from "../../../generated/contracts";
import { StatusModel, TableStatusColor } from "../../atoms/table/tableModels";

export function statusToDisplayNameAndColor(status: StatusViewModel): StatusModel {
    return {
        label: status.name,
        color: convertColor(status.color),
        type: "status"
    };

    function convertColor(color: StatusColor): TableStatusColor {
        switch (color) {
            case StatusColor.Blue:
                return TableStatusColor.Blue;
            case StatusColor.Green:
                return TableStatusColor.Green;
            case StatusColor.Yellow:
                return TableStatusColor.Yellow;
            case StatusColor.Red:
                return TableStatusColor.Red;
        }
    }
}

export function toDomainSortBy(sortByAscending: boolean) {
    return sortByAscending ? SortBy.Ascending : SortBy.Descending;
}


