
import { Component, Prop } from "vue-property-decorator";
import StandardLayout from "../../components/layout/standard-layout/StandardLayout.vue";
import { DistributionCenterView, PackageSortOrder, SortBy } from "../../../generated/contracts";
import Api from "../../../core/api/Api";
import constants from "../../../core/constants";
import OrderHistoryView from "../shared/OrderHistoryView.vue";
import ErrorView from "../shared/ErrorView.vue";
import ReturnErrorView from "../shared/ReturnErrorView.vue";

@Component({
    components: { OrderHistoryView, ErrorView }
})
export default class DistributionCenterPage extends StandardLayout<DistributionCenterView> {
    @Prop() private id!: string;

    protected async created() {
        this.pageView = await Api.distributioncenter.getDistributionCenterPage(
            this.id, PackageSortOrder.DeliveryDate, SortBy.Ascending, constants.NoOfRowsAtATime);

        // Remove statistics for now
        (this.pageView as any).statistics = null;

        this.sections = [
            {
                name: "Fejlmeldinger",
                component: ErrorView,
                props: {
                    id: this.id,
                    isDistributionCenter: true,
                    apiMethod: Api.distributioncenter.getDistributionCenterErrors,
                    tab: "0"
                }
            },
            {
                name: "Fejlmeldinger retur",
                component: ReturnErrorView,
                props: {
                    id: this.id,
                    isDistributionCenter: true,
                    apiMethod: Api.distributioncenter.getDistributionCenterReturnErrors,
                    tab: "1"
                }
            }
        ];

        this.contactType = "phone";
    }
}
