import { render, staticRenderFns } from "./DcStatus.vue?vue&type=template&id=6595bae3&scoped=true&"
import script from "./DcStatus.vue?vue&type=script&lang=ts&"
export * from "./DcStatus.vue?vue&type=script&lang=ts&"
import style0 from "./DcStatus.vue?vue&type=style&index=0&id=6595bae3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6595bae3",
  null
  
)

component.options.__file = "DcStatus.vue"
export default component.exports