






















































    import { Component, Prop, Vue } from "vue-property-decorator";
    import TableSettingsAtom from "./_TableSubrow.vue";
    import ToggleSwitchAtom from "@atoms/toggle-switch/ToggleSwitch.vue";
    import Overlay from "@components/overlay/Overlay.vue";
    import { StatusModel, TableHeader, TableRow, TableStatusColor } from "./tableModels";

    @Component({
        components: {
            TableSettingsAtom,
            ToggleSwitchAtom,
            Overlay
        }
    })
    export default class TableRowAtom extends Vue {
        private showSettings = false;
        private showDialog = false;
        private showWarning = false;

        @Prop() private index!: number;
        @Prop() private row!: TableRow;
        @Prop() private headerItems!: TableHeader[];
        @Prop() private highlightCell!: number;
        @Prop() private alignRight!: boolean;
        @Prop() private searchTerm!: string;
        @Prop() private staticRow!: boolean;

        private updateOptions(options) {
            this.$emit("update:options", options);
//            setTimeout(() => {
//                this.showSettings = false;
//            }, 1000);
        }

        private preSearchTerm(value) {
            if (!value) {
                return "";
            }
            value = value.toString();
            const indexOf = value.toLocaleLowerCase().indexOf(this.searchTerm.toLocaleLowerCase());
            return indexOf > -1 ? value.slice(0, indexOf) : value;
        }

        private get getClass() {
            return {
                "--active": this.showSettings,
                "--searching": this.searchTerm,
                "--static": this.staticRow
            };
        }

        private colorClass(value: StatusModel) {
            return {
                "--green": value.color === TableStatusColor.Green,
                "--blue": value.color === TableStatusColor.Blue,
                "--yellow": value.color === TableStatusColor.Yellow,
                "--red": value.color === TableStatusColor.Red
            };
        }

        private typeClass(value: string) {
            return `${value}-type`;
        }

        private theSearchTerm(value) {
            if (!value) {
                return "";
            }
            value = value.toString();
            const indexOf = value.toLocaleLowerCase().indexOf(this.searchTerm.toLocaleLowerCase());
            return indexOf > -1 ? value.slice(indexOf, indexOf + this.searchTerm.length) : "";
        }

        private postSearchTerm(value) {
            if (!value) {
                return "";
            }
            value = value.toString();
            const indexOf = value.toLocaleLowerCase().indexOf(this.searchTerm.toLocaleLowerCase());
            return indexOf > -1 ? value.slice(indexOf + this.searchTerm.length, value.length) : "";
        }

        private closeDialog() {
            this.showWarning = false;
            this.showDialog = false;
            this.showSettings = !this.showSettings;
        }

        private dismissDialog() {
            this.showDialog = false;

        }

        private toggleSettings(hasOptions) {
            if (!hasOptions) {
                this.$emit("click", this.row);
                return;
            }

            if (this.showWarning) {
                this.showDialog = true;
            } else {
                this.showSettings = !this.showSettings;
            }
        }

        private setWarning(value) {
            this.showWarning = !value;
        }

    }
