

























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import Easing from "../../util/easing";

import IconAtom from "../icons/Icons.vue";

@Component({
    components: {
        IconAtom
    }
})
export default class StatChangeAtom extends Vue {
    @Prop() private title!: string;
    @Prop() private changeTitle!: string;
    @Prop({ default: 0 })
    private value!: number;
    @Prop({ default: 0 })
    private changeValue!: number;
    @Prop({ default: false })
    private percentage!: boolean;

    private displayedValue = 0;

    @Watch("value", {
        immediate: true
    })
    private onValueChange(value) {
        if (!value) {
            return;
        }
        // calc the amount we want to increase by for each loop (30 = 750 (total) / 25 (each interval))
        const intervals: number = this.value / 30;

        let countValue: number = 0;

        const counterInterval = setInterval(() => {
            // increase current value by the interval calculated value
            countValue += intervals;

            // set the value given from the easing utility
            this.displayedValue = Easing.easeIn(countValue, this.value);
        }, 25);

        // clear interval and set the total percentage to avoid errors
        setTimeout(() => {
            this.displayedValue = this.value;

            clearInterval(counterInterval);
        }, 750);
    }
}
