
















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { CustomerReturnDeliverySortOrder, CustomerReturnDeliverySummary } from "../../../generated/contracts";
import Api from "../../../core/api/Api";
import { TableHeader } from "../../atoms/table/tableModels";
import TableViewControlModel, { ToolbarItem } from "../../components/table-view/tableViewModels";
import constants from "../../../core/constants";
import { statusToDisplayNameAndColor, toDomainSortBy } from "./tableHelper";
import TableView from "../../components/table-view/TableView.vue";
import { dateTimeFilter } from "../../filters/dateTimeFilters";
import router from "../../router";

interface ExtendedCustomerReturnDeliverySummary extends CustomerReturnDeliverySummary {
    addressLine: string;
    city: string;
    postalCode: string;
}

interface CustomerReturnDeliverySummaryTableHeader extends TableHeader {
    key: keyof (ExtendedCustomerReturnDeliverySummary);
}

@Component({
    components: {
        TableView
    }
})
export default class CustomerReturnDeliveryView extends Vue {
    @Prop() private id!: string;
    @Prop({validator: (value: string) => ["customer", "project"].indexOf(value) > -1}) private context!: string;
    @Prop() private tab!: string;

    private loadFromUrl = this.tab === router.currentRoute.query["tab"];
    private initialSortOrder: keyof (CustomerReturnDeliverySummary) = router.currentRoute.query["sortOrder"] && this.loadFromUrl ?
                (router.currentRoute.query["sortOrder"] as keyof (CustomerReturnDeliverySummary))! :
                "status";
    private initialSortBy = router.currentRoute.query["sortByAsc"] && this.loadFromUrl ?
                router.currentRoute.query["sortByAsc"] === "true" :
                true;
    private initialSearchTerm = router.currentRoute.query["searchTerm"] && this.loadFromUrl ?
                router.currentRoute.query["searchTerm"] :
                null;
    private initialFromDate = router.currentRoute.query["fromDate"] && this.loadFromUrl ?
                router.currentRoute.query["fromDate"] as string :
                null;
    private initialToDate = router.currentRoute.query["toDate"] && this.loadFromUrl ?
                router.currentRoute.query["toDate"] as string :
                null;

    private tableControlModel: TableViewControlModel = {
        fromDate: this.initialFromDate ? this.initialFromDate : null,
        toDate: this.initialToDate ? this.initialToDate : null,
        searchTerm: this.initialSearchTerm,
        sort: {
            sortOrder: this.initialSortOrder,
            sortByAscending: this.initialSortBy
        }
    };
    private loadingOrderBy = false;
    private loadingMore = false;

    private returnDeliverySummaries: CustomerReturnDeliverySummary[] = [];

    private mounted() {
        this.load(0, true);
    }

    private get toolbarItems() {
        return [ToolbarItem.TextSearch, ToolbarItem.DatePicker];
    }

    private get tableHeader(): CustomerReturnDeliverySummaryTableHeader[] {
        return [
            { key: "noteNumber", title: "Returseddel", size: 10 },
            { key: "routeNumber", title: "Rute", size: 10 },
            { key: "addressLine", title: "Afhentningsadresse", size: 20 },
            { key: "postalCode", title: "Postnr", size: 10 },
            { key: "city", title: "By", size: 20 },
            { key: "deliveryTimeUtc", title: "Dato / tid", size: 15 },
            { key: "status", title: "Status", size: 15 }
        ];
    }

    private rowClicked(row: CustomerReturnDeliverySummary) {
        router.push({ name: "CustomerReturnDelivery", params: { id: row.noteNumber } });
    }

    private tableControlModelChanged(tableControlData: TableViewControlModel) {
        this.tableControlModel = tableControlData;
        this.loadingOrderBy = true;
        this.load();
    }

    private async load(skip = 0, firstLoad = false, take = constants.NoOfRowsAtATime) {
        this.loadingMore = true;
        try {
            const sortByAsc = router.currentRoute.query["sortByAsc"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["sortByAsc"] === "true" :
                this.tableControlModel.sort.sortByAscending;

            const sortOrder = router.currentRoute.query["sortOrder"] && firstLoad && this.loadFromUrl ?
                (router.currentRoute.query["sortOrder"] as keyof (CustomerReturnDeliverySummary))! :
                (this.tableControlModel.sort.sortOrder as keyof (CustomerReturnDeliverySummary))!;

            const searchTerm = router.currentRoute.query["searchTerm"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["searchTerm"] :
                this.tableControlModel.searchTerm;

            const fromDate = router.currentRoute.query["fromDate"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["fromDate"] as string :
                this.tableControlModel.fromDate;

            const toDate = router.currentRoute.query["toDate"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["toDate"] as string :
                this.tableControlModel.toDate;

            this.loadFromUrl = this.tab === router.currentRoute.query["tab"];

            if (!firstLoad && this.loadFromUrl) {
                router.replace({ query: {
                    ...this.$route.query,
                    sortOrder: sortOrder.toString(),
                    sortByAsc: sortByAsc.toString(),
                    searchTerm: searchTerm as string,
                    fromDate: fromDate ? fromDate.toString() : "",
                    toDate: toDate ? toDate.toString() : ""} });
                }

            const returnDeliverySummaries: CustomerReturnDeliverySummary[] = await Api[this.context].getCustomerReturnDeliverySummaries(this.id,
                this.toSortOrder(sortOrder),
                toDomainSortBy(sortByAsc),
                searchTerm,
                fromDate,
                toDate,
                skip,
                take);

            this.returnDeliverySummaries = skip > 0 ? [...this.returnDeliverySummaries, ...returnDeliverySummaries] :
                returnDeliverySummaries;
        } finally {
            this.loadingMore = false;
            this.loadingOrderBy = false;
        }
    }

    private toSortOrder(key: keyof (ExtendedCustomerReturnDeliverySummary)): CustomerReturnDeliverySortOrder {
        switch (key) {
            case "noteNumber":
                return CustomerReturnDeliverySortOrder.NoteNumber;
            case "routeNumber":
                return CustomerReturnDeliverySortOrder.Route;
            case "addressLine":
                return CustomerReturnDeliverySortOrder.Address;
            case "city":
                return CustomerReturnDeliverySortOrder.City;
            case "postalCode":
                return CustomerReturnDeliverySortOrder.PostalCode;
            case "deliveryTimeUtc":
                return CustomerReturnDeliverySortOrder.DeliveredTime;
            case "status":
                return CustomerReturnDeliverySortOrder.Status;
            default:
                throw Error(`Unhandled sort-order: ${key}`);
        }
    }

    private async loadMore(done: () => void) {
        await this.load(this.tableContent.length);
        done();
    }

    private get tableContent(): object[] {
        return this.returnDeliverySummaries.map((d: CustomerReturnDeliverySummary) => ({
            ...d,
            deliveryTimeUtc: dateTimeFilter(d.deliveryTimeUtc),
            addressLine: d.address ? d.address.line1 : null,
            city: d.address ? d.address.city : null,
            postalCode: d.address ? d.address.postalCode : null,
            status: statusToDisplayNameAndColor(d.status)
        }));
    }
}
