










import Vue from "vue";
import Component from "vue-class-component";
import IconAtom from "@atoms/icons/Icons.vue";
import { Prop } from "vue-property-decorator";

@Component({
    components: {
        IconAtom
    }
})
export default class InTransit extends Vue {

    @Prop() private text!: string;

    private click() {
        this.$emit("openOnMap");
    }
}
