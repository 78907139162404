















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
    props: {
        title: String,
        progress: Number
    }
})
export default class GraphProgressAtom extends Vue {

    @Prop({ default: 0 }) private progress!: number;
    @Prop() private title!: string;

    private progressDisplay = 0;

    private style = {
        width: "0%"
    };

    protected mounted() {

        // animate progress percentage value
        const counterInterval = setInterval(() => {
            this.progressDisplay += Math.round(this.progress / 15);
        }, 50);

        // set total progress percentage value and clear interval
        setTimeout(() => {
            this.progressDisplay = this.progress;

            clearInterval(counterInterval);
        }, 750);

        // set progress slider value to init css animation
        setTimeout(() => {
            this.style = {
                width: this.progress + "%"
            };
        }, 50);

    }
}
