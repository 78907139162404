




































import { Component, Vue, Watch } from "vue-property-decorator";
import debounce from "lodash-es/debounce";
import constants from "../../../core/constants";

import { LogisticsType, SearchQueryResult, SearchResult } from "../../../generated/contracts";

import Api from "../../../core/api/Api";
import router, { getRoute } from "../../router";
import SearchResultComp from "./SearchResult.vue";
import IconAtom from "@atoms/icons/Icons.vue";
import { Route } from "vue-router";

const MinNoOfCharsToSearch = 3;
const QuantityPerLoad = constants.NoOfRowsAtATime;
let newSearchSeq = 0;

@Component({
    components: {
        SearchResultComp,
        IconAtom
    }
})
export default class SearchPanel extends Vue {
    private term: string = "";
    private lastSearchTerm: string = "";
    private searchResult: SearchQueryResult | null = null;
    private isSearching = false;
    private isActive = false;
    private serverHasMoreResults = false;
    private debounceDelay = constants.DebounceDelay;

    @Watch("$route")
    private onRouteChange(route: Route, { name: oldRouteName }) {
        // close when navigated away from home
        if (route.name !== oldRouteName) {
            this.searchPanelIsActive(false);
        }

        // Clear when user hits home button (still same route, but a specific clearSearch param)
        if (route.query["clearSearch"]) {
            this.clear();
            this.searchPanelIsActive(false);

            // Remove clearSearch from URL
            const { clearSearch, ...query } = route.query;
            this.$router.replace(query);
        }
    }

    private debouncedSearch = debounce(this.search, this.debounceDelay);

    private search(event: any) {
        if (event.key === "Escape") {
            this.searchPanelIsActive(false);
        }
        if (this.term.length >= MinNoOfCharsToSearch) {
            this.doSearch();
        } else {
            this.clearResult();
        }
    }

    private enterPressed() {
        if (this.searchResult && this.searchResult.results.length >= 1) {
            this.searchItemPicked(this.searchResult.results[0]);
        }
    }

    private loadNew(type: LogisticsType | null) {
        this.doSearch(type);
    }

    private async loadMore(type: LogisticsType | null, done: () => void) {
        if (this.serverHasMoreResults) {
            await this.doSearch(type, this.searchResult!.results.length);
            done();
        }
    }

    private async doSearch(type: LogisticsType | null = null, first: Number = 0) {
        const requestSeq = ++newSearchSeq;
        this.isSearching = true;

        try {
            const result = await Api.search.search(this.term, first, QuantityPerLoad, type);
            this.serverHasMoreResults = result.hasMoreResults;
            // only use newest, if crossing request/responses
            if (requestSeq === newSearchSeq) {
                this.lastSearchTerm = this.term;
                if (first === 0) {
                    // overwrite everything
                    this.searchResult = result;
                } else {
                    // append
                    this.searchResult!.results = [...this.searchResult!.results, ...result.results];
                }
            }
        } finally {
            this.isSearching = false;
        }
    }

    private setFocus(value: boolean) {
        if (!value && this.searchResult && this.searchResult.results.length > 0) {
            return;
        }
        this.searchPanelIsActive(value);
    }

    private searchItemPicked(searchItem: SearchResult) {
        router.push({
            name: getRoute(searchItem.type),
            params: { id: searchItem.logisticsId }
        });

        this.searchPanelIsActive(false);
    }

    private searchPanelIsActive(active: boolean) {
        this.isActive = active;
        this.$emit("isActive", active);
        if (!active && this.$refs.input) {
            (this.$refs.input as HTMLInputElement).blur();
        }
    }

    private clear() {
        this.term = "";
        this.clearResult();
        (this.$refs.input as HTMLInputElement).focus();
    }

    private clearResult() {
        this.searchResult = null;
        this.serverHasMoreResults = false;
    }

    @Watch("term")
    private onTermUpdated(value) {
        if (value === "") {
            this.lastSearchTerm = "";
        }
    }
}
