export function dateTimeFilter(value: string | Date | null | undefined) {
    if (!value) {
        return "--";
    }
    const date: Date = typeof value === "string" ? new Date(value) : value;
    return `${pad(date.getDate())}-${pad(date.getMonth() + 1)}-${pad(date.getFullYear())}`
        + ` ${pad(date.getHours())}:${pad(date.getMinutes())}`;
}

export function dateFilter(value: string | Date | null | undefined) {
    if (!value) {
        return "--";
    }
    const date: Date = typeof value === "string" ? new Date(value) : value;
    return `${pad(date.getDate())}-${pad(date.getMonth() + 1)}-${pad(date.getFullYear())}`;
}


export function timeFilter(value: string | Date | null | undefined) {
    if (!value) {
        return "--";
    }
    const date: Date = typeof value === "string" ? new Date(value) : value;

    return `${pad(date.getHours())}:${pad(date.getMinutes())}`;
}

function pad(num: Number): string {
    return num.toString().length === 1 ? "0" + num : num.toString();
}
