

import Vue from "vue";
import { Component } from "vue-property-decorator";
import Permissions, { PermissionToken } from "./permissions";
import store from "../../store";

@Component
export default class PermissionsMixin extends Vue {

    public get $can(): Permissions {
        return {
            administerDistributionCenters: () => hasPermission("DistributionCenterMessages")
        };

        function permissionTokens() {
            return store.state && store.state.login ? store.state.login.permissions : [];
        }

        function hasPermission(permission: PermissionToken) {
            return permissionTokens().indexOf(permission.toString()) > -1;
        }
    }
}
