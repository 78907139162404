
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { SnackbarMessage } from "./bus";

const EndlessScrollLoadMoreKey = "EndlessScrollLoadMore";
const EndlessScrollScrollPosKey = "EndlessScrollScrollPos";
const SnackbarMessageKey = "SnackbarMessage";

@Component
class Bus extends Vue {
    public endlessScrollLoadMore(done: () => void) {
        this.$emit(EndlessScrollLoadMoreKey, done);
    }

    public onEndlessScrollLoadMore(callback: (done: () => void) => void) {
        this.$on(EndlessScrollLoadMoreKey, callback);
    }

    public unregisterEndlessScrollLoadMore(callback: (done: () => void) => void) {
        this.$off(EndlessScrollLoadMoreKey, callback);
    }

    public endlessScrollScrollPos(pos) {
        this.$emit(EndlessScrollScrollPosKey, pos);
    }

    public onEndlessScrollScrollPos(callback: (pos) => void) {
        this.$on(EndlessScrollScrollPosKey, callback);
    }

    public unregisterEndlessScrollScrollPos(callback: (pos) => void) {
        this.$off(EndlessScrollScrollPosKey, callback);
    }

    public onSnackbarMessage(callback: (message: SnackbarMessage) => void) {
        this.$on(SnackbarMessageKey, callback);
    }

    public SnackbarMessage(message: SnackbarMessage) {
        this.$emit(SnackbarMessageKey, message);
    }
}

export default new Bus();
