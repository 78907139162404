


















import Component from "vue-class-component";
import Vue from "vue";
import DcStatusMap from "./DcStatusMap.vue";
import DcStatusAdmin from "./DcStatusAdmin.vue";
import DcInfobox from "./DcInfobox.vue";
import Overlay from "../../components/overlay/Overlay.vue";
import Api from "../../../core/api/Api";
import { DistributionCenter, GlobalStatistics, GlobalStatus } from "../../../generated/contracts";
import Permissions from "../../../core/permissions/Permissions.mixin.vue";

@Component({
    components: {
        DcStatusMap,
        DcStatusAdmin,
        Overlay,
        DcInfobox
    },
    mixins: [Permissions]
})
export default class DcStatus extends Vue {
    private showAdminPanel = false;
    private distributionCenters: DistributionCenter[] | null = null;
    private globalStatus: GlobalStatus | null = null;
    private globalStatistics: GlobalStatistics | null = null;
    private intervalId;

    public doShowAdminPanel(value: boolean) {
        this.showAdminPanel = value;
    }

    protected async created() {
        await this.loadData();

        this.intervalId = setInterval(async () => {
            await this.loadData();
        }, 60000);
    }

    private async loadData() {
        this.distributionCenters = await Api.distributioncenter.getDistributionCenters();
        this.globalStatus = await Api.content.getGlobalStatus();
        this.globalStatistics = await Api.statistics.getGlobalStatistics();
    }

    protected beforeDestroy() {
        clearInterval(this.intervalId);
    }
}
