











import { Component, Vue } from "vue-property-decorator";
import IconAtom from "@atoms/icons/Icons.vue";
import bus from "../../../core/bus/Bus.vue";
import { SnackbarMessage } from "../../../core/bus/bus";
import logging from "../../../core/logging/logging.service";

@Component({
    components: {
        IconAtom
    }
})
export default class Snackbar extends Vue {
    private type = "";
    private message = "";
    private error: string | null = null;
    private show = false;

    private created() {
        bus.onSnackbarMessage((message: SnackbarMessage) => {
            this.message = message.message;
            this.type = message.type ? message.type : "neutral";
            this.error = message.error ? message.error : "";
            this.showIt();

            if (this.type === "error") {
                logging.error(this.message + ":" + this.error);
            }
        });
    }

    private get getClass() {
        return `--${this.type}`;
    }

    private get getIcon() {
        return this.type === "success" ? "check" : "cross";
    }

    private showIt() {
        this.show = true;
        const timeoutHandle = setTimeout(() => {
            this.dismiss();
        }, 5000);
        this.$once("hook:destroyed", () => {
            this.dismiss();
            clearTimeout(timeoutHandle);
        });
    }

    private dismiss() {
        this.show = false;
    }
}
