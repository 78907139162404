



























import { Component, Prop, Vue } from "vue-property-decorator";

import Icon from "@atoms/icons/Icons.vue";

@Component({
    components: {
        Icon
    }
})
export default class ButtonAtom extends Vue {
    @Prop() private title!: string;
    @Prop({ default: "primary" }) private type!: string;
    @Prop() private disabled!: boolean;
    @Prop() private invert!: boolean;

    public $refs!: {
        btnEle: HTMLBaseElement,
        ripEle: HTMLBaseElement
    };

    protected mounted() {
        if (this.$refs.ripEle) {
            const calcSize = Math.max(this.$refs.btnEle.offsetWidth, this.$refs.btnEle.offsetHeight);
            this.$refs.ripEle.style.width = this.$refs.ripEle.style.height = calcSize + "px";
        }
    }

    private ripClass = "ripple--animate";

    protected get btnClass() {
        return {
            "--primary": this.type === "primary",
            "--ghost": this.type === "ghost",
            "--add": this.type === "add",
            "--remove": this.type === "remove",
            "--share": this.type === "share",
            "--icon": this.type === "edit" || this.type === "download",
            "--disabled": this.disabled,
            "--invert": this.invert
        };
    }

    protected click(event) {
        this.$emit("click");
        this.ripple(event);
    }

    protected ripple(event) {
        if (!this.$refs.ripEle) {
            return;
        }

        if (this.$refs.ripEle.classList.contains(this.ripClass)) {
            this.$refs.ripEle.classList.remove(this.ripClass);
        }

        this.$refs.ripEle.style.top = (event.offsetY - this.$refs.ripEle.offsetHeight / 2) + "px";
        this.$refs.ripEle.style.left = (event.offsetX - this.$refs.ripEle.offsetWidth / 2) + "px";

        this.$refs.ripEle.classList.add(this.ripClass);

        const timeoutHandle = setTimeout(() => {
            this.$refs.ripEle.classList.remove(this.ripClass);
        }, 650);
        this.$once("hook:destroyed", () => clearTimeout(timeoutHandle));
    }
}
