






















































































import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import Api from "../../../core/api/Api";
import { DistributionCenter, GlobalStatus } from "../../../generated/contracts";
import HtmlEditor from "@atoms/html-editor/HtmlEditor.vue";
import IconAtom from "@atoms/icons/Icons.vue";
import snackbar from "../../components/snackbar/snackbarClient";
import ToggleSwitchAtom from "../../atoms/toggle-switch/ToggleSwitch.vue";
import orderBy from "lodash-es/orderBy";
import ExcludedPostalCode from "./ExcludedPostalCode.vue";

@Component({
    components: {
        HtmlEditor,
        IconAtom,
        ToggleSwitchAtom,
        ExcludedPostalCode
    }
})
export default class DcStatusAdmin extends Vue {
    private selectedCenter: DistributionCenter | null = null;
    private distributionCentersForEdit: DistributionCenter[] = [];
    private distributionCentersForEditInitialized = false;
    private globalStatusForEdit: GlobalStatus | null = null;
    private globalStatusForEditInitialized = false;
    private maxMessageCharsDc = 350;
    private maxMessageCharsGlobal = 350;
    private distributionCenterCharacterCount = 0;
    private globalCharacterCount = 0;
    private isSaving = false;

    private globalHtmlOptions = {
        placeholder: "Skriv status her...",
        modules: {
            toolbar: [
                ["bold", "italic", "underline"],
                [{ header: 1 }, { header: 2 }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ size: ["small", false, "large", "huge"] }]
            ]
        }
    };

    private distributionCenterHtmlOptions = {
        placeholder: "Skriv status her...",
        modules: {
            toolbar: [
                ["bold", "italic", "underline"]
            ]
        }
    };

    @Prop({
        default: () => [],
        type: Array
    }) private distributionCenters!: DistributionCenter[];

    @Prop({
        default: () => ({ message: "" }),
        type: Object
    }) private globalStatus!: GlobalStatus;

    private distributionCenterCharacterCountFunc(count: number) {
        this.distributionCenterCharacterCount = count;
    }

    private globalCharacterCountFunc(count: number) {
        this.globalCharacterCount = count;
    }

    private get getClasses() {
        return (center: DistributionCenter) => {
            return {
                "--active": center === this.selectedCenter
            };
        };
    }

    @Watch("distributionCenters", {
        immediate: true
    })
    private onDistributionCentersChange(value: DistributionCenter[]) {
        if (value && value.length && !this.distributionCentersForEditInitialized) {
            this.distributionCentersForEditInitialized = true;
            this.distributionCentersForEdit = JSON.parse(JSON.stringify(this.distributionCenters));
        }
    }

    private get isGlobalMessageDirty() {
        if (!this.globalStatus || !this.globalStatusForEdit) {
            return false;
        }
        return this.globalStatus.message !== this.globalStatusForEdit.message;
    }

    private isDistributionCenterDirty(center: DistributionCenter) {
        return center.message !== this.originalCenterMessage(center);
    }

    @Watch("globalStatus", {
        immediate: true
    })
    private onGlobalStatusChange(value: GlobalStatus) {
        if (value && !this.globalStatusForEditInitialized) {
            this.globalStatusForEditInitialized = true;
            this.globalStatusForEdit = JSON.parse(JSON.stringify(this.globalStatus));
        }
    }

    private originalCenterMessage(center: DistributionCenter) {
        return this.distributionCenters!.find(c => c.name === center.name)!.message;
    }

    private cancelDistributionCenterEdit(center: DistributionCenter) {
        const original = this.originalCenterMessage(center);
        center.message = original ? this.cloneString(original) : "";
    }

    private cancelGlobalStatusEdit() {
        this.globalStatusForEdit!.message = this.cloneString(this.globalStatus.message);
    }

    private cloneString(text: string | undefined): string {
        // work around chromes effective mem-management
        return (" " + text).slice(1);
    }

    private selectCenter(center: DistributionCenter) {
        this.selectedCenter = center;
    }

    private async storeCenterMessages(center: DistributionCenter) {
        this.isSaving = true;
        try {
            const newCenter = await Api.distributioncenter.updateDistributionCenterMessage(center);
            const newCenters = this.distributionCenters.map(c => {
                if (c.name === newCenter.name) {
                    return newCenter;
                }
                return c;
            });
            this.$emit("update:distributionCenters", newCenters);
            this.selectedCenter = newCenter;
            this.showSnackbar("message-success", null);
        } catch (error) {
            this.showSnackbar("error", error);
        }
        this.isSaving = false;
    }

    private async storeglobalMessage() {
        this.isSaving = true;
        try {
            const newStatus = await Api.content.updateGlobalStatus(this.globalStatusForEdit!);
            this.$emit("update:globalStatus", newStatus);
            this.showSnackbar("message-success", null);
        } catch (error) {
            this.showSnackbar("error", error);
        }
        this.isSaving = false;
    }

    private async updateDistributionCenter(center: DistributionCenter) {
        this.isSaving = true;
        try {
            const newCenter = await Api.distributioncenter.updateDistributionCenter(center);
            const newCenters = this.distributionCenters.map(c => {
                if (c.name === newCenter.name) {
                    return newCenter;
                }
                return c;
            });
            this.selectedCenter = newCenter;
            this.showSnackbar("settings-success", null);
        } catch (error) {
            this.showSnackbar("error", error);
        }
        this.isSaving = false;
    }

    private showSnackbar(type, error) {
        switch (type) {
            case "message-success":
                snackbar.message("Beskeden er gemt");
                break;
            case "settings-success":
                snackbar.message("Indstillingerne er gemt");
                break;
            case "error":
                snackbar.error("Der skete en fejl, prøv igen.", error.message);
                break;
        }
    }
}
