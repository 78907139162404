










import Vue from "vue";
import { Component, Prop, Provide } from "vue-property-decorator";
import { ScrollEvents } from "./tabsModel";
import bus from "../../../core/bus/Bus.vue";

@Component
export default class TabsAtom extends Vue {
    @Prop({
        type: Number
    }) public id;
    public isActive = false;

    /*
        Let components in this tab subscribe for scroll-events here so they are only forwarded when
        tab is active.
        PREMISE: Only on component needing scrollevents pr. tab is supported
    */

    private created() {
        bus.onEndlessScrollLoadMore(this.onEndlessScrollLoadMore);
        bus.onEndlessScrollScrollPos(this.onEndlessScrollScrollPos);
    }

    private destroyed() {
        bus.unregisterEndlessScrollLoadMore(this.onEndlessScrollLoadMore);
        bus.unregisterEndlessScrollScrollPos(this.onEndlessScrollScrollPos);
    }

    private childScrollLoadMoreListener: ((done: () => void) => void) | undefined;
    private childScrollScrollPosListener: ((pos) => void) | undefined;

    @Provide() private scrollEvents: ScrollEvents = {
        onEndlessScrollLoadMore: this.providedOnEndlessScrollLoadMore,
        onEndlessScrollScrollPos: this.providedOnEndlessScrollScrollPos
    };

    private onEndlessScrollLoadMore(done: () => void) {
        if (this.isActive && this.childScrollLoadMoreListener) {
            this.childScrollLoadMoreListener(done);
        }
    }

    private onEndlessScrollScrollPos(pos) {
        if (this.isActive && this.childScrollScrollPosListener) {
            this.childScrollScrollPosListener(pos);
        }
    }

    public providedOnEndlessScrollLoadMore(callback: (done: () => void) => void) {
        this.childScrollLoadMoreListener = callback;
    }

    public providedOnEndlessScrollScrollPos(callback: (pos) => void) {
        this.childScrollScrollPosListener = callback;
    }

}
