















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import Easing from "../../util/easing";

@Component({
    props: {
        title: String,
        value: Number,
        max: Number
    }
})
export default class GraphRadialAtom extends Vue {

    @Prop({default: 100}) private max!: number;
    @Prop({default: 0}) private value!: number;
    @Prop() private title!: string;

    private displayedValue = 0;
    private dashArray = "0,439.823";
    private defaultMaxVal = 439.823;
    private calculatedDash = 0;
    private counterInterval: any = null;


    protected mounted() {
        // calculate the percentage of the maximum value
        const calculatedPercentage = this.value / this.max;

        // calculate where we should place the dash at the end animation
        this.calculatedDash = this.defaultMaxVal * calculatedPercentage;

        // calculate how much we should increase for each interval
        const strokeIntervals: number = this.calculatedDash / 25;
        const percentIntervals: number = this.value / 25;

        // init the total values used in each interval
        let strokeCountValue: number = 0;
        let percentCountValue: number = 0;

        this.counterInterval = setInterval(() => {

            // add to total count
            strokeCountValue += strokeIntervals;

            // animate the displayed percentage and convert it to a readable percentage
            percentCountValue += percentIntervals;

            // update the percentage value
            this.displayedValue = Easing.easeIn(percentCountValue, this.value);

            // create the stroke array string
            this.dashArray = Easing.easeIn(strokeCountValue, this.calculatedDash) + "," + this.defaultMaxVal;

            if (strokeCountValue >= this.calculatedDash) {
                this.clearAnimation();
            }

        }, 25);
    }

    private clearAnimation() {
        // set percentage value and clear interval
        this.displayedValue = this.value;
        this.dashArray = this.calculatedDash + "," + this.defaultMaxVal;

        clearInterval(this.counterInterval);
    }
}
