






































import { Component, Inject, Prop, Vue } from "vue-property-decorator";

import TableRowAtom from "./_TableRow.vue";
import TableHeaderAtom from "./_TableHeader.vue";
import LoaderAtom from "@atoms/loader/Loader.vue";
import { TableHeader, TableRow } from "./tableModels";
import zipObject from "lodash-es/zipObject";
import { ScrollEvents } from "../tabs/tabsModel";

@Component({
    components: {
        TableHeaderAtom,
        TableRowAtom,
        LoaderAtom
    }
})
export default class TableAtom extends Vue {

    @Prop() private header!: TableHeader[];
    @Prop() private content!: TableRow[];
    @Prop() private highlightCell!: number;
    @Prop() private rightAlignLast!: boolean;
    @Prop() private initialOrderKey!: string;
    @Prop() private initialOrderAscending!: boolean;
    @Prop() private searchTerm!: string;
    @Prop() private loadingOrderBy!: boolean;
    @Prop() private loadingMore!: boolean;
    @Prop() private static!: boolean;

    @Inject() private scrollEvents!: ScrollEvents;

    private newOrderBy = false;
    private stickyHeader = {
        fixed: false,
        originalPos: 0,
        headerHeight: 113
    };

    private updateRowOptions(row, options) {
        this.$emit("update:rowOptions", row.cells, options);
    }

    private get getClass() {
        return {
            "--fixed": this.stickyHeader.fixed,
            "--loading": this.loadingOrderBy
        };
    }

    public $refs!: {
        leftCurtain: HTMLDivElement;
        rightCurtain: HTMLDivElement;
        tableHeader: TableHeaderAtom;
        table: HTMLDivElement;
    };

    private orderedRow(row: any): object {
        const returnRow = {
            cells: {},
            options: row.options,
            rowModel: row.rowModel
        };
        returnRow.cells = this.header.map((headerCol) => {
            return row.cells[headerCol.key];
        });
        return returnRow;
    }

    private created() {
        this.scrollEvents.onEndlessScrollLoadMore(this.loadMore);
        this.scrollEvents.onEndlessScrollScrollPos(this.setStickyHeader);
    }

    private mounted() {
        this.$refs.table.addEventListener("scroll", this.mobileTable);
        document.addEventListener("resize", this.setOriginalHeaderTop);
        this.setOriginalHeaderTop();
    }

    private beforeDestroy() {
        this.$refs.table.removeEventListener("scroll", this.mobileTable);
        document.removeEventListener("resize", this.setOriginalHeaderTop);
    }

    private mobileTable(Event) {
        if (window.innerWidth <= 1280) {
            const tableWidth = this.$refs.table.offsetWidth;
            const ele = Event.target as HTMLDivElement;
            const xPos = ele.scrollLeft;

            this.$refs.leftCurtain.style.opacity = xPos > 0 ? "1" : "0";
            this.$refs.rightCurtain.style.opacity = xPos > (ele.scrollWidth - tableWidth - 15) ? "0" : "1";
        }
    }

    private setOriginalHeaderTop() {
        this.stickyHeader.headerHeight = this.$refs.tableHeader.$el.offsetHeight + 20;

        this.stickyHeader.originalPos = this.$refs.table.getBoundingClientRect().top;

        if (window.innerWidth <= 1280) {
            this.stickyHeader.fixed = false;
        }
    }

    private setStickyHeader(scrollPos) {
        if (this.stickyHeader.originalPos === 0) {
            this.setOriginalHeaderTop();
            return;
        }

        const setFixed = (window.innerWidth > 1280) && (scrollPos > (this.stickyHeader.originalPos - this.stickyHeader.headerHeight));

        this.stickyHeader.fixed = setFixed;
    }

    private rowClicked(row) {
        this.$emit("rowClicked", row.rowModel);
    }

    private orderChanged(order) {
        this.$emit("orderChanged", order);
    }

    private loadMore(done: () => void) {
        this.$emit("loadMore", done);
    }
}
