
import { Component, Prop } from "vue-property-decorator";
import StandardLayout from "../../components/layout/standard-layout/StandardLayout.vue";
import { DeliveryView, PackageSortOrder, PackageSummary, SortBy } from "../../../generated/contracts";
import Api from "../../../core/api/Api";
import constants from "../../../core/constants";
import DeliveryInfo from "../shared/delivery-info/DeliveryInfo.vue";
import DeliveryViewComp from "./DeliveryView.vue";
import DeliveryNotificationsView from "./DeliveryNotificationsView.vue";

@Component
export default class DeliveryPage extends StandardLayout<DeliveryView> {
    private packageSummaries: PackageSummary[] = [];

    @Prop() private id!: string;

    protected async created() {
        this.pageView = await Api.delivery.getDeliveryPage(this.id, PackageSortOrder.Status, SortBy.Ascending,
            constants.NoOfRowsAtATime);
        this.packageSummaries = this.pageView.content.packages;
        this.sections = [
            {
                name: "Følgeseddellinjer",
                component: DeliveryViewComp,
                props: {
                    id: this.id,
                    tab: "0"
                }
            },
            {
                name: "Leveringsinfo",
                component: DeliveryInfo,
                props: {
                    deliveryInfo: this.pageView.content.deliveryInfo,
                    tab: "1"
                },
                events: {
                    shareInfo: this.deliveryInfoShareInfo
                }
            },
            {
                name: "Leveringsnotifikationer",
                component: DeliveryNotificationsView,
                props: {
                    deliveryInfo: this.pageView.content.deliveryInfo,
                    tab: "2"
                }
            }
        ];

        if (this.pageView.content.isInTransit || this.$route.query["showMap"]) {
            this.inTransit = {
                routeId: this.pageView.content.currentDeliveryRouteId!,
                displayText: `Levering på vej`,
                orderNumber: this.pageView.content.order.orderNumber,
                simulated: !this.pageView.content.isInTransit
            };
        }
    }

    private deliveryInfoShareInfo(target: "customer" | "colleague", name: string, email: string, message: string, images: string[]) {
        Api.delivery.share(target, this.id, name, email, message, images);
    }

}
