















import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { DistributionCenter } from "../../../generated/contracts";
import { timeFilter } from "../../filters/dateTimeFilters";

@Component
export default class DcStatusMap extends Vue {
    private map!: google.maps.Map;
    private overlay!: google.maps.OverlayView;
    private markers: Map<string, google.maps.Marker> = new Map<string, google.maps.Marker>();
    private infoWindowRelatedMarker: google.maps.Marker | null = null;
    private iwContent: string = "";
    private iwPosition: object = {
        top: 0,
        left: 0
    };
    private iwReverted: boolean = false;
    private bounds: google.maps.LatLngLiteral[] = [];

    public $refs!: {
        infowindow: HTMLElement
    };

    @Prop() private distributionCenters!: DistributionCenter[];

    @Watch("distributionCenters", {
        immediate: true,
        deep: true
    })
    protected onDistributionCentersChanged(value) {

        if (!value) {
            return;
        }
        if (!this.map) {
            this.createMap();
        }
        this.updateCenters();
        this.setBounds();

    }

    protected mounted() {
        window.addEventListener("resize", this.setBounds);
    }

    protected beforeDestroy() {
        window.removeEventListener("resize", this.setBounds);
    }

    private createMap() {
        const element = this.$refs["map"];

        const mapStyles = require("../../util/mapStyle.json");

        const styledMapType = new google.maps.StyledMapType(mapStyles, {name: "styles map"});

        const options: google.maps.MapOptions = {
            draggable: false,
            disableDoubleClickZoom: true,
            fullscreenControl: false,
            keyboardShortcuts: false,
            mapTypeControl: false,
            rotateControl: false,
            scaleControl: false,
            scrollwheel: false,
            streetViewControl: false,
            zoomControl: false
        };

        this.map = new google.maps.Map(element as HTMLBaseElement, options);

        this.map.mapTypes.set("styled_map", styledMapType);
        this.map.setMapTypeId("styled_map");

        this.overlay = new google.maps.OverlayView();
        this.overlay.setMap(this.map);
    }

    private setBounds() {
        const bounds = new google.maps.LatLngBounds();

        this.bounds.forEach((bound) => {
            bounds.extend(bound);
        });

        bounds.extend({
            lat: 55.963961,
            lng: 6.497829
        });

        bounds.extend({
            lat: 55.073336,
            lng: 15.421418
        });

        // Issues with fitting bounds
        try {
            this.map.fitBounds(bounds);
        } catch (e) {
            // error
        }
    }

    private updateCenters() {
        // add or update
        this.distributionCenters.forEach(center => {

            const marker = this.markers[center.name];
            if (!marker) {
                this.createMarker(center);
            } else {
                this.updateMarker(center, marker);
            }
        });
    }

    private createMarker(center: DistributionCenter) {
        const latLng = this.toGoogleLatLng(center);

        const marker = new google.maps.Marker({
            position: latLng,
            icon: this.getMarkerIcon(center),
            title: this.getMarkerTitle(center),
            map: this.map
        });

        marker.addListener("mouseover", () => this.updateInfoWindowContent(center.name, marker));
        marker.addListener("mouseout", () => this.closeInfoWindow());

        this.bounds.push(latLng);
        this.markers[center.name] = marker;

    }

    private updateInfoWindowContent(centerName: string, marker: google.maps.Marker) {
        const hoverMarkup = this.hoverMarkup(this.distributionCenters.find(c => c.name === centerName)!);
        if (!hoverMarkup) {
            return;
        }

        this.infoWindowRelatedMarker = marker;
        this.iwContent = hoverMarkup;

        // get marker x y pos
        const overlayProjection = this.overlay.getProjection();
        const markerPos = marker as any;
        const pos = markerPos.position;
        const pixelPos = overlayProjection.fromLatLngToContainerPixel(pos);

        this.$nextTick(() => {
                const iwEle = this.$refs.infowindow;

                let topPos = (pixelPos.y - iwEle.offsetHeight - 20);

                if (topPos < 0) {
                    this.iwReverted = true;
                    topPos = topPos + iwEle.offsetHeight + 50;
                } else {
                    this.iwReverted = false;
                }

                this.iwPosition = {
                    top: topPos + "px",
                    left: pixelPos.x + "px"
                };
            }
        );

    }

    private closeInfoWindow() {
        this.iwContent = "";
        this.infoWindowRelatedMarker = null;
    }

    private updateMarker(center: DistributionCenter, marker: google.maps.Marker) {
        marker.setIcon(this.getMarkerIcon(center));
        marker.setTitle(this.getMarkerTitle(center));

        if (this.infoWindowRelatedMarker === marker) {
            this.updateInfoWindowContent(center.name, marker);
        }
    }

    private getMarkerIcon(center: DistributionCenter) {
        return {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: this.hoverMarkup(center) ? "#63ADB3" : "#AEAEAE",
            optimized: false,
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 15
        };
    }

    private getMarkerTitle(center: DistributionCenter) {
        return this.hoverMarkup(center) ? "" : "Ingen notifikation"; // remember to update css selector if title is changed
    }

    private toGoogleLatLng(center: DistributionCenter): google.maps.LatLngLiteral {
        return {
            lat: center.latitude,
            lng: center.longitude
        };
    }

    private hoverMarkup(center: DistributionCenter) {
        if (!center.message) {
            return "";
        }

        const formattedDate = timeFilter(center.messageLastUpdated);
        let message = center.messageLastUpdated ? `<div class="timestamp">Kl. ${formattedDate}</div>` : "";
        message += center.message ? `<div class="message">${center.message}</div>` : "";

        return `
            <h3>${center.city}</h3>
            ${message}
        `;
    }
}
