





































import Vue from "vue";
import { Component } from "vue-property-decorator";
import IconAtom from "@atoms/icons/Icons.vue";
import LoaderAtom from "@atoms/loader/Loader.vue";
import Overlay from "../../components/overlay/Overlay.vue";
import Api from "../../../core/api/Api";
import { Action } from "vuex-class";
import { LoginView } from "../../../generated/contracts";
import router from "../../router";

@Component({
    components: {
        IconAtom,
        Overlay,
        LoaderAtom
    }
})
export default class LoginPage extends Vue {
    private username: string = "";
    private password: string = "";
    private errorMsg: string = "";

    public isLoading: boolean = false;

    @Action private loggedIn!: (login: LoginView) => void;
    @Action private clearLogin!: () => void;

    private async login() {
        try {
            this.errorMsg = "";
            this.isLoading = true;
            const login = await Api.login.login(this.username, this.password);
            this.loggedIn(login);
            router.replace({ name: "Home" });
        } catch (err) {
            this.isLoading = false;
            this.errorMsg = "Login fejlede. Kontroller brugernavn og kodeord og prøv igen.";
            this.clearLogin();
        }
    }
}
