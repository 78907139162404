







import { Vue, Component, Prop } from "vue-property-decorator";

import IconAtom from "@atoms/icons/Icons.vue";
import LoaderAtom from "@atoms/loader/Loader.vue";

@Component({
    components: {
        IconAtom,
        LoaderAtom
    }
})
export default class IsSearchingAnimation extends Vue {
    @Prop({ default: "Søger..." }) private text!: string;
}
