const iconUrl = `${window.location.origin}/icon_pin.png`;

export default function mapImage(latitude: number, longitude: number, width: number,
                                 height: number, zoom: number, key: string) {
    const params = `
size=${width}x${height}&zoom=${zoom}&scale=2
&markers=icon:${iconUrl}|${latitude},${longitude}
&style=element:geometry|color:0xf8f8f8
&style=element:labels.icon|visibility:off
&style=element:labels.text.fill|color:0xB0B0B0
&style=element:labels.text.stroke|color:0xf5f5f5
&style=feature:administrative|visibility:off
&style=feature:administrative.land_parcel|element:geometry.fill|visibility:off
&style=feature:administrative.land_parcel|element:labels|visibility:off
&style=feature:administrative.land_parcel|element:labels.text.fill|visibility:off|color:0xbdbdbd
&style=feature:administrative.province|element:geometry.fill|weight:3.5
&style=feature:poi|element:geometry|color:0xeeeeee
&style=feature:poi|element:labels.text.fill|visibility:off|color:0x757575
&style=feature:poi|element:labels.text.stroke|visibility:off
&style=feature:poi.park|element:geometry|color:0xe5e5e5
&style=feature:poi.park|element:labels.text.fill|color:0x9e9e9e
&style=feature:road|element:geometry|color:0xffffff
&style=feature:road.arterial|element:labels.text.fill|color:0x757575
&style=feature:road.highway|element:geometry|color:0xdadada
&style=feature:road.highway|element:geometry.fill|color:0xffffff|visibility:on
&style=feature:road.highway|element:geometry.stroke|color:0xeeeeee|visibility:off
&style=feature:road.highway|element:labels.text.fill|color:0x616161
&style=feature:road.local|element:labels.text.fill|color:0x9e9e9e
&style=feature:transit.line|element:geometry|color:0xe5e5e5
&style=feature:transit.line|element:geometry.fill|visibility:off
&style=feature:transit.line|element:geometry.stroke|visibility:off
&style=feature:transit.station|element:geometry|color:0xeeeeee
&style=feature:water|element:geometry|color:0xc9c9c9
&style=feature:water|element:geometry.fill|color:0xeaf1f5
&style=feature:water|element:geometry.text.fill|color:0x9e9e9e
`;
    return `https://maps.googleapis.com/maps/api/staticmap?${params}&key=${key}`;
}
