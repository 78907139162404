


















































import { Vue, Component, Watch, Prop } from "vue-property-decorator";

import { CardEditModel, CardErrorModel } from "../cardModels";

import InputTextfieldAtom from "@atoms/input-textfield/InputTextfield.vue";
import DropdownAtom from "@atoms/dropdown/Dropdown.vue";

@Component({
    components: {
        InputTextfieldAtom,
        DropdownAtom
    }
})
export default class CardLayoutEditAtom extends Vue {
    @Prop() private content!: CardEditModel[];

    private errMsg: CardErrorModel[] = [];
    private areaPrefix = {
        options: [
            {
                label: "+45"
            }, {
                label: "+44"
            }, {
                label: "+43"
            }, {
                label: "+42"
            }
        ]
    };

    protected handleErrMsg(ev, errKey) {

        // add if error exists
        if (ev.value) {
            let updatedMsg = false;

            // update error message if already exists
            this.errMsg.forEach((item, ix) => {
                if (item.key === errKey) {
                    this.errMsg[ix].msg = ev.msg;
                    updatedMsg = true;
                }
            });

            // error doesnt exists in array so we need to add it
            if (!updatedMsg) {
                this.errMsg.push({
                    key: errKey,
                    msg: ev.msg
                });
            }
        } else {
            // remove error message
            this.errMsg.forEach((item, ix) => {
                if (item.key === errKey) {
                    this.errMsg.splice(ix, 1);
                }
            });
        }

        this.$emit("hasErrors", this.errMsg.length);

    }
}
