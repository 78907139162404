
import { Component, Prop } from "vue-property-decorator";
import StandardLayout from "../../components/layout/standard-layout/StandardLayout.vue";
import { OrderSortOrder, OrderSummary, SalesAccountOrderView, SalesAccountView, SortBy } from "../../../generated/contracts";
import Api from "../../../core/api/Api";
import OrderHistoryView from "../shared/OrderHistoryView.vue";
import NotificationsView from "../shared/NotificationsView.vue";
import CustomerReturnDeliveryView from "../shared/CustomerReturnDeliveryView.vue";
import constants from "../../../core/constants";

@Component({
    components: { OrderHistoryView, NotificationsView, CustomerReturnDeliveryView }
})
export default class CustomerPage extends StandardLayout<SalesAccountView> {
    private orderSummaries: OrderSummary[] = [];

    @Prop() private id!: string;

    protected async created() {
        this.pageView = await Api.customer.getCustomerPage(this.id, OrderSortOrder.Status, SortBy.Ascending,
            constants.NoOfRowsAtATime);
        this.orderSummaries = (this.pageView.content as SalesAccountOrderView).orders;
        this.sections = [
            {
                name: "Ordrehistorik",
                component: OrderHistoryView,
                props: {
                    id: this.id,
                    tab: "0"
                }
            },
            {
                name: "Retur",
                component: CustomerReturnDeliveryView,
                props: {
                    context: "customer",
                    id: this.id,
                    tab: "1"
                }
            },
            {
                name: "Notifikationer",
                component: NotificationsView,
                props: {
                    context: "customer",
                    accountNumber: this.id
                },
                events: {}
            }
        ];
    }
}
