












import { Vue, Component, Prop } from "vue-property-decorator";
import CardTableview from "../card-layouts/CardLayoutTableview.vue";
import CardTimeline from "../card-layouts/CardLayoutTimeline.vue";

import { CardItemContent } from "../cardModels";

@Component({
    components: {
        CardTableview,
        CardTimeline
    }
})
export default class CardItemAtom extends Vue {

    @Prop() private type!: string;
    @Prop() private headline!: string;
    @Prop() private image!: string;
    @Prop() private content!: CardItemContent[];
}
