




























import { Component, Prop, Vue } from "vue-property-decorator";
import Overlay from "@components/overlay/Overlay.vue";
import DropdownAtom from "@atoms/dropdown/Dropdown.vue";
import InputTextfieldAtom from "@atoms/input-textfield/InputTextfield.vue";
import CardItemAtom from "@atoms/cards/card-item/CardItem.vue";
import CardImagesAtom from "@atoms/cards/card-images/CardImages.vue";
import ShareInfo from "./_ShareInfo.vue";
import StaticMap from "@components/static-map/StaticMap.vue";
import { CardImages, CardItemContent } from "../../../atoms/cards/cardModels";
import { DeliveryInfo, GpsLocation } from "../../../../generated/contracts";
import { dateTimeFilter as dateTime } from "../../../filters/dateTimeFilters";
import serverContext from "../../../../core/server-context/index";
import mapImage from "../../../../core/map-utils/mapImage";

@Component({
    components: {
        Overlay,
        InputTextfieldAtom,
        DropdownAtom,
        CardItemAtom,
        CardImagesAtom,
        ShareInfo,
        StaticMap
    }
})
export default class DeliveryInfoComp extends Vue {
    private showMap: boolean = false;

    @Prop() private deliveryInfo!: DeliveryInfo;

    private history(): CardItemContent[] {
        return this.deliveryInfo.history.map((historyElem) => {
            return {
                description: historyElem.description,
                label: dateTime(historyElem.timestamp),
            };
        });
    }

    private get infoSummary(): CardItemContent[] {
        return [
            {
                label: "Filial",
                description: this.deliveryInfo.department
            },
            {
                label: "Vor ref",
                description: this.deliveryInfo.ourRef
            },
            {
                label: "Rute",
                description: this.deliveryInfo.route
            },
            {
                label: "Leveret",
                description: dateTime(this.deliveryInfo.deliveredTimeUtc)
            },
            {
                label: "Leveret af",
                description: this.deliveryInfo.deliveredBy
            },
            {
                label: "Adresse",
                description: this.combinedAddress
            },
            {
                label: "Leverings beskrivelse",
                description: this.deliveryInfo.deliveryDescriptionLinkDisplayName,
                url: this.deliveryInfo.deliveryDescriptionLink
            },
            {
                label: "Kontaktperson",
                description: this.deliveryInfo.contactPerson
            }
        ];
    }

    private get combinedAddress() {
        return this.createMultiline(this.deliveryInfo.deliveredToName,
            this.deliveryInfo.deliveredToStreetAndNumber,
            this.deliveryInfo.deliveredToPostalCodeAndCity);
    }

    private get images(): CardImages {
        return {
            headline: "Leveringsbillede",
            content: this.deliveryInfo.photos.map((photo) => (
            {
                label: dateTime(photo.exif.takenUtc),
                url: serverContext.apiUrl + photo.url,
                deliveryInfo: [
                    {
                        label: "EXIF data",
                        description: photo.exif.address,
                        name: this.createMultiline(formatGpsLocation(photo.exif.location), dateTime(photo.exif.takenUtc))
                    },
                    {
                        label: "Chauffør note",
                        description: photo.note
                    }
                ]
            }))
        };

        function formatGpsLocation(location: GpsLocation) {
            return `${location.latitude}, ${location.longitude}`;
        }
    }

    private get contentImages() {
        return this.deliveryInfo.photos.map((photo) => (
            {
                label: dateTime(photo.exif.takenUtc),
                url: photo.url
            }));
    }

    private createMultiline(...strings: string[]) {
        return strings.join("\r\n");
    }

    private get getLatLng() {
        return {
            lat: this.deliveryInfo.deliveryGpsPos.latitude,
            lng: this.deliveryInfo.deliveryGpsPos.longitude
        };
    }

    private shareAction(to: "customer" | "colleague", name: string, email, message, images) {
        this.$emit("shareInfo", to, name, email, message, images);
    }

    private get dropMapImg() {
        return mapImage(this.deliveryInfo.deliveryGpsPos.latitude,
            this.deliveryInfo.deliveryGpsPos.longitude,
            600, 300,
            17,
            serverContext.googleMapsApiKey
        );
    }

    private toggleMap() {
        this.showMap = !this.showMap;
    }

}
