













import { Vue, Component, Watch, Prop } from "vue-property-decorator";

import CardLayoutEditAtom from "@atoms/cards/card-layouts/CardLayoutEdit.vue";
import CardLayoutSwitchAtom from "@atoms/cards/card-layouts/CardLayoutSwitch.vue";

import { TableEditModel } from "./tableModels";
import cloneDeep from "lodash-es/cloneDeep";

@Component({
    components: {
        CardLayoutEditAtom,
        CardLayoutSwitchAtom
    }
})
export default class TableSubrowAtom extends Vue {
    @Prop() private options!: TableEditModel[];

    private disableSave = true;
    private errors = 0;
    private localOptions = {};

    private setErrors(errorCount) {
        this.errors = errorCount;
        this.disableSave = errorCount > 0;
    }

    private saveSettings() {
        this.disableSave = true;
        this.$emit("promptClose", true);
        this.$emit("update:options", this.localOptions);
    }

    @Watch("options", { immediate: true })
    private onOptionsChanged(value) {
        this.localOptions = cloneDeep(value);
    }

    @Watch("localOptions", { deep: true })
    private onLocalOptionsChanged(value) {
        if (!this.errors) {
            this.disableSave = false;
        }
        this.$emit("promptClose", JSON.stringify(this.localOptions) === JSON.stringify(this.options));
    }

}
