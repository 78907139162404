






























import Vue from "vue";
import Component from "vue-class-component";
import ToolbarSearchAtom from "@atoms/toolbar-search/ToolbarSearch.vue";
import ToolbarCalendarAtom from "@atoms/toolbar-calendar/ToolbarCalendar.vue";
import ToolbarExportAtom from "@atoms/toolbar-export/ToolbarExport.vue";
import TableAtom from "@atoms/table/Table.vue";
import { Prop } from "vue-property-decorator";
import { TableHeader, TableRow } from "../../atoms/table/tableModels";
import TableViewControlModel, { SortDefinition, ToolbarItem } from "./tableViewModels";
import constants from "../../../core/constants";
import { ExcelService } from "../../../core/excel";

@Component({
    components: {
        ToolbarSearchAtom,
        ToolbarCalendarAtom,
        ToolbarExportAtom,
        TableAtom
    }
})
export default class TableView extends Vue {
    private controlModel: TableViewControlModel = {
        fromDate: null,
        toDate: null,
        searchTerm: null,
        sort: {
            sortOrder: null,
            sortByAscending: true
        }
    };
    private debounceDelay = constants.DebounceDelay;

    @Prop({ default: () => [] }) private tableHeader!: TableHeader[];
    @Prop({ default: () => [] }) private tableContent!: object[];
    @Prop() private tableOrdering!: SortDefinition;
    @Prop({ default: false }) private loadingOrderBy!: boolean;
    @Prop({ default: false }) private loadingMore!: boolean;
    @Prop({ default: false }) private staticItems!: boolean;
    @Prop({ default: null }) private initialSearchTerm!: string;
    @Prop({ default: null }) private initialFromDate!: string;
    @Prop({ default: null }) private initialToDate!: string;
    @Prop({ default: () => [] })
    private toolbarItems!: ToolbarItem[];

    protected created() {
        this.controlModel.sort = this.tableOrdering;
    }

    private get toolbarDatePickerEnabled() {
        return this.toolbarItems.indexOf(ToolbarItem.DatePicker) > -1;
    }

    private get toolbarTextSearchEnabled() {
        return this.toolbarItems.indexOf(ToolbarItem.TextSearch) > -1;
    }

    private get toolbarExportEnabled() {
        return this.toolbarItems.indexOf(ToolbarItem.Export) > -1;
    }

    private get content(): TableRow[] {
        return this.tableContent.map(tc => {
            return ({
                cells: tc,
                rowModel: tc
            });
        });
    }

    private rowClicked(row) {
        this.$emit("rowClicked", row);
    }

    private exportToExcel() {
        const excelService = new ExcelService(this.tableContent, this.tableHeader);
        excelService.exportTableToXlsx();
    }

    private searchTermChanged(term) {
        this.controlModel.searchTerm = term.trim();
        this.$emit("controlModelChanged", this.controlModel);
    }

    private datesChanged(dateOne, dateTwo) {
        this.controlModel.fromDate = dateOne;
        this.controlModel.toDate = dateTwo;
        this.$emit("controlModelChanged", this.controlModel);
    }

    private orderChanged(order: SortDefinition) {
        this.controlModel.sort = order;
        this.$emit("controlModelChanged", this.controlModel);
    }

    private loadMore(done: () => void) {
        this.$emit("loadMore", done);
    }
}
