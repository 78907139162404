






import { Vue, Component, Prop } from "vue-property-decorator";

import IconAtom from "@atoms/icons/Icons.vue";

@Component({
    components: {
        IconAtom
    }
})
export default class ToolbarExportAtom extends Vue {
    @Prop() private title!: string;
}
