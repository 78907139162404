




































































































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
    props: {
        type: String,
        width: String,
        height: String
    }
})
export default class IconAtom extends Vue {

    @Prop() private type!: string;
    @Prop({default: "100"}) private width!: string;
    @Prop({default: "100"}) private height!: string;

    public $refs!: {
        icon: HTMLBaseElement
    };

    private mounted() {
        if (this.$refs.icon.innerHTML.indexOf("path") === -1) {
            this.$refs.icon.style.display = "none";
            this.$emit("notFound");
        }
    }

}
