















import { Vue, Component, Prop } from "vue-property-decorator";

import { CardItemContent } from "../cardModels";

@Component({
    props: {
        items: Object
    }
})
export default class CardLayoutTableviewAtom extends Vue {

    @Prop() private items!: CardItemContent[];

}
