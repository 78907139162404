import { render, staticRenderFns } from "./DeliveryInfo.vue?vue&type=template&id=f4e67ece&scoped=true&"
import script from "./DeliveryInfo.vue?vue&type=script&lang=ts&"
export * from "./DeliveryInfo.vue?vue&type=script&lang=ts&"
import style0 from "./DeliveryInfo.vue?vue&type=style&index=0&id=f4e67ece&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4e67ece",
  null
  
)

component.options.__file = "DeliveryInfo.vue"
export default component.exports