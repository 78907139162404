












import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ToggleSwitchAtom extends Vue {
    @Prop() private title!: string;
    @Prop() private label!: string;
    @Prop() private on!: boolean;
}

