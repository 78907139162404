






















import { Vue, Component, Prop } from "vue-property-decorator";
import CardTableview from "../card-layouts/CardLayoutTableview.vue";
import DropdownAtom from "@atoms/dropdown/Dropdown.vue";
import Overlay from "@components/overlay/Overlay.vue";
import LoaderAtom from "@atoms/loader/Loader.vue";

import { CardImages } from "../cardModels";

@Component({
    components: {
        CardTableview,
        DropdownAtom,
        Overlay,
        LoaderAtom
    }
})
export default class CardImagesAtom extends Vue {

    @Prop() private cardContents!: CardImages;
    private showImage = false;
    private imageLoading = true;
    private currentIndex = 0;
    private dropdownContents = {};

    protected mounted() {
        const contentOptions = this.cardContents.content.map((item) => {
            return {
                label: item.label
            };
        });

        this.dropdownContents = {
            icon: "layers",
            placeholder: this.cardContents.content.length + " Leveringsbilleder",
            options: contentOptions
        };
    }

    protected selectOption(options) {
        if (this.currentIndex !== options.index) {
            this.currentIndex = options.index;
            this.imageLoading = true;
        }
    }

    protected toggleImage(show) {
        this.showImage = show || !this.showImage;
    }

    protected imageLoaded() {
        this.imageLoading = false;
    }
}
