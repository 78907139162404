

























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import DropModel from "./dropListModel";
import IconAtom from "@atoms/icons/Icons.vue";
import groupBy from "lodash-es/groupBy";
import map from "lodash-es/map";

@Component({
    components: {
        IconAtom
    }
})
export default class DropListAtom extends Vue {
    @Prop() private drops!: DropModel[];
    @Prop() private selectedDrop!: number;
    private shouldScroll: boolean = true;

    public $refs!: {
        drops: HTMLBaseElement,
        dropItem: HTMLBaseElement
    };

    private getClass(drop: DropModel, ix: number) {
        return {
            "--is-selected": this.selectedDrop === ix,
            "--is-target": drop.isTarget,
            "--is-next": drop.isNext,
            "--delivered": drop.isDelivered,
            "--inactive": !drop.isTarget && !drop.isDelivered && !drop.isNext
        };
    }

    private get realDrops() {
        // Don't show distribution-center
        return this.drops.length > 0 ? this.drops.slice(1) : [];
    }

    private address(drop: DropModel): string {
        return `${drop.address.line1}, ${drop.address.postalCode} ${drop.address.city}`;
    }

    private preventScroll(ix) {
        this.$emit("updateDrop", ix);

        this.shouldScroll = false;

        setTimeout(() => {
            this.shouldScroll = true;
        }, 50);
    }

    @Watch("selectedDrop")
    private onSelectedDropChange(value) {
        if (value == null) {
            return;
        }
        this.$nextTick(() => {
            if (this.shouldScroll && this.$refs.drops !== undefined && this.$refs.dropItem !== undefined) {
                this.scrollToEle(this.$refs.drops, this.$refs.dropItem[value], 200);
            }
        });
    }

    private mounted() {
        // Everything in place scroll in place.
        this.onSelectedDropChange(this.selectedDrop);
    }

    private scrollToEle(scrollElement, scrollToElement, duration) {
        const startingY = scrollElement.scrollTop;
        const elementY = scrollToElement.offsetTop - scrollToElement.offsetHeight - 28;
        const diff = elementY - startingY;
        let start;

        window.requestAnimationFrame(function step(timestamp) {
            if (!start) {
                start = timestamp;
            }
            const time = timestamp - start;
            const percent = Math.min(time / duration, 1);

            scrollElement.scrollTop = startingY + diff * percent;

            if (time < duration) {
                window.requestAnimationFrame(step);
            }
        });
    }

    private deliveryMethods(drop: DropModel) {
        return map(groupBy(drop.deliveryMethodNames), (value: string[], method: string) => {
            return method + (value.length > 1 ? ` (${value.length})` : "");
        }).join(", ");
    }

    private orderNumbers(drop: DropModel) {
        return drop.orderNumbers.join(", ");
    }

    private returnNumbers(drop: DropModel) {
        return drop.returnNumbers.join(", ");
    }
}
