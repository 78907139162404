







import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class SkeletonLoaderAtom extends Vue {
    @Prop() private content!: string;
    @Prop({
        default: "grey",
        validator: value => ["grey", "blue"].indexOf(value) > -1
    }) private color!: string;
    @Prop({
        default: "18px"
    }) private height!: string;
    @Prop({
        default: "100px"
    }) private width!: string;

    private get getStyle() {
        if (this.content) {
            return {};
        } else {
            return {
                width: this.width,
                height: this.height
            };
        }
    }

    private get getClass() {
        return `--${this.color}`;
    }
}
