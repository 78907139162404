












import { Component, Prop, Vue } from "vue-property-decorator";
import Overlay from "@components/overlay/Overlay.vue";
import DropdownAtom from "@atoms/dropdown/Dropdown.vue";
import ShareModal from "./_ShareModal.vue";

@Component({
    components: {
        Overlay,
        DropdownAtom,
        ShareModal
    }
})
export default class ShareInfo extends Vue {

    private showDialog = false;
    private dialogType = "";
    private dropdownContent = {
        icon: "bouncy-arrow",
        placeholder: "Del",
        options: [
            {
                label: "Del med kunde",
                value: "customer"
            }, {
                label: "Del med kollega",
                value: "colleague"
            }
        ]
    };

    @Prop({
        type: String,
        default: "delivery",
        validator: (value) => ["delivery", "pickup"].indexOf(value) > -1
    })
    private mode!: string;

    @Prop({ required: true })
    private shareAction;

    @Prop() private images;

    public $refs!: {
        linkPlaceholder: HTMLInputElement
    };

    protected closeShare() {
        this.showDialog = false;
    }

    protected toggleShare(selected) {
        this.dialogType = selected.value;
        this.showDialog = !this.showDialog;
    }
}
