



















import Component from "vue-class-component";
import Vue from "vue";
import Api from "../../../core/api/Api";
import snackbar from "../../components/snackbar/snackbarClient";
import orderBy from "lodash-es/orderBy";

@Component
export default class DcStatusAdmin extends Vue {
    private newExcludedPostalCode: string = "";
    private excludedPostalCodes: string[] = [];

    protected async mounted() {
        const excludedPostalCodes = await Api.delayedDelivery.get();
        this.excludedPostalCodes = excludedPostalCodes;
    }

    private getSortedExcludedPostalCodes() {
        return orderBy(this.excludedPostalCodes, postalCode => postalCode);
    }

    private isPostalCode() {
        return !/^[\d-]{3,}$/.test(this.newExcludedPostalCode);
    }

    private async addExcludedPostalCode() {
        const postalCodeExists = this.excludedPostalCodes.find(x => x === this.newExcludedPostalCode) != null;

        if (postalCodeExists) {
            this.showSnackbar("excluded-postal-code-exists", null);
            return;
        }

        try {
            await Api.delayedDelivery.put(this.newExcludedPostalCode);

            this.excludedPostalCodes.push(this.newExcludedPostalCode);
            this.excludedPostalCodes = orderBy(this.excludedPostalCodes, postalCode => postalCode, "asc");

            this.newExcludedPostalCode = "";
            this.showSnackbar("excluded-postal-code-added", null);

        } catch (error) {
            this.showSnackbar("error", error);
        }
   }

    private async deleteExcludedPostalCodes(selectedPostalCode: string) {
        try {
            await Api.delayedDelivery.delete([selectedPostalCode]);

            this.excludedPostalCodes.splice(this.excludedPostalCodes.indexOf(selectedPostalCode), 1);

            this.showSnackbar("excluded-postal-code-deleted", null);

        } catch (error) {
            this.showSnackbar("error", error);
        }
    }

    private showSnackbar(type, error) {
        switch (type) {
            case "excluded-postal-code-exists":
                snackbar.message("Postkoden eksisterer allerede");
                break;
            case "excluded-postal-code-added":
                snackbar.message("Postkode tilføjet");
                break;
            case "excluded-postal-code-deleted":
                snackbar.message("Postkode slettet");
                break;
            case "error":
                snackbar.error("Der skete en fejl, prøv igen.", error.message);
                break;
        }
    }
}
