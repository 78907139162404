
import { Component, Prop } from "vue-property-decorator";
import StandardLayout from "../../components/layout/standard-layout/StandardLayout.vue";
import { ReturnDeliveryView, ReturnPackageSortOrder, ReturnPackageSummary, SortBy } from "../../../generated/contracts";
import Api from "../../../core/api/Api";
import PickupInfo from "../shared/PickupInfo.vue";
import constants from "../../../core/constants";
import ReturnPackageView from "./ReturnPackageView.vue";

@Component
export default class CustomerReturnDeliveryPage extends StandardLayout<ReturnDeliveryView> {
    private returnPackageSummaries: ReturnPackageSummary[] = [];

    @Prop() private id!: string;

    protected async created() {
        this.pageView = await Api.delivery.getCustomerReturnDeliveryPage(this.id, ReturnPackageSortOrder.Status, SortBy.Ascending,
            constants.NoOfRowsAtATime);
        this.returnPackageSummaries = (this.pageView.content as ReturnDeliveryView).packages;

        this.sections = [
            {
                name: "Returkolli",
                component: ReturnPackageView,
                props: {
                    id: this.id,
                    tab: "0"
                }
            },
            {
                name: "Afhentningsinfo",
                component: PickupInfo,
                props: {
                    pickupInfo: this.pageView.content.pickupInfo
                },
                events: {
                    shareInfo: this.returnInfoShareInfo
                }
            }
        ];
    }

    private returnInfoShareInfo(target: "customer" | "colleague", name: string, email: string, message: string, images: string[]) {
        Api.delivery.shareReturn(target, this.id, name, email, message, images);
    }
}
