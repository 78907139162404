



























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import RouteMap from "./RouteMap.vue";
import ContactInfo from "@atoms/contact-info/ContactInfo.vue";
import Api from "../../../core/api/Api";
import DropList from "@atoms/drop-list/DropList.vue";
import DropModel from "../../atoms/drop-list/dropListModel";
import { DeliveryRouteView, DeliveryStatus, DeliveryStopView, DriverGpsLocation } from "../../../generated/contracts";
import constants from "../../../core/constants";
import findIndex from "lodash-es/findIndex";
import { DropRouteLeg } from "./routeModels";
import snackbar from "../snackbar/snackbarClient";
import logging from "../../../core/logging/logging.service";
import router from "../../../ui/router/index";

interface DeliveryStopViewEx extends DeliveryStopView {
    eta?: Date;
}

@Component({
    components: {
        ContactInfo,
        RouteMap,
        DropList
    }
})
export default class LiveRoute extends Vue {

    private deliveryRoute: DeliveryRouteView | null = null;
    private dropLocations: DeliveryStopViewEx[] = [];
    private selectedDrop: number | null = 0;
    private directions: ReadonlyArray<DropRouteLeg> = [];

    @Prop() private routeNumber!: string;
    @Prop() private orderNumber!: string | undefined;

    private get drops(): DropModel[] {
        if (!this.dropLocations) {
            return [];
        }

        return this.dropLocations
            .map((drop, ix) => {
                return ({
                    ...drop,
                    isDelivered: ix < this.upcomingDrop,
                    isNext: ix === this.upcomingDrop,
                    isTarget: isTarget(drop.orderNumbers, this.orderNumber)
                });
            });

        function isTarget(stopOrderNumbers: string[], orderNumber: string | undefined): boolean {
            return !!(orderNumber && stopOrderNumbers.indexOf(orderNumber) > -1);
        }
    }

    private get deliveryStops() {
        return this.deliveryRoute ? this.deliveryRoute.deliveryStops : [];
        /* Test code
        if (!this.deliveryRoute) {
            return [];
        }
        const stops = this.deliveryRoute.deliveryStops;
        stops.push(JSON.parse(JSON.stringify(stops[1])));
        stops[1].status = DeliveryStatus.Delivered;
        stops[2].status = DeliveryStatus.CouldNotBeDelivered;
        stops[2].latitude = stops[2].latitude + 0.02;
        return stops;
        */
    }

    private get locationHistory(): DriverGpsLocation[] {
        const history = this.deliveryRoute ? this.deliveryRoute.locationHistory
            : [];

        if (history.length === 0 && this.deliveryStops.length > 0) {
            // Start with Terminal (whichs is first in list of stops) as first entry, if we have nothing else
            history.push({
                deliveryRouteId: -1,
                latitude: this.deliveryStops[0].latitude,
                longitude: this.deliveryStops[0].longitude,
                id: -1,
                timeStamp: this.deliveryRoute!.currentTime
            });
        }
        return history;
    }

    private get showHistoryLocationParamForMap(): boolean {
        const showHistoryLocationParam = router.currentRoute.query["showHistoryLocation"];
        return showHistoryLocationParam ? showHistoryLocationParam.toLowerCase() === "true" : false;
    }

    private get locationHistoryForMap(): ReadonlyArray<google.maps.LatLngLiteral> {
        return Object.freeze(this.locationHistory.map(l => this.latLngToGoogleLatLng(l.latitude, l.longitude)));
    }

    private get deliveryStopsForMap() {
        return this.deliveryStops.slice(1)   // Don't use Terminal
            .map(h => this.latLngToGoogleLatLng(h.latitude, h.longitude));
    }

    private async created() {
        this.deliveryRoute = await Api.deliveryroute.getRoute(this.routeNumber);

        try {
            const directions = await this.getAllDirections();
            this.directions = Object.freeze(directions);
        } catch (error) {
            // Catch google error here - the rest can probably proceed.
            logging.exception("Google directions error", error);
        }
        this.dropLocations = this.deliveryStops.map((drop, ix) => {
            return {
                ...drop,
                eta: eta.bind(this)(ix)
            };

            function eta(this: LiveRoute, index: number): Date | undefined {

                if (index < this.upcomingDrop) {
                    // Only future drops
                    return undefined;
                }
                // The index of the routeleg for the current drop
                const directionIxForDrop = ix - this.upcomingDrop;
                // duration is per route-leg, so accumulate over alle route-legs.
                let durationSecs = this.directions.reduce((sum: number, direction: DropRouteLeg, directionIx: number) => {
                    if (directionIx > directionIxForDrop) {
                        // Don't include durations further out than directionIxForDrop
                        return sum;
                    }
                    return sum + direction.duration;
                }, 0);
                // Add average time spent dropping off
                durationSecs += directionIxForDrop * constants.MinutesPerDrop * 60;
                return new Date(new Date().getTime() + durationSecs * 1000);
            }
        });

        // Set initial selection if related order
        if (this.orderNumber) {
            const index = this.deliveryStops.findIndex(s => s.orderNumbers.indexOf(this.orderNumber!) > -1);
            if (index > -1) {
                this.setDrop(index - 1);
            }
        }
    }

    private get upcomingDrop(): number {
        // First is DC, skip that.
        const upcomingIx = findIndex(this.deliveryStops, (stop, ix) => ix > 0 && isNotDone(stop.status));

        // If all done, set it to one after last
        return upcomingIx > -1 ? upcomingIx : this.deliveryStops.length;

        function isNotDone(status: DeliveryStatus) {
            return [
                DeliveryStatus.Delivered,
                DeliveryStatus.PartiallyDelivered,
                DeliveryStatus.CouldNotBeDelivered,
                DeliveryStatus.NotReturned,
                DeliveryStatus.Returned
            ].indexOf(status) === -1;
        }
    }

    private get currentLocation(): google.maps.LatLngLiteral | undefined {
        if (!this.deliveryRoute) {
            return undefined;
        }
        // Use last history-location (always has Terminal pos if nothing else)
        const gpsLocation = this.locationHistory[this.locationHistory.length - 1];
        return this.latLngToGoogleLatLng(gpsLocation.latitude, gpsLocation.longitude);
    }

    private latLngToGoogleLatLng(lat: number, lng: number): google.maps.LatLngLiteral {
        return {
            lat,
            lng
        };
    }

    private async getAllDirections(): Promise<DropRouteLeg[]> {
        // We can only ask for directions for 25 points at a time (incl. start / stop). Make chunk-requests and union.
        return new Promise<DropRouteLeg[]>(async (resolve, reject) => {
            // Only get directions for the upcoming drops.
            const relevantDrops = this.deliveryStops
                .slice(this.upcomingDrop)
                .map(drop => this.latLngToGoogleLatLng(drop.latitude, drop.longitude));
            let result: DropRouteLeg[] = [];
            const startTime = new Date(this.deliveryRoute!.currentTime);
            const maxWaypointsAndEnd = 24;  // 25 - 1 start-location

            let startLocation = this.currentLocation!;
            let startIndex = 0;
            let firstTime = true; // Special because startLocation does not come from drops.

            try {
                for (; ;) {
                    const nextDrops = relevantDrops.slice(firstTime ? 0 : startIndex + 1, firstTime ? maxWaypointsAndEnd : startIndex + maxWaypointsAndEnd + 1);
                    const directions = await this.getDirections(startLocation, nextDrops, startTime);
                    result = result.concat(directions);
                    startIndex += firstTime ? maxWaypointsAndEnd - 1 : maxWaypointsAndEnd;
                    if (startIndex >= relevantDrops.length - 1) {
                        // Processed all
                        break;
                    }

                    firstTime = false;
                    startLocation = relevantDrops[startIndex];
                }
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    private async getDirections(origin: google.maps.LatLngLiteral,
                                drops: google.maps.LatLngLiteral[], departureTime: Date): Promise<DropRouteLeg[]> {
        return new Promise<DropRouteLeg[]>((resolve, reject) => {
            if (!drops || drops.length === 0) {
                resolve([]);
                return;
            }
            const waypoints = drops.slice(0, -1)
                .map(loc => {
                    return ({
                        location: loc,
                        stopover: true
                    });
                });
            const request: google.maps.DirectionsRequest = {
                origin,
                waypoints,
                destination: drops[drops.length - 1],
                travelMode: google.maps.TravelMode.DRIVING,
                drivingOptions: {
                    departureTime,
                    trafficModel: google.maps.TrafficModel.BEST_GUESS
                },
                optimizeWaypoints: false,
                unitSystem: google.maps.UnitSystem.METRIC
            };
            new google.maps.DirectionsService().route(request, (response, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    const result = response.routes[0].legs.map(leg => {
                        return {
                            steps: leg.steps
                                .reduce((acc: google.maps.LatLng[], step) => {
                                    return acc.concat(step.path);
                                }, [])
                                .map(pathElem => {
                                    return this.latLngToGoogleLatLng(pathElem.lat(), pathElem.lng());
                                }),
                            duration: leg.duration.value
                        };
                    });
                    resolve(result);
                } else {
                    snackbar.error("Google directions returnerede en advarsel", status.toString());
                    reject(status);
                }
            });
        });
    }

    private setDrop(index) {
        // To be able to trigger drop-action even if its already selected
        setTimeout(() => this.selectedDrop = null);
        setTimeout(() => this.selectedDrop = index);
    }
}
