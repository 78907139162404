

















import { Component, Prop, Vue } from "vue-property-decorator";
import { logisticsTypeDisplayName } from "../../../util/dictionary";
import route, { getRoute } from "../../../router/index";
import { BreadcrumbPath, LogisticsType } from "../../../../generated/contracts";
import IconAtom from "@atoms/icons/Icons.vue";
import SkeletonLoaderAtom from "@atoms/skeleton-loader/SkeletonLoader.vue";

@Component({
    components: {
        IconAtom,
        SkeletonLoaderAtom
    }
})
export default class Breadcrumb extends Vue {
    @Prop({
        default: () => [],
        type: Array
    }) private path!: BreadcrumbPath[];

    private pathElemKey(path: BreadcrumbPath) {
        return `${path.type} - ${path.id}`;
    }

    private logisticsTypeDisplayName(type: LogisticsType) {
        return logisticsTypeDisplayName(type);
    }

    private selectPath(path: BreadcrumbPath, ix: number) {
        if (this.isLast(ix)) {
            return;
        }
        route.push({ name: getRoute(path.type), params: { id: path.id } });
    }

    private get classes() {
        return (ix: number) => {
            return {
                disabled: this.isLast(ix),
                path: true
            };
        };
    }

    private isLast(ix: number) {
        return this.path && ix + 1 === this.path.length;
    }
}
