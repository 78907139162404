











import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import IconAtom from "@atoms/icons/Icons.vue";
import debounce from "lodash-es/debounce";

@Component({
    props: {
        openPlaceholder: String,
        closedPlaceholder: String
    },
    components: {
        IconAtom
    }
})
export default class ToolbarSearchAtom extends Vue {
    @Prop({ default: "Søg i tabel" }) private closedPlaceholder!: string;
    @Prop({ default: "Søg efter..." }) private openPlaceholder!: string;
    @Prop({ default: 300, type: Number }) private debounceDelay!: number;
    @Prop({ default: null }) private initialSearchTerm!: string;

    private initialClosedPlaceholder = this.closedPlaceholder;
    private inputModel = this.initialSearchTerm ? this.initialSearchTerm : this.initialClosedPlaceholder;

    protected onFocus() {
        if (this.inputModel === this.initialClosedPlaceholder) {
            this.inputModel = "";
        }
    }

    protected onBlur() {
        if (this.inputModel === "") {
            this.inputModel = this.initialClosedPlaceholder;
        }
    }

    protected clear() {
        this.inputModel = "";
    }

    @Watch("inputModel")
    private onInputModelChanged(value, oldValue) {
        // Don't emit changes to / from initialtext
        if (value === this.initialClosedPlaceholder && !oldValue
            || oldValue === this.initialClosedPlaceholder && !value) {
            return;
        }
        if (value.length === 0 || value.length >= 3) {
            this.debouncedSendChange(value);
        }
    }

    private debouncedSendChange = debounce(this.sendChange, this.debounceDelay);

    private sendChange(value: string) {
        this.$emit("searchTerm", value);
    }
}
