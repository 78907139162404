
















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { DeliveryOnRouteSortOrder, DeliveryOnRouteSummary } from "../../../generated/contracts";
import Api from "../../../core/api/Api";
import { TableHeader } from "../../atoms/table/tableModels";
import { dateTimeFilter } from "../../filters/dateTimeFilters";
import TableViewControlModel, { ToolbarItem } from "../../components/table-view/tableViewModels";
import constants from "../../../core/constants";
import { statusToDisplayNameAndColor, toDomainSortBy } from "../shared/tableHelper";
import TableView from "../../components/table-view/TableView.vue";
import router from "../../router";

interface ExtendedDeliveryOnRouteSummary extends DeliveryOnRouteSummary {
    addressLine: string;
    city: string;
    postalCode: string;
}

interface DeliveryOnRouteSummaryTableHeader extends TableHeader {
    key: keyof (ExtendedDeliveryOnRouteSummary);
}

@Component({
    components: { TableView }
})
export default class DeliveryRouteHistoryView extends Vue {
    @Prop() private id!: string;
    @Prop() private tab!: string;

    private loadFromUrl = this.tab === router.currentRoute.query["tab"];
    private initialSortOrder: keyof (DeliveryOnRouteSummary) = router.currentRoute.query["sortOrder"] && this.loadFromUrl ?
                (router.currentRoute.query["sortOrder"] as keyof (DeliveryOnRouteSummary))! :
                "status";
    private initialSortBy = router.currentRoute.query["sortByAsc"] && this.loadFromUrl ?
                router.currentRoute.query["sortByAsc"] === "true" :
                true;
    private initialSearchTerm = router.currentRoute.query["searchTerm"] && this.loadFromUrl ?
                router.currentRoute.query["searchTerm"] :
                null;
    private initialFromDate = router.currentRoute.query["fromDate"] && this.loadFromUrl ?
                router.currentRoute.query["fromDate"] as string :
                null;
    private initialToDate = router.currentRoute.query["toDate"] && this.loadFromUrl ?
                router.currentRoute.query["toDate"] as string :
                null;

    private tableControlModel: TableViewControlModel = {
        fromDate: this.initialFromDate ? this.initialFromDate : null,
        toDate: this.initialToDate ? this.initialToDate : null,
        searchTerm: this.initialSearchTerm,
        sort: {
            sortOrder: this.initialSortOrder,
            sortByAscending: this.initialSortBy
        }
    };
    private loadingOrderBy = false;
    private loadingMore = false;
    private deliveriesOnRouteSummaries: DeliveryOnRouteSummary[] = [];

    private mounted() {
        this.load(0, true);
    }

    private get tableHeader(): DeliveryOnRouteSummaryTableHeader[] {
        return [
            { key: "noteNumber", title: "F.seddel", size: 10 },
            { key: "orderNumber", title: "Ordrenummer", size: 10 },
            { key: "routeNumber", title: "Rute", size: 9 },
            { key: "customerName", title: "Kunde", size: 20 },
            { key: "addressLine", title: "Leveringsadresse", size: 11 },
            { key: "postalCode", title: "Postnr", size: 7 },
            { key: "city", title: "By", size: 10 },
            { key: "deliveredTimeUtc", title: "Leveret", size: 13 },
            { key: "status", title: "Status", size: 10 }
        ];
    }

    private rowClicked(row: DeliveryOnRouteSummary) {
        this.$router.push({ name: row.isReturnDelivery ? "CustomerReturnDelivery" : "Delivery", params: { id: row.noteNumber } });
    }

    private tableControlModelChanged(tableControlData: TableViewControlModel) {
        this.tableControlModel = tableControlData;
        this.loadingOrderBy = false;
        this.load();
    }

    private async load(skip = 0, firstLoad = false, take = constants.NoOfRowsAtATime) {
        this.loadingMore = true;
        try {
            const sortByAsc = router.currentRoute.query["sortByAsc"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["sortByAsc"] === "true" :
                this.tableControlModel.sort.sortByAscending;

            const sortOrder = router.currentRoute.query["sortOrder"] && firstLoad && this.loadFromUrl ?
                (router.currentRoute.query["sortOrder"] as keyof (DeliveryOnRouteSummary))! :
                (this.tableControlModel.sort.sortOrder as keyof (DeliveryOnRouteSummary))!;

            const searchTerm = router.currentRoute.query["searchTerm"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["searchTerm"] :
                this.tableControlModel.searchTerm;

            const fromDate = router.currentRoute.query["fromDate"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["fromDate"] as string :
                this.tableControlModel.fromDate;

            const toDate = router.currentRoute.query["toDate"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["toDate"] as string :
                this.tableControlModel.toDate;

            this.loadFromUrl = this.tab === router.currentRoute.query["tab"];

            if (!firstLoad && this.loadFromUrl) {
                router.replace({ query: {
                    ...this.$route.query,
                    sortOrder: sortOrder.toString(),
                    sortByAsc: sortByAsc.toString(),
                    searchTerm: searchTerm as string,
                    fromDate: fromDate ? fromDate.toString() : "",
                    toDate: toDate ? toDate.toString() : ""} });
                }
            const deliveries: DeliveryOnRouteSummary[] = await Api.driver.getDeliveriesOnRouteByDeliveryRouteId(this.id,
                this.toDeliveryOnRouteSummarySortOrder(sortOrder),
                toDomainSortBy(sortByAsc),
                searchTerm,
                fromDate,
                toDate,
                skip,
                take);

            this.deliveriesOnRouteSummaries = skip > 0 ? [...this.deliveriesOnRouteSummaries, ...deliveries] : deliveries;
        } finally {
            this.loadingOrderBy = false;
            this.loadingMore = false;
        }
    }

    private toDeliveryOnRouteSummarySortOrder(key: keyof (ExtendedDeliveryOnRouteSummary)): DeliveryOnRouteSortOrder {
        switch (key) {
            case "routeNumber":
                return DeliveryOnRouteSortOrder.RouteNumber;
            case "customerName":
                return DeliveryOnRouteSortOrder.CustomerName;
            case "addressLine":
                return DeliveryOnRouteSortOrder.Address;
            case "postalCode":
                return DeliveryOnRouteSortOrder.PostalCode;
            case "city":
                return DeliveryOnRouteSortOrder.City;
            case "orderNumber":
                return DeliveryOnRouteSortOrder.OrderNumber;
            case "noteNumber":
                return DeliveryOnRouteSortOrder.NoteNumber;
            case "deliveredTimeUtc":
                return DeliveryOnRouteSortOrder.DeliveredTime;
            case "status":
                return DeliveryOnRouteSortOrder.Status;
            default:
                throw Error(`Unhandled sort-order: ${key}`);
        }
    }

    private async loadMore(done: () => void) {
        await this.load(this.tableContent.length);
        done();
    }

    private get tableContent(): object[] {
        return this.deliveriesOnRouteSummaries.map((d: DeliveryOnRouteSummary) => ({
            ...d,
            deliveredTimeUtc: dateTimeFilter(d.deliveredTimeUtc),
            addressLine: d.address ? d.address.line1 : null,
            city: d.address ? d.address.city : null,
            postalCode: d.address ? d.address.postalCode : null,
            status: statusToDisplayNameAndColor(d.status)
        }));
    }

    private get toolbarItems() {
        return [ToolbarItem.TextSearch, ToolbarItem.DatePicker];
    }
}
