export interface SortDefinition {
    sortOrder: string | null;
    sortByAscending: boolean;
}

export const enum ToolbarItem {
    TextSearch,
    DatePicker,
    Export
}

export default interface TableViewControlModel {
    sort: SortDefinition;
    searchTerm: string | null;
    fromDate: string | null;
    toDate: string | null;
}

