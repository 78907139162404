
import { Component, Prop } from "vue-property-decorator";
import StandardLayout from "../../components/layout/standard-layout/StandardLayout.vue";
import { DeliverySortOrder, DeliverySummary, OrderDeliveryView, OrderView, SortBy } from "../../../generated/contracts";
import Api from "../../../core/api/Api";
import constants from "../../../core/constants";
import OrderDetailsView from "./OrderDetailsView.vue";
import OrderLinesView from "./OrderLinesView.vue";

@Component
export default class OrderPage extends StandardLayout<OrderView> {
    private deliverySummaries: DeliverySummary[] = [];

    @Prop() private id!: string;

    protected async created() {
        this.pageView = await Api.order.getOrderPage(this.id, DeliverySortOrder.Status, SortBy.Ascending,
            constants.NoOfRowsAtATime);
        this.deliverySummaries = (this.pageView.content as OrderDeliveryView).deliveries;
        this.sections = [
            {
                name: "Ordredetaljer",
                component: OrderDetailsView,
                props: {
                    id: this.id,
                    tab: "0"
                }
            },
            {
                name: "Ordrelinjer",
                component: OrderLinesView,
                props: {
                    id: this.id,
                    tab: "1"
                }
            }
            /*
            {
                name: "Leveringsbeskrivelse",
                component: DeliveryDescriptions,
                props: { descriptions: this.pageView.content.deliveryDescriptions }
            }
            */
        ];

        if (this.pageView.content.isInTransit || this.$route.query["showMap"]) {
            this.inTransit = {
                routeId: this.pageView.content.currentDeliveryRouteId!,
                displayText: `Levering på vej`,
                orderNumber: this.pageView.content.orderNumber,
                simulated: !this.pageView.content.isInTransit
            };
        }
    }

}
