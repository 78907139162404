import Vue from "vue";
import Router from "vue-router";
import DcStatus from "../views/dc-status/DcStatus.vue";
import CustomerPage from "../views/customer/CustomerPage.vue";
import ProjectPage from "../views/project/ProjectPage.vue";
import OrderPage from "../views/order/OrderPage.vue";
import DeliveryPage from "../views/delivery/DeliveryPage.vue";
import ReturnPackagePage from "../views/return-package/ReturnPackagePage.vue";
import StoreReturnPackagePage from "../views/store/StoreReturnPackagePage.vue";
import CustomerReturnDeliveryPage from "../views/customer-return-delivery/CustomerReturnDeliveryPage.vue";
import PackagePage from "../views/package/PackagePage.vue";
import DistributionCenterPage from "../views/distribution-center/DistributionCenterPage.vue";
import StorePage from "../views/store/StorePage.vue";
import DriverPage from "../views/driver/DriverPage.vue";
import LoginPage from "../views/login/LoginPage.vue";
import TrackAndTracePage from "../views/trackandtrace/TrackAndTracePage.vue";
import DeliveryRoutePage from "../views/delivery-route/DeliveryRoutePage.vue";
import { LogisticsType } from "@/generated/contracts";
import store from "../../store";
//import KitchenSink from "../views/kitchensink/Kitchensink.vue";

Vue.use(Router);

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "Home",
            component: DcStatus,
            meta: { private: true }
        },
        {
            path: "/login",
            name: "Login",
            components: {
                login: LoginPage
            },
            props: true
        },
        {
            path: "/track&trace/:id",
            name: "TrackAndTrace",
            component: TrackAndTracePage,
            props: true
        },
        {
            path: "/order/:id",
            name: "Order",
            component: OrderPage,
            props: true,
            meta: { private: true }
        },
        {
            path: "/delivery/:id",
            name: "Delivery",
            component: DeliveryPage,
            props: true,
            meta: { private: true }
        },
        {
            path: "/package/:id",
            name: "Package",
            component: PackagePage,
            props: true,
            meta: { private: true }
        },
        {
            path: "/return-package/:id",
            name: "ReturnPackage",
            component: ReturnPackagePage,
            props: true,
            meta: { private: true }
        },
        {
            path: "/customer-return-delivery/:id",
            name: "CustomerReturnDelivery",
            component: CustomerReturnDeliveryPage,
            props: true,
            meta: { private: true }
        },
        {
            path: "/store/:id",
            name: "Store",
            component: StorePage,
            props: true,
            meta: { private: true }
        },
        {
            path: "/store/:storeAccountNumber/return-package/:id",
            name: "StoreReturnPackage",
            component: StoreReturnPackagePage,
            props: true,
            meta: { private: true }
        },
        {
            path: "/driver/:id",
            name: "Driver",
            component: DriverPage,
            props: true,
            meta: { private: true }
        },
        {
            path: "/deliveryroute/:id",
            name: "DeliveryRoute",
            component: DeliveryRoutePage,
            props: true,
            meta: { private: true }
        },
        {
            path: "/distribution-center/:id",
            name: "DistributionCenter",
            component: DistributionCenterPage,
            props: true,
            meta: { private: true }
        },
        {
            path: "/customer/:id",
            name: "Customer",
            component: CustomerPage,
            props: true,
            meta: { private: true }
        },
        {
            path: "/project/:id",
            name: "Project",
            component: ProjectPage,
            props: true,
            meta: { private: true }
        }
        //{
        //    path: "/kitchensink",
        //    name: "Kitchensink",
        //    component: KitchenSink
        //}
    ]
});

router.beforeEach((to, from, next) => {
    const loginView = store.state.login;
    // If private page and not logged in - login
    if (to.matched.some(r => r.meta.private) && !loginView) {
        next({
            name: "Login"
        });
        return;
    }
    // If logged in - go to home
    if (to.matched.some(r => r.name === "Login") && loginView) {
        next({
            name: "Home"
        });
        return;
    }

    if (!store.getters.isLoggedIn &&
        !to.matched.some(r => r.name === "TrackAndTrace") &&
        !to.matched.some(r => r.name === "Login") &&
        !to.matched.some(r => r.name === "Kitchensink")) {
        next({
            name: "Login"
        });
        return;
    }
    next();
});

export default router;

export function getRoute(logisticsType: LogisticsType): string {
    return LogisticsType[logisticsType];
}
