import bus from "../../../core/bus/Bus.vue";

function message(msg: string) {
    bus.SnackbarMessage({
        message: msg,
        type: "success"
    });
}

function error(msg: string, err?: string) {
    bus.SnackbarMessage({
        message: msg,
        error: err,
        type: "error"
    });
}

export default {
    message,
    error
};
