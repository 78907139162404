














import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { DeliverySortOrder, DeliverySummary } from "../../../generated/contracts";
import Api from "../../../core/api/Api";
import { TableHeader } from "../../atoms/table/tableModels";
import { dateTimeFilter } from "../../filters/dateTimeFilters";
import router from "../../router";
import TableViewControlModel, { ToolbarItem } from "../../components/table-view/tableViewModels";
import constants from "../../../core/constants";
import { statusToDisplayNameAndColor, toDomainSortBy } from "../shared/tableHelper";
import TableView from "../../components/table-view/TableView.vue";

interface ExtendedDeliverySummary extends DeliverySummary {
    addressLine: string;
    city: string;
    postalCode: string;
}

interface DeliverySummaryTableHeader extends TableHeader {
    key: keyof (ExtendedDeliverySummary);
}

@Component({
    components: { TableView }
})
export default class OrderDetailsView extends Vue {
    @Prop() private id!: string;
    @Prop() private tab!: string;

    private loadFromUrl = this.tab === router.currentRoute.query["tab"];
    private initialSortOrder: keyof (DeliverySummary) = router.currentRoute.query["sortOrder"] && this.loadFromUrl ?
                (router.currentRoute.query["sortOrder"] as keyof (DeliverySummary))! :
                "status";
    private initialSortBy = router.currentRoute.query["sortByAsc"] && this.loadFromUrl ?
                router.currentRoute.query["sortByAsc"] === "true" :
                true;
    private initialSearchTerm = router.currentRoute.query["searchTerm"] && this.loadFromUrl ?
                router.currentRoute.query["searchTerm"] :
                null;

    private tableControlModel: TableViewControlModel = {
        fromDate: null,
        toDate: null,
        searchTerm: this.initialSearchTerm,
        sort: {
            sortOrder: this.initialSortOrder,
            sortByAscending: this.initialSortBy
        }
    };
    private loadingOrderBy = false;
    private loadingMore = false;
    private deliverySummaries: DeliverySummary[] = [];

    private mounted() {
        this.load(0, true);
    }

    private get tableHeader(): DeliverySummaryTableHeader[] {
        return [
            { key: "noteNumber", title: "Følgeseddel", size: 15 },
            { key: "addressLine", title: "Adresse", size: 17 },
            { key: "postalCode", title: "Postnummer", size: 10 },
            { key: "city", title: "By", size: 20 },
            { key: "deliveryTimeUtc", title: "Leveret", size: 18 },
            { key: "status", title: "Status", size: 20 }
        ];
    }

    private rowClicked(row: DeliverySummary) {
        router.push({ name: "Delivery", params: { id: row.noteNumber } });
    }

    private tableControlModelChanged(tableControlData: TableViewControlModel) {
        this.tableControlModel = tableControlData;
        this.loadingOrderBy = false;
        this.load();
    }

    private async load(skip = 0, firstLoad = false, take = constants.NoOfRowsAtATime) {
        this.loadingMore = true;
        try {
            const sortByAsc = router.currentRoute.query["sortByAsc"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["sortByAsc"] === "true" :
                this.tableControlModel.sort.sortByAscending;

            const sortOrder = router.currentRoute.query["sortOrder"] && firstLoad && this.loadFromUrl ?
                (router.currentRoute.query["sortOrder"] as keyof (DeliverySummary))! :
                (this.tableControlModel.sort.sortOrder as keyof (DeliverySummary))!;

            const searchTerm = router.currentRoute.query["searchTerm"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["searchTerm"] :
                this.tableControlModel.searchTerm;

            this.loadFromUrl = this.tab === router.currentRoute.query["tab"];

            if (!firstLoad && this.loadFromUrl) {
                router.replace({ query: {
                    ...this.$route.query,
                    sortOrder: sortOrder.toString(),
                    sortByAsc: sortByAsc.toString(),
                    searchTerm: searchTerm as string} });
                }

            const deliveries: DeliverySummary[] = await Api.delivery.getDeliverySummaries(this.id,
                this.toDeliverySummarySortOrder(sortOrder),
                toDomainSortBy(sortByAsc),
                searchTerm,
                skip,
                take);

            this.deliverySummaries = skip > 0 ? [...this.deliverySummaries, ...deliveries] : deliveries;
        } finally {
            this.loadingOrderBy = false;
            this.loadingMore = false;
        }
    }

    private toDeliverySummarySortOrder(key: keyof (ExtendedDeliverySummary)): DeliverySortOrder {
        switch (key) {
            case "noteNumber":
                return DeliverySortOrder.NoteNumber;
            case "routeNumber":
                return DeliverySortOrder.Route;
            case "addressLine":
                return DeliverySortOrder.Address;
            case "postalCode":
                return DeliverySortOrder.PostalCode;
            case "city":
                return DeliverySortOrder.City;
            case "deliveryTimeUtc":
                return DeliverySortOrder.DeliveryDate;
            case "status":
                return DeliverySortOrder.Status;
            default:
                throw Error(`Unhandled sort-order: ${key}`);
        }
    }

    private async loadMore(done: () => void) {
        await this.load(this.tableContent.length);
        done();
    }

    private get tableContent(): object[] {
        return this.deliverySummaries.map((d: DeliverySummary) => ({
            ...d,
            deliveryTimeUtc: dateTimeFilter(d.deliveryTimeUtc),
            addressLine: `${d.address ? d.address.line1 : null}`,
            city: d.address ? d.address.city : null,
            postalCode: d.address ? d.address.postalCode : null,
            status: statusToDisplayNameAndColor(d.status)
        }));
    }

    private get toolbarItems() {
        return [ToolbarItem.TextSearch];
    }
}
