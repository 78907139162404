import { render, staticRenderFns } from "./_ShareInfo.vue?vue&type=template&id=dc4c8c28&scoped=true&"
import script from "./_ShareInfo.vue?vue&type=script&lang=ts&"
export * from "./_ShareInfo.vue?vue&type=script&lang=ts&"
import style0 from "./_ShareInfo.vue?vue&type=style&index=0&id=dc4c8c28&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc4c8c28",
  null
  
)

component.options.__file = "_ShareInfo.vue"
export default component.exports