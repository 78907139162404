

















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { TableHeader } from "../../atoms/table/tableModels";
import TableViewControlModel, { ToolbarItem } from "../../components/table-view/tableViewModels";
import constants from "../../../core/constants/index";
import { statusToDisplayNameAndColor, toDomainSortBy } from "./tableHelper";
import TableView from "../../components/table-view/TableView.vue";
import { dateTimeFilter } from "../../filters/dateTimeFilters";
import { ErrorSortOrder, ErrorSummary } from "../../../generated/contracts";
import router from "../../router/index";

@Component({
    components: {
        TableView
    }
})
export default class ErrorView extends Vue {
    @Prop() private tab!: string;
    @Prop() private id!: string;
    @Prop() private apiMethod;
    @Prop({ default: false }) private isDistributionCenter!: boolean;

    private loadFromUrl = this.tab === router.currentRoute.query["tab"];
    private initialSortOrder: keyof (ErrorSummary) = router.currentRoute.query["sortOrder"] && this.loadFromUrl ?
                (router.currentRoute.query["sortOrder"] as keyof (ErrorSummary))! :
                "deliveryTimeUtc";
    private initialSortBy = router.currentRoute.query["sortByAsc"] && this.loadFromUrl ?
                router.currentRoute.query["sortByAsc"] === "true" :
                false;
    private initialSearchTerm = router.currentRoute.query["searchTerm"] && this.loadFromUrl ?
                router.currentRoute.query["searchTerm"] :
                null;
    private initialFromDate = router.currentRoute.query["fromDate"] && this.loadFromUrl ?
                router.currentRoute.query["fromDate"] as string :
                null;
    private initialToDate = router.currentRoute.query["toDate"] && this.loadFromUrl ?
                router.currentRoute.query["toDate"] as string :
                null;

    private tableControlModel: TableViewControlModel = {
        fromDate: this.initialFromDate ? this.initialFromDate : null,
        toDate: this.initialToDate ? this.initialToDate : null,
        searchTerm: this.initialSearchTerm,
        sort: {
            sortOrder: this.initialSortOrder,
            sortByAscending: this.initialSortBy
        }
    };

    private errorSummaries: ErrorSummary[] = [];
    private loadingOrderBy = false;
    private loadingMore = false;

    private mounted() {
        this.load(0, true);
    }

    private get tableHeader(): TableHeader[] {
        const header = [
            { key: "ssccNumber", title: "SSCC", size: 15 },
            { key: "routeNumber", title: "Rute", size: 6 },
            { key: "owner", title: this.isDistributionCenter ? "Butik" : "Lager", size: 9 },
            { key: "warehouseArea", title: "Lomnr.", size: 8 },
            { key: "orderNumber", title: "Ordrenr.", size: 9 },
            { key: "customerName", title: "Kundenavn", size: this.isDistributionCenter ? 11 : 15 },
            { key: "ourReference", title: "Sælgers navn", size: 11 },
            { key: "deliveryTimeUtc", title: "Dato / tid", size: 12 },
            { key: "status", title: "Status", size: 12 }
        ];
        if (this.isDistributionCenter) {
            header.splice(3, 0, { key: "warehouse", title: "Lager", size: 8 });
        }
        return header;
    }

    private rowClicked(row: ErrorSummary) {
        const resolved = this.$router.resolve({ name: "Package", params: { id: row.ssccNumber } });
        window.open(window.location.origin + resolved.href, "_blank");
    }

    private tableControlModelChanged(tableControlData: TableViewControlModel) {
        this.tableControlModel = tableControlData;
        this.loadingOrderBy = true;
        this.load();
    }

    private async load(skip = 0, firstLoad = false, take = constants.NoOfRowsAtATime) {
        this.loadingMore = true;
        try {
            const sortByAsc = router.currentRoute.query["sortByAsc"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["sortByAsc"] === "true" :
                this.tableControlModel.sort.sortByAscending;

            const sortOrder = router.currentRoute.query["sortOrder"] && firstLoad && this.loadFromUrl ?
                (router.currentRoute.query["sortOrder"] as keyof (ErrorSummary))! :
                (this.tableControlModel.sort.sortOrder as keyof (ErrorSummary))!;

            const searchTerm = router.currentRoute.query["searchTerm"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["searchTerm"] :
                this.tableControlModel.searchTerm;

            const fromDate = router.currentRoute.query["fromDate"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["fromDate"] as string :
                this.tableControlModel.fromDate;

            const toDate = router.currentRoute.query["toDate"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["toDate"] as string :
                this.tableControlModel.toDate;

            this.loadFromUrl = this.tab === router.currentRoute.query["tab"];

            if (!firstLoad && this.loadFromUrl) {
                router.replace({ query: {
                    ...this.$route.query,
                    sortOrder: sortOrder.toString(),
                    sortByAsc: sortByAsc.toString(),
                    searchTerm: searchTerm as string,
                    fromDate: fromDate ? fromDate.toString() : "",
                    toDate: toDate ? toDate.toString() : ""} });
                }
            const errors: ErrorSummary[] = await this.apiMethod(this.id,
                this.toErrorSummarySortOrder(sortOrder),
                toDomainSortBy(sortByAsc),
                searchTerm,
                fromDate,
                toDate,
                skip,
                take);

            this.errorSummaries = skip > 0 ? [...this.errorSummaries, ...errors] : errors;
        } finally {
            this.loadingMore = false;
            this.loadingOrderBy = false;
        }
    }

    protected toErrorSummarySortOrder(key: keyof (ErrorSummary)): ErrorSortOrder {
        switch (key) {
            case "ssccNumber":
                return ErrorSortOrder.SsccNumber;
            case "owner":
                return ErrorSortOrder.Owner;
            case "routeNumber":
                return ErrorSortOrder.RouteNumber;
            case "warehouseArea":
                return ErrorSortOrder.WarehouseArea;
            case "warehouse":
                return ErrorSortOrder.Warehouse;
            case "orderNumber":
                return ErrorSortOrder.OrderNumber;
            case "customerName":
                return ErrorSortOrder.CustomerName;
            case "ourReference":
                return ErrorSortOrder.OurReference;
            case "deliveryTimeUtc":
                return ErrorSortOrder.DeliveryDate;
            case "status":
                return ErrorSortOrder.Status;
        }
    }

    private async loadMore(done: () => void) {
        await this.load(this.tableContent.length);
        done();
    }

    private get tableContent(): object[] {
        return this.errorSummaries.map((d: ErrorSummary) => ({
            ...d,
            deliveryTimeUtc: dateTimeFilter(d.deliveryTimeUtc),
            status: statusToDisplayNameAndColor(d.status)
        }));
    }

    private get toolbarItems() {
        return [ToolbarItem.TextSearch, ToolbarItem.DatePicker];
    }
}
