












import {Vue, Component, Prop} from "vue-property-decorator";

import IconAtom from "@atoms/icons/Icons.vue";

@Component({
    components: {
        IconAtom
    }
})
export default class SearchAnimation extends Vue {
    @Prop({default: "Start en søgning"}) private text!: string;
}
