import "es6-promise/auto";
import Vue from "vue";
import App from "./ui/App.vue";
import router from "./ui/router";
import store from "./store";
import "./assets/scss/main.scss";
import serverContext from "./core/server-context";
import loadGoogleMapsApi from "load-google-maps-api";
import PortalVue from "portal-vue";
import "./globalRegistrations";
import "./core/logging/logging.service";

import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import * as datepickerOptions from "./core/calendar-settings";

import VueAuthImage from "vue-auth-image";

Vue.config.productionTip = false;

Vue.use(PortalVue);
Vue.use(AirbnbStyleDatepicker, datepickerOptions.default);

Vue.use(VueAuthImage);

loadGoogleMapsApi({
    key: serverContext.googleMapsApiKey,
    libraries: ["geometry"]
})
    .then(() => {
        // tslint:disable-next-line
        new Vue({
            router,
            store,
            render: h => h(App),
            mounted() {
                // Prevent blank screen in Electron builds
                // this.$router.push("/");
            }
        }).$mount("#app");
    })
    .catch((error: any) => {
        console.error(error);
    });
