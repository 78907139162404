













































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import IconAtom from "@atoms/icons/Icons.vue";
import LoaderAtom from "@atoms/loader/Loader.vue";
import Overlay from "../../components/overlay/Overlay.vue";
import StaticMap from "@components/static-map/StaticMap.vue";
import IsSearchingAnimation from "../../chrome/search/_IsSearchingAnimation.vue";
import { TrackAndTraceView } from "../../../generated/contracts";
import Api from "../../../core/api/Api";

@Component({
    components: {
        IconAtom,
        Overlay,
        LoaderAtom,
        StaticMap,
        IsSearchingAnimation
    }
})
export default class TrackAndTracePage extends Vue {
    @Prop() private id!: string;

    private showMap: boolean = true;
    private isSearching: boolean = true;
    private showContentMessage = true;
    private view!: TrackAndTraceView;

    protected async mounted() {
        try {
            this.view = await Api.trackAndTrace.getPackage(this.id);
            if (this.view == null) {
                this.showMap = false;
            }
        } catch (error) {
            this.showMap = false;
        } finally {
            this.isSearching = false;
        }
    }

    private get getLocation() {
        return {
            lat: this.view.latitude,
            lng: this.view.longitude
        };
    }

    protected toggleContentMessage() {
        this.showContentMessage = !this.showContentMessage;
    }
}
