






































import { Component, Prop, Vue } from "vue-property-decorator";
import format from "date-fns/format";
import da from "date-fns/locale/da";

import IconAtom from "@atoms/icons/Icons.vue";

@Component({
    components: {
        IconAtom
    }
})
export default class ToolbarCalendarAtom extends Vue {
    @Prop({ default: null }) private initialFromDate!: string;
    @Prop({ default: null }) private initialToDate!: string;

    private dateFormat = "DD MMM YYYY";
    private dateOne = this.initialFromDate ? this.initialFromDate : "";
    private dateTwo = this.initialToDate ? this.initialToDate : "";
    private isOpen = false;

    public $refs!: {
        calendar: HTMLDivElement
    };

    protected mounted() {
        this.$refs.calendar.addEventListener("click", (e) => {
            this.isOpen = true;
            e.stopPropagation();
        });

        document.body.addEventListener("click", this.closeCalendar);
    }

    protected beforeDestroy() {
        document.body.removeEventListener("click", this.closeCalendar);
    }

    private closeCalendar() {
        this.isOpen = false;
    }

    protected formatDate(date) {
        let formattedDates = "";
        if (date) {
            formattedDates = format(date, this.dateFormat, { locale: da });
        }
        return formattedDates;
    }

    protected openCal() {
        this.isOpen = true;
    }

    protected closeCal(e) {
        e.stopPropagation();

        this.isOpen = false;

        if (this.dateOne && !this.dateTwo) {
            const today = new Date().toJSON().slice(0, 10);

            this.dateTwo = today;
        }
        this.$emit("datesChanged", this.dateOne, this.dateTwo);
    }

    protected reset() {
        this.dateOne = "";
        this.dateTwo = "";

        this.$emit("datesChanged", this.dateOne, this.dateTwo);
    }
}
