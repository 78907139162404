



































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import api from "../../../core/api/Api";
import { AddOrUpdateNotificationRequest, NotificationSettingSummary, NotificationSettingViewModel, SalesAccountNotificationSettingView } from "../../../generated/contracts";
import Table from "../../atoms/table/Table.vue";
import CardLayoutEdit from "../../atoms/cards/card-layouts/CardLayoutEdit.vue";
import CardLayoutSwitch from "../../atoms/cards/card-layouts/CardLayoutSwitch.vue";
import Overlay from "../../components/overlay/Overlay.vue";
import { CardEditModel, CardSwitchModel } from "../../atoms/cards/cardModels";
import every from "lodash-es/every";
import snackbar from "../../components/snackbar/snackbarClient";
import { SortDefinition } from "@/ui/components/table-view/tableViewModels";
import orderBy from "lodash-es/orderBy";

const fakeAdressId = -1;

@Component({
        components: { CardLayoutEdit, CardLayoutSwitch, Table, Overlay }
    }
)
export default class NotificationsView extends Vue {

    private editModels: { [key: number]: CardEditModel[] } = {};
    private switchModels: { [key: number]: CardSwitchModel } = {};
    private mainModelsSnapshot = "";
    private hasErrors = false;
    private adressConflicts = "";
    private showTransferDialog = false;
    private showConfirmNoEmailOrPhoneDialog = false;
    private pendingUpdateModel: AddOrUpdateNotificationRequest | null = null;
    private sortDefinition: SortDefinition = {
        sortOrder: "integrationId",
        sortByAscending: true
    };

    @Prop(String)
    private accountNumber!: string;
    @Prop({
        validator: (value: string) => ["customer", "project"].indexOf(value) > -1
    }) private context!: string;

    private async created() {
        this.load();
    }

    private orderChanged(sortDef: SortDefinition) {
        this.sortDefinition = sortDef;
    }

    private buildEditModels() {
        if (!this.content.notificationSetting) {
            this.content.notificationSetting = this.createDummyNotification();
        }

        const editFunc = createEditModel.bind(this);
        editFunc(this.editModels, fakeAdressId, this.content.notificationSetting);
        this.content.addresses.forEach(addr => {
            editFunc(this.editModels, addr.id, addr.notificationSetting || this.content.notificationSetting);
        });

        const switchFunc = createSwitchModel.bind(this);
        switchFunc(this.switchModels, fakeAdressId, this.content.notificationSetting);
        this.content.addresses.forEach(addr => {
            switchFunc(this.switchModels, addr.id, addr.notificationSetting || this.content.notificationSetting);
        });

        this.mainModelsSnapshot = this.createMainModelsSnapshot;

        function createEditModel(this: NotificationsView, parent: any, index: number, notification: NotificationSettingViewModel) {
            const value = [
                {
                    label: "E-mail",
                    type: "email",
                    value: notification ? notification.email : ""
                }, {
                    label: "Mobil",
                    type: "phone",
                    phone: notification ? notification.phoneNumber : "",
                    interval: {
                        start: notification ? this.formatTimestamp(notification.startContactTime) : "",
                        end: notification ? this.formatTimestamp(notification.endContactTime) : ""
                    }
                }
            ];
            this.$set(parent, index, value);
        }

        function createSwitchModel(this: NotificationsView, parent: any, index: number, notification: NotificationSettingViewModel) {
            const value = {
                type: "switch",
                switch1Name: "E-mail",
                switch2Name: "SMS",
                content: [
                    {
                        label: "Før levering",
                        switch1: {
                            value: notification ? notification.shouldReceiveEmailBeforeDelivery : false
                        },
                        switch2: {
                            value: notification ? notification.shouldReceiveSmsBeforeDelivery : false
                        }
                    }, {
                        label: "Efter levering",
                        switch1: {
                            value: notification ? notification.shouldReceiveEmailAfterDelivery : false
                        },
                        switch2: {
                            value: notification ? notification.shouldReceiveSmsAfterDelivery : false
                        }
                    }, {
                        label: "Ved forsinkelser",
                        switch1: {
                            value: notification ? notification.shouldReceiveEmailWhenDelayed : false
                        },
                        switch2: {
                            value: notification ? notification.shouldReceiveSmsWhenDelayed : false
                        }
                    }
                ]
            };
            this.$set(parent, index, value);
        }
    }

    private get addressesRows() {
        let rows = this.content.addresses.map(address => {
            return {
                cells: {
                    ...address,
                    hasOwnSettings: {
                        label: !this.areSettingsEqualToRoot(address) ? "Obs: Individuel indstilling" : "",
                        note: !this.areSettingsEqualToRoot(address),
                        type: "label"
                    }
                },
                options: [
                    {
                        type: "edit",
                        content: this.editModels[address.id]
                    }, this.switchModels[address.id],
                    {
                        type: "button",
                        title: "Gem"
                    }
                ]
            };
        });
        rows = orderBy(rows, (row => row.cells[this.sortDefinition.sortOrder!]), [this.sortDefinition.sortByAscending ? "asc" : "desc"]) as any;
        return rows;
    }

    private get mainEditModel() {
        return this.editModels[fakeAdressId];
    }

    private get mainSwitchModel() {
        return this.switchModels[fakeAdressId];
    }

    private setErrors(e) {
        this.hasErrors = e > 0;
    }

    private get mainNotificationSaveDisabled() {
        if (this.mainModelsSnapshot === this.createMainModelsSnapshot) {
            return true;
        } else if (this.mainModelsSnapshot !== this.createMainModelsSnapshot && this.hasErrors) {
            return true;
        } else {
            return false;
        }
    }

    private get mainNotificationApplyDisabled() {
        return every(this.content.addresses, this.areSettingsEqualToRoot);
    }

    private get createMainModelsSnapshot() {
        return JSON.stringify(this.mainEditModel) + JSON.stringify(this.mainSwitchModel);
    }

    private async load() {
        this.content = await api[this.context].getNotifications(this.accountNumber);

        this.buildEditModels();
    }

    private areSettingsEqualToRoot(address: NotificationSettingSummary) {
        return !address.notificationSetting || JSON.stringify(address.notificationSetting) === JSON.stringify(this.content.notificationSetting);
    }

    private async trySaveMain() {
        this.enforceEmailOrSms(this.internModelToUpdateModel(fakeAdressId));
    }

    private enforceEmailOrSms(updateModel: AddOrUpdateNotificationRequest) {
        if (!updateModel.email && !updateModel.phoneNumber) {
            this.showConfirmNoEmailOrPhoneDialog = true;
            this.pendingUpdateModel = updateModel;
        } else {
            this.saveNotification(updateModel);
        }
    }

    private async saveNotification(updateModel: AddOrUpdateNotificationRequest) {
        try {
            await api.notification.saveNotification(this.accountNumber, updateModel);
            snackbar.message("Notifikationsindstillinger blev gemt");
        } catch {
            snackbar.error("Notifikationsindstillinger", "blev ikke gemt!");
        }
        // Server has new thruth for addresses
        this.load();
    }

    private applyToAll() {
        // check if we have conflicts
        this.addressesRows.map((item) => {
            if (item.cells.hasOwnSettings.note) {
                this.adressConflicts += item.cells.integrationId + ", ";
            }
        });

        // conflicting adresses - show modal before saving
        if (this.adressConflicts.length > 0) {
            this.adressConflicts = this.adressConflicts.substr(0, this.adressConflicts.length - 2);
            this.showTransferDialog = true;
        } else {
            this.saveToAll();
        }
    }

    private async saveToAll() {
        this.showTransferDialog = false;

        try {
            await api.notification.applyToAll(this.accountNumber);
            snackbar.message("Notifikationsindstillinger blev påført alle adresse-id'er");
        } catch {
            snackbar.error("Notifikationsindstillinger", "blev ikke påført adresse-id'er!");
        }
        // Server has new thruth for addresses
        this.load();
    }

    private async trySaveForAddress(row, options) {
        this.enforceEmailOrSms(this.optionsToUpdateModel(row.id, options));
    }

    private internModelToUpdateModel(addressId: number): AddOrUpdateNotificationRequest {

        // For main notification (customer) there is not an addressId - I use fakeAdressId in that case to
        // lookup modeldata, but the addressId is not used towards server
        // For adress-notifications there is an addressId and they are sent as is.
        return {
            addressId: addressId !== fakeAdressId ? addressId : undefined,
            email: this.editModels[addressId][0].value!,
            phoneNumber: this.editModels[addressId][1].phone!,
            startContactTime: ((this.editModels[addressId][1].interval) as any).start,
            endContactTime: ((this.editModels[addressId][1].interval) as any).end,
            shouldReceiveEmailBeforeDelivery: ((this.switchModels[addressId].content[0].switch1) as any).value,
            shouldReceiveEmailAfterDelivery: ((this.switchModels[addressId].content[1].switch1) as any).value,
            shouldReceiveEmailWhenDelayed: ((this.switchModels[addressId].content[2].switch1) as any).value,
            shouldReceiveSmsBeforeDelivery: ((this.switchModels[addressId].content[0].switch2) as any).value,
            shouldReceiveSmsAfterDelivery: ((this.switchModels[addressId].content[1].switch2) as any).value,
            shouldReceiveSmsWhenDelayed: ((this.switchModels[addressId].content[2].switch2) as any).value
        };

    }

    private optionsToUpdateModel(addressId: string,
                                 models: [{ content: CardEditModel[] },
                                     { content: CardSwitchModel }]): AddOrUpdateNotificationRequest {

        const email = models[0].content[0];
        const phone = models[0].content[1];
        const emailBefore = models[1].content[0].switch1 as any;
        const emailAfter = models[1].content[1].switch1 as any;
        const emailDelay = models[1].content[2].switch1 as any;
        const smsBefore = models[1].content[0].switch2 as any;
        const smsAfter = models[1].content[1].switch2 as any;
        const smsDelay = models[1].content[2].switch2 as any;

        return {
            addressId: parseInt(addressId, 10),
            email: email.value!,
            phoneNumber: phone.phone!,
            startContactTime: (phone.interval as any).start,
            endContactTime: (phone.interval as any).end,
            shouldReceiveEmailBeforeDelivery: emailBefore.value,
            shouldReceiveEmailAfterDelivery: emailAfter.value,
            shouldReceiveEmailWhenDelayed: emailDelay.value,
            shouldReceiveSmsBeforeDelivery: smsBefore.value,
            shouldReceiveSmsAfterDelivery: smsAfter.value,
            shouldReceiveSmsWhenDelayed: smsDelay.value,
        };
    }

    private get addressesHeader() {
        return [
            { key: "integrationId", title: "Adresse id", size: 12 },
            { key: "name", title: "Navn", size: 35 },
            { key: "line1", title: "Leveringsadresse", size: 20 },
            { key: "postalCode", title: "Postnr", size: 10 },
            { key: "city", title: "By", size: 20 },
            { key: "hasOwnSettings", title: "" }
        ];
    }

    private content: SalesAccountNotificationSettingView = {
        name: "",
        addresses: [],
        salesAccountAddress: {
            city: "",
            country: "",
            line1: "",
            postalCode: "",
            name: ""
        },
        notificationSetting: this.createDummyNotification()
    };

    private createDummyNotification(): NotificationSettingViewModel {
        return {
            id: fakeAdressId,
            email: "",
            phoneNumber: "",
            startContactTime: "06:00",
            endContactTime: "16:00",
            shouldReceiveEmailAfterDelivery: false,
            shouldReceiveEmailBeforeDelivery: false,
            shouldReceiveEmailWhenDelayed: false,
            shouldReceiveSmsAfterDelivery: false,
            shouldReceiveSmsBeforeDelivery: false,
            shouldReceiveSmsWhenDelayed: false
        };
    }

    private formatTimestamp(raw: string): string {
        // 07:00:00 -> 07:00
        const split = raw.split(":");
        if (split.length === 2) {
            return raw;
        }
        split.splice(-1, 1);
        return split.join(":");
    }

    private closeTransferDialog() {
        this.showTransferDialog = false;
    }

    private closeEmailSmsConfirmDialog(confirmed = false) {
        this.showConfirmNoEmailOrPhoneDialog = false;

        if (confirmed && this.pendingUpdateModel) {
            this.saveNotification(this.pendingUpdateModel);
        }
    }
}
