
















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { OrderSortOrder, OrderSummary } from "../../../generated/contracts";
import Api from "../../../core/api/Api";
import { TableHeader } from "../../atoms/table/tableModels";
import { dateTimeFilter } from "../../filters/dateTimeFilters";
import router from "../../router/index";
import TableViewControlModel, { ToolbarItem } from "../../components/table-view/tableViewModels";
import constants from "../../../core/constants/index";
import { statusToDisplayNameAndColor, toDomainSortBy } from "./tableHelper";
import TableView from "../../components/table-view/TableView.vue";

interface ExtendedOrderSummary extends OrderSummary {
    addressLine: string;
    city: string;
    postalCode: string;
}

interface OrderSummaryTableHeader extends TableHeader {
    key: keyof (ExtendedOrderSummary);
}

@Component({
    components: {
        TableView
    }
})
export default class OrderHistoryView extends Vue {
    @Prop() private tab!: string;
    @Prop() private id!: string;

    private loadFromUrl = this.tab === router.currentRoute.query["tab"];
    private initialSortOrder: keyof (OrderSummary) = router.currentRoute.query["sortOrder"] && this.loadFromUrl ?
                (router.currentRoute.query["sortOrder"] as keyof (OrderSummary))! :
                "status";
    private initialSortBy = router.currentRoute.query["sortByAsc"] && this.loadFromUrl ?
                router.currentRoute.query["sortByAsc"] === "true" :
                true;
    private initialSearchTerm = router.currentRoute.query["searchTerm"] && this.loadFromUrl ?
                router.currentRoute.query["searchTerm"] :
                null;
    private initialFromDate = router.currentRoute.query["fromDate"] && this.loadFromUrl ?
                router.currentRoute.query["fromDate"] as string :
                null;
    private initialToDate = router.currentRoute.query["toDate"] && this.loadFromUrl ?
                router.currentRoute.query["toDate"] as string :
                null;

    private tableControlModel: TableViewControlModel = {
        fromDate: this.initialFromDate ? this.initialFromDate : null,
        toDate: this.initialToDate ? this.initialToDate : null,
        searchTerm: this.initialSearchTerm,
        sort: {
            sortOrder: this.initialSortOrder,
            sortByAscending: this.initialSortBy
        }
    };
    private loadingOrderBy = false;
    private loadingMore = false;
    private orderSummaries: OrderSummary[] = [];

    private mounted() {
        this.load(0, true);
    }

    private get toolbarItems() {
        return [ToolbarItem.TextSearch, ToolbarItem.DatePicker];
    }

    private get tableHeader(): OrderSummaryTableHeader[] {
        return [
            { key: "orderNumber", title: "Ordrenummer", size: 12 },
            { key: "salesAccountNumber", title: "Konto", size: 9 },
            { key: "routeNumber", title: "Rute", size: 9 },
            { key: "addressLine", title: "Adresse", size: 20 },
            { key: "postalCode", title: "Postnummer", size: 11 },
            { key: "city", title: "By", size: 15 },
            { key: "deliveryTimeUtc", title: "Leveret", size: 10 },
            { key: "status", title: "Status" }
        ];
    }

    private rowClicked(row: OrderSummary) {
        router.push({ name: "Order", params: { id: row.orderNumber } });
    }

    private tableControlModelChanged(tableControlData: TableViewControlModel) {
        this.tableControlModel = tableControlData;
        this.loadingOrderBy = true;
        this.load();
    }

    private async load(skip = 0, firstLoad = false, take = constants.NoOfRowsAtATime) {
        this.loadingMore = true;
        try {

            const sortByAsc = router.currentRoute.query["sortByAsc"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["sortByAsc"] === "true" :
                this.tableControlModel.sort.sortByAscending;

            const sortOrder = router.currentRoute.query["sortOrder"] && firstLoad && this.loadFromUrl ?
                (router.currentRoute.query["sortOrder"] as keyof (OrderSummary))! :
                (this.tableControlModel.sort.sortOrder as keyof (OrderSummary))!;

            const searchTerm = router.currentRoute.query["searchTerm"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["searchTerm"] :
                this.tableControlModel.searchTerm;

            const fromDate = router.currentRoute.query["fromDate"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["fromDate"] as string :
                this.tableControlModel.fromDate;

            const toDate = router.currentRoute.query["toDate"] && firstLoad && this.loadFromUrl ?
                router.currentRoute.query["toDate"] as string :
                this.tableControlModel.toDate;

            this.loadFromUrl = this.tab === router.currentRoute.query["tab"];

            if (!firstLoad && this.loadFromUrl) {
                router.replace({ query: {
                    ...this.$route.query,
                    sortOrder: sortOrder.toString(),
                    sortByAsc: sortByAsc.toString(),
                    searchTerm: searchTerm as string,
                    fromDate: fromDate ? fromDate.toString() : "",
                    toDate: toDate ? toDate.toString() : ""} });
                }

            const orders: OrderSummary[] = await Api.order.getOrderDetails(this.id,
                this.toOrderSummarySortOrder(sortOrder),
                toDomainSortBy(sortByAsc),
                searchTerm,
                fromDate,
                toDate,
                skip,
                take);

            this.orderSummaries = skip > 0 ? [...this.orderSummaries, ...orders] : orders;
        } finally {
            this.loadingMore = false;
            this.loadingOrderBy = false;
        }
    }

    private toOrderSummarySortOrder(key: keyof (ExtendedOrderSummary)): OrderSortOrder {
        switch (key) {
            case "orderNumber":
                return OrderSortOrder.OrderNumber;
            case "salesAccountNumber":
                return OrderSortOrder.SalesAccountNumber;
            case "routeNumber":
                return OrderSortOrder.Route;
            case "addressLine":
                return OrderSortOrder.Address;
            case "postalCode":
                return OrderSortOrder.PostalCode;
            case "city":
                return OrderSortOrder.City;
            case "deliveryTimeUtc":
                return OrderSortOrder.DeliveryDate;
            case "status":
                return OrderSortOrder.Status;
            default:
                throw Error(`Unhandled sort-order: ${key}`);
        }
    }

    private async loadMore(done: () => void) {
        await this.load(this.tableContent.length);
        done();
    }

    private get tableContent(): object[] {
        return this.orderSummaries.map((d: OrderSummary) => ({
            ...d,
            deliveryTimeUtc: dateTimeFilter(d.deliveryTimeUtc),
            addressLine: d.address ? d.address.line1 : null,
            city: d.address ? d.address.city : null,
            postalCode: d.address ? d.address.postalCode : null,
            status: statusToDisplayNameAndColor(d.status)
        }));
    }
}
