





















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import IconAtom from "../icons/Icons.vue";
import SkeletonLoaderAtom from "@atoms/skeleton-loader/SkeletonLoader.vue";

@Component({
    components: {
        IconAtom,
        SkeletonLoaderAtom
    }
})
export default class ContactInfoAtom extends Vue {

    @Prop({ default: "client" }) private type!: string;
    @Prop() private title!: string;
    @Prop() private address!: string;
    @Prop() private phone!: string;
    @Prop() private clientNo!: string;

}
