













import Vue from "vue";
import Component from "vue-class-component";
import IconAtom from "@atoms/icons/Icons.vue";
import { Prop } from "vue-property-decorator";

@Component({
    components: {
        IconAtom
    }
})
export default class Overlay extends Vue {
    @Prop({
        type: String,
        default: "fullscreen",
        validator: value => ["dialog", "fullscreen"].indexOf(value) > -1
    }) private mode!: string;

    @Prop({
        type: String,
        default: "transparent",
        validator: value => ["transparent", "circle"].indexOf(value) > -1
    }) private closeType!: string;

    @Prop({
        default: false,
    }) private noClose!: boolean;

    public $refs!: {
        overlay: HTMLElement
    };

    private created() {
        document.addEventListener("keydown", this.close);
    }

    private beforeDestroy() {
        document.removeEventListener("keydown", this.close);
    }

    private get closeClass() {
        return `close --${this.mode} --${this.closeType}`;
    }

    private get foregroundClass() {
        return `foreground --${this.mode}`;
    }

    private close(e) {

        if (e === true || e.key === "Escape" || e.target === this.$refs.overlay) {
            this.$emit("close");
        }

    }
}
