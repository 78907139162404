




























import Vue from "vue";
import Component from "vue-class-component";
import NavigationPanel from "./chrome/links/NavigationPanel.vue";
import SearchPanel from "./chrome/search/SearchPanel.vue";
import EndlessScroll from "./components/endless-scroll/EndlessScroll.vue";
import bus from "../core/bus/Bus.vue";
import Snackbar from "./components/snackbar/Snackbar.vue";
import { Getter } from "vuex-class";

@Component({
    components: {
        Snackbar,
        EndlessScroll,
        NavigationPanel,
        SearchPanel
    }
})
export default class App extends Vue {
    private searchIsActive: boolean = false;

    private setSearchIsActive(value: boolean) {
        this.searchIsActive = value;
    }

    private loadMore(done) {
        bus.endlessScrollLoadMore(done);
    }

    private scrollPos(pos) {
        bus.endlessScrollScrollPos(pos);
    }

    @Getter
    private isLoggedIn!: boolean;

}
