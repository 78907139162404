












import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import IconAtom from "@atoms/icons/Icons.vue";

import { TableHeader } from "./tableModels";

@Component({
    components: {
        IconAtom
    }
})
export default class TableHeaderAtom extends Vue {
    private orderKey: string | null = null;
    private orderAscending: boolean = false;

    @Prop() private headerItems!: TableHeader[];
    @Prop() private alignRight!: boolean;
    @Prop() private highlightCell!: number;
    @Prop() private initialOrderKey!: string;
    @Prop({ default: true }) private initialOrderAscending!: boolean;

    protected mounted() {
        this.orderKey = this.initialOrderKey;
        this.orderAscending = this.initialOrderAscending;
    }

    protected orderBy(item: TableHeader) {

        if (item.key === this.orderKey) {
            this.orderAscending = !this.orderAscending;
        } else {
            this.orderAscending = true;
            this.orderKey = item.key;
        }

        this.$emit("orderChanged", { sortOrder: this.orderKey, sortByAscending: this.orderAscending });
    }
}
