


















































    import { Component, Prop, Vue } from "vue-property-decorator";
    import InputTextfieldAtom from "@atoms/input-textfield/InputTextfield.vue";
    import InputTextareaAtom from "@atoms/input-textarea/InputTextarea.vue";
    import IconAtom from "@atoms/icons/Icons.vue";

    @Component({
        components: {
            InputTextfieldAtom,
            InputTextareaAtom,
            IconAtom
        }
    })
    export default class ShareModal extends Vue {
        @Prop() private dialogType!: string;

        @Prop({ required: true })
        private shareAction;

        @Prop({
            type: String,
            default: "delivery",
            validator: (value) => ["delivery", "pickup"].indexOf(value) > -1
        })
        private mode!: string;

        @Prop() private images;

        private hasErrors = [] as any;
        private msgSent = false;
        private msgfailed = false;
        private shareInfo = {
            name: "",
            message: "",
            email: "",
            checkedImages: []
        };

        private get headline() {
            if (this.dialogType === "customer") {
                return "Del med kunde";
            } else {
                return "Del med kollega";
            }
        }

        private get paragraph() {
            if (this.mode === "delivery") {
                if (this.dialogType === "customer") {
                    return `Send leveringsinfo inklusiv billede til en kunde. E-mailen vil <u>ikke indeholde information om scanninger</u>,
da disse kun er til internt brug i Saint-Gobain Distribution.`;
                } else {
                    return `Send <u>hele</u> leveringsinfoen på e-mail til en kollega inden for Saint-Gobain Distribution.
Må ikke sendes til kunder, da informationen kun er til internt brug.`;
                }
            } else { // pickup
                if (this.dialogType === "customer") {
                    return `Send afhentningsinfo inklusiv billede til en kunde. E-mailen vil <u>ikke indeholde information om scanninger</u>,
da disse kun er til internt brug i Saint-Gobain Distribution.`;
                } else {
                    return `Send <u>hele</u> afhentningsinfoen på e-mail til en kollega inden for Saint-Gobain Distribution. Må ikke sendes til kunder, da informationen kun er til internt brug.`;
                }
            }
        }

        public $refs!: {
            name: HTMLFormElement,
            email: HTMLFormElement,
            message: HTMLFormElement,
            submit: HTMLFormElement
        };

        protected handleErrMsg(ev, errKey) {
            // add if error exists
            if (ev.value) {
                let updatedMsg = false;
                // update error message if already exists
                this.hasErrors.forEach((item: any) => {
                    if (item.key === errKey) {
                        updatedMsg = true;
                    }
                });
                // error doesnt exists in array so we need to add it
                if (!updatedMsg) {
                    this.hasErrors.push({
                        key: errKey
                    });
                }
            } else {
                // remove error message
                this.hasErrors.forEach((item: any, ix) => {
                    if (item.key === errKey) {
                        this.hasErrors.splice(ix, 1);
                    }
                });
            }
        }

        protected async sendInfo() {
            this.$refs.name.$el.focus();
            this.$refs.email.$el.focus();
            this.$refs.submit.$el.focus();

            if (!this.hasErrors.length) {
                try {
                    this.msgSent = true;
                    await this.shareAction(this.dialogType, this.shareInfo.name, this.shareInfo.email, this.shareInfo.message, this.shareInfo.checkedImages);
                    setTimeout(() => {
                        this.$emit("close");
                    }, 2000);
                } catch (e) {
                    this.msgfailed = true;
                }
            }
        }
    }
