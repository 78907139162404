import { render, staticRenderFns } from "./LiveRoute.vue?vue&type=template&id=58ba0b9a&scoped=true&"
import script from "./LiveRoute.vue?vue&type=script&lang=ts&"
export * from "./LiveRoute.vue?vue&type=script&lang=ts&"
import style0 from "./LiveRoute.vue?vue&type=style&index=0&id=58ba0b9a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58ba0b9a",
  null
  
)

component.options.__file = "LiveRoute.vue"
export default component.exports