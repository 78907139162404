import { LogisticsType } from "@/generated/contracts";

export function logisticsTypeDisplayName(type: LogisticsType): string {
    switch (type) {
        case LogisticsType.Order:
            return "Ordre";
        case LogisticsType.Delivery:
            return "Følgeseddel";
        case LogisticsType.Package:
            return "Kolli";
        case LogisticsType.ReturnPackage:
            return "Returkolli";
        case LogisticsType.CustomerReturnDelivery:
            return "Retursag";
        case LogisticsType.Store:
            return "Butik";
        case LogisticsType.Driver:
            return "Chauffør";
        case LogisticsType.DistributionCenter:
            return "Terminal";
        case LogisticsType.Customer:
            return "Kunde";
        case LogisticsType.Project:
            return "Underkonto";
        case LogisticsType.DeliveryRoute:
            return "Leveringsrute";
        default:
            return "Unhandled type in logisticsTypeDisplayName: " + type;
    }
}
