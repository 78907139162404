









































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import debounce from "lodash-es/debounce";

import TabItem from "./tabsModel";
import TabsAtom from "@atoms/tabs/Tabs.vue";
import SkeletonLoaderAtom from "@atoms/skeleton-loader/SkeletonLoader.vue";

@Component({
    components: {
        SkeletonLoaderAtom
    }
})
export default class TabsItemAtom extends Vue {
    private children: TabsAtom[] = this.$children;
    private activeTab = 0;
    private allInitialized = false;
    private hiddenItems = 0;

    @Prop() private tabs!: TabItem[];
    @Prop({ type: Number, default: 0 }) private initialActiveIndex!: number;

    public $refs!: {
        listWrap: HTMLElement,
        list: HTMLElement,
        moreTab: HTMLElement,
        indicator: HTMLBaseElement,
        tabItem: HTMLUListElement
    };

    private created() {
        this.activeTab = this.initialActiveIndex;
    }

    @Watch("children")
    private onChildrenChanged() {
        // the content-children (from $children) are not ready at first
        this.children.forEach((child: any, ix) => {
            child.isActive = this.activeTab === child.id;
        });
    }

    private itemStyles(index) {
        return (
            {
                "--active": index === this.activeTab,
                "--disabled": this.tabs[index].disabled,
                "--hidden": this.tabs[index].hidden
            }
        );
    }

    protected mounted() {
        window.addEventListener("resize", debounce(() => {
            this.setItems();
            this.resetIndicator();
        }, 250));

        this.setItems();
    }

    protected beforeDestroy() {
        window.removeEventListener("resize", debounce(() => {
            this.setItems();
            this.resetIndicator();
        }, 250));
    }

    protected setItems() {
        if (!this.$refs.listWrap || this.$refs.tabItem === undefined) {
            return;
        }

        const maxWidth = this.$refs.listWrap.offsetWidth - 110;

        let hideItems = 0;
        let size = 0;

        this.tabs.forEach((ele, ix) => {
            this.$refs.tabItem[ix].hidden = false;
        });

        this.tabs.forEach((ele, ix) => {
            const currentItem = this.$refs.tabItem[ix] as HTMLElement;

            size += currentItem.offsetWidth;

            this.tabs[ix].hidden = size > maxWidth ? true : false;

            if (size > maxWidth) {
                hideItems++;
            }
        });
        this.hiddenItems = hideItems;
    }

    protected setIndicatorStyle(index) {
        if (index === -1) {
            if (!this.$refs.moreTab) {
                // fix for edge case where moreTab is undefined due to debouce throttling
                return;
            }
            // show more btn is hovered / active
            this.$refs.indicator.style.width = this.$refs.moreTab.offsetWidth + "px";
            this.$refs.indicator.style.left = this.$refs.moreTab.offsetLeft + "px";
        } else if (this.$refs.indicator && this.$refs.tabItem) {
            this.$refs.indicator.style.width = this.$refs.tabItem[index].offsetWidth + "px";
            this.$refs.indicator.style.left = this.$refs.tabItem[index].offsetLeft + "px";
        }
    }

    @Watch("tabs", {
        immediate: true
    })
    private onTabsChanged(value) {

        this.setItems();
        this.setIndicatorStyle(this.activeTab);
        Vue.nextTick(() => {
            this.resetIndicator();
        });

        if (this.allInitialized || !value || value.length === 0) {
            return;
        }
        this.allInitialized = true;
        this.setActive(this.activeTab, true);
    }

    protected setActive(index, initial = false) {
        if (this.tabs[index].disabled) {
            return;
        }
        this.activeTab = index;

        this.children.forEach((child: any) => {
            child.isActive = index === child.id;
        });

        if (!initial) {
            this.$emit("tabSelected", this.tabs[index]);
        }
    }

    protected setIndicator(index) {
        if (index !== -1 && this.tabs[index].disabled) {
            return;
        }

        this.setIndicatorStyle(index);
    }

    protected resetIndicator() {
        let setActive = this.activeTab;

        if (this.hiddenItems !== 0 && this.activeTab > (this.tabs.length - this.hiddenItems)) {
            setActive = -1;
        }

        this.setIndicatorStyle(setActive);
    }
}
