import Vue from "vue";
import Vuex, { ActionTree, GetterTree, MutationTree, StoreOptions } from "vuex";
import { IRootState } from "./types";
import { LoginView } from "@/generated/contracts";
import { localStorage } from "../core/storage/storage.service";
import axios from "axios";

const StorageLoginKey = "Login";

Vue.use(Vuex);

function syncAuthHeader(login: LoginView | null = null) {
    if (login) {
        axios.defaults.headers.Authorization = `Bearer ${login.token}`;
    } else {
        delete axios.defaults.headers.Authorization;
    }
}

const getters: GetterTree<IRootState, any> = {
    isLoggedIn: state => !!state.login
};

const actions: ActionTree<IRootState, any> = {
    loggedIn({ commit, state }, login) {
        commit("loggedIn", login);
        syncAuthHeader(login);
    },
    clearLogin({ commit }) {
        commit("clearLogin");
        syncAuthHeader();
    }
};

const mutations: MutationTree<IRootState> = {
    loggedIn(state, value: LoginView) {
        state.login = value;
        // Make a rudimentary encryption of data
        localStorage.setItem(StorageLoginKey, btoa(JSON.stringify(value)));
    },
    clearLogin(state) {
        state.login = undefined;
        localStorage.removeItem(StorageLoginKey);
    }
};

function readLogin() {
    try {
        const login = localStorage.getItem(StorageLoginKey) ?
            JSON.parse(atob(localStorage.getItem(StorageLoginKey) || "")!) as LoginView
            : undefined;
        syncAuthHeader(login);
        return login;
    } catch (e) {
        console.error("store/readLogin - catch", e);
        return undefined;
    }
}

const store: StoreOptions<IRootState> = {
    strict: process.env.NODE_ENV !== "production",
    state: { login: readLogin() },
    actions,
    getters,
    mutations
};

export default new Vuex.Store<IRootState>(store);
