


















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import StatChange from "../../../atoms/stat-change/StatChange.vue";

@Component({
    components: { StatChange }
})
export default class CustomerStats extends Vue {
    @Prop({ default: () => ({}) }) private stats;
}
