import { render, staticRenderFns } from "./CardLayoutTableview.vue?vue&type=template&id=da207b68&scoped=true&"
import script from "./CardLayoutTableview.vue?vue&type=script&lang=ts&"
export * from "./CardLayoutTableview.vue?vue&type=script&lang=ts&"
import style0 from "./CardLayoutTableview.vue?vue&type=style&index=0&id=da207b68&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da207b68",
  null
  
)

component.options.__file = "CardLayoutTableview.vue"
export default component.exports