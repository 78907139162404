



























import { Vue, Component, Prop } from "vue-property-decorator";

import { CardSwitchModel } from "../cardModels";

import ToggleSwitchAtom from "@atoms/toggle-switch/ToggleSwitch.vue";

@Component({
    components: {
        ToggleSwitchAtom
    }
})
export default class CardLayoutSwitchAtom extends Vue {
    @Prop() private data!: CardSwitchModel;
}
